/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroAnalytics_application_from_submission_to_approval_or_rejectionCollection } from '../models/lasgro-analytics-_application-_from-_submission-_to-_approval-_or-_rejection-collection';
import { LasgroAnalytics_application_from_submission_to_openingCollection } from '../models/lasgro-analytics-_application-_from-_submission-_to-_opening-collection';
import { LasgroAnalytics_financial_report_avgCollection } from '../models/lasgro-analytics-_financial-_report-_avg-collection';
import { LasgroAnalytics_pending_avg } from '../models/lasgro-analytics-_pending-_avg';
import { LasgroGeneral_success_payload } from '../models/lasgro-general-_success-_payload';
import { AddPendingAverageInputPayload } from '../models/add-pending-average-input-payload';
import { LasgroAnalytics_fr_from_submission_to_approval_or_rejectionCollection } from '../models/lasgro-analytics-_fr-_from-_submission-_to-_approval-_or-_rejection-collection';
import { LasgroAnalytics_fr_from_submission_to_openingCollection } from '../models/lasgro-analytics-_fr-_from-_submission-_to-_opening-collection';
import { LasgroAnalytics_fr_by_supplierCollection } from '../models/lasgro-analytics-_fr-_by-_supplier-collection';
import { LasgroAnalytics_not_submitted_fr_in_time_vendorsCollection } from '../models/lasgro-analytics-_not-_submitted-_fr-_in-_time-_vendors-collection';
import { LasgroAnalytics_resourcesCollection } from '../models/lasgro-analytics-_resources-collection';
import { LasgroAnalytics } from '../models/lasgro-analytics';
import { LasgroAnalytics_voucher_from_submission_to_approval_or_rejectionCollection } from '../models/lasgro-analytics-_voucher-_from-_submission-_to-_approval-_or-_rejection-collection';
import { LasgroAnalytics_voucher_from_submission_to_openingCollection } from '../models/lasgro-analytics-_voucher-_from-_submission-_to-_opening-collection';
@Injectable({
  providedIn: 'root',
})
class AnalyticsService extends __BaseService {
  static readonly analyticsApplicationFromSubmissionToApprovalOrRejectionPath = '/organizations/{organizationID}/analytics/application-from-submission-to-approval-or-rejection/generate';
  static readonly analyticsApplicationFromSubmissionToOpenPath = '/organizations/{organizationID}/analytics/application-from-submission-to-open/generate';
  static readonly analyticsShowFinancialReportAveragePath = '/organizations/{organizationID}/analytics/average-financial-report/generate';
  static readonly analyticsShowPendingAveragePath = '/organizations/{organizationID}/analytics/average-pending';
  static readonly analyticsAddPendingAveragePath = '/organizations/{organizationID}/analytics/average-pending/generate';
  static readonly analyticsFrFromSubmissionToApprovalOrRejectionPath = '/organizations/{organizationID}/analytics/fr-from-submission-to-approval-or-rejection/generate';
  static readonly analyticsFrFromSubmissionToOpenPath = '/organizations/{organizationID}/analytics/fr-from-submission-to-open/generate';
  static readonly analyticsFrBySupplierReportPath = '/organizations/{organizationID}/analytics/fr_by_supplier_report/generate';
  static readonly analyticsVendorsNotSubmittedFrInTimePath = '/organizations/{organizationID}/analytics/not_submitted_in_time_vendors/generate';
  static readonly analyticsShowResourcesPath = '/organizations/{organizationID}/analytics/resources';
  static readonly analyticsShowPath = '/organizations/{organizationID}/analytics/resources/{resourceID}';
  static readonly analyticsVoucherFromSubmissionToApprovalOrRejectionPath = '/organizations/{organizationID}/analytics/voucher-from-submission-to-approval-or-rejection/generate';
  static readonly analyticsVoucherFromSubmissionToOpenPath = '/organizations/{organizationID}/analytics/voucher-from-submission-to-open/generate';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * application_from_submission_to_approval_or_rejection analytics
   *
   * fetches the historical data of application from submission to approval or rejection
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  analyticsApplicationFromSubmissionToApprovalOrRejectionResponse(organizationID: string): __Observable<__StrictHttpResponse<LasgroAnalytics_application_from_submission_to_approval_or_rejectionCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(organizationID))}/analytics/application-from-submission-to-approval-or-rejection/generate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroAnalytics_application_from_submission_to_approval_or_rejectionCollection>;
      })
    );
  }
  /**
   * application_from_submission_to_approval_or_rejection analytics
   *
   * fetches the historical data of application from submission to approval or rejection
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  analyticsApplicationFromSubmissionToApprovalOrRejection(organizationID: string): __Observable<LasgroAnalytics_application_from_submission_to_approval_or_rejectionCollection> {
    return this.analyticsApplicationFromSubmissionToApprovalOrRejectionResponse(organizationID).pipe(
      __map(_r => _r.body as LasgroAnalytics_application_from_submission_to_approval_or_rejectionCollection)
    );
  }

  /**
   * application_from_submission_to_open analytics
   *
   * fetches the historical data of application from submission to open
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  analyticsApplicationFromSubmissionToOpenResponse(organizationID: string): __Observable<__StrictHttpResponse<LasgroAnalytics_application_from_submission_to_openingCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(organizationID))}/analytics/application-from-submission-to-open/generate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroAnalytics_application_from_submission_to_openingCollection>;
      })
    );
  }
  /**
   * application_from_submission_to_open analytics
   *
   * fetches the historical data of application from submission to open
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  analyticsApplicationFromSubmissionToOpen(organizationID: string): __Observable<LasgroAnalytics_application_from_submission_to_openingCollection> {
    return this.analyticsApplicationFromSubmissionToOpenResponse(organizationID).pipe(
      __map(_r => _r.body as LasgroAnalytics_application_from_submission_to_openingCollection)
    );
  }

  /**
   * show_financial_report_average analytics
   *
   * fetches the historical data of submitted financial report documents
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `AnalyticsService.AnalyticsShowFinancialReportAverageParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `to`:
   *
   * - `from`:
   *
   * @return OK
   */
  analyticsShowFinancialReportAverageResponse(params: AnalyticsService.AnalyticsShowFinancialReportAverageParams): __Observable<__StrictHttpResponse<LasgroAnalytics_financial_report_avgCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.to != null) __params = __params.set('to', params.to.toString());
    if (params.from != null) __params = __params.set('from', params.from.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/analytics/average-financial-report/generate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroAnalytics_financial_report_avgCollection>;
      })
    );
  }
  /**
   * show_financial_report_average analytics
   *
   * fetches the historical data of submitted financial report documents
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `AnalyticsService.AnalyticsShowFinancialReportAverageParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `to`:
   *
   * - `from`:
   *
   * @return OK
   */
  analyticsShowFinancialReportAverage(params: AnalyticsService.AnalyticsShowFinancialReportAverageParams): __Observable<LasgroAnalytics_financial_report_avgCollection> {
    return this.analyticsShowFinancialReportAverageResponse(params).pipe(
      __map(_r => _r.body as LasgroAnalytics_financial_report_avgCollection)
    );
  }

  /**
   * show_pending_average analytics
   *
   * fetches the historical data of submitted pending documents
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `AnalyticsService.AnalyticsShowPendingAverageParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `to`:
   *
   * - `from`:
   *
   * @return OK
   */
  analyticsShowPendingAverageResponse(params: AnalyticsService.AnalyticsShowPendingAverageParams): __Observable<__StrictHttpResponse<LasgroAnalytics_pending_avg>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.to != null) __params = __params.set('to', params.to.toString());
    if (params.from != null) __params = __params.set('from', params.from.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/analytics/average-pending`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroAnalytics_pending_avg>;
      })
    );
  }
  /**
   * show_pending_average analytics
   *
   * fetches the historical data of submitted pending documents
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `AnalyticsService.AnalyticsShowPendingAverageParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `to`:
   *
   * - `from`:
   *
   * @return OK
   */
  analyticsShowPendingAverage(params: AnalyticsService.AnalyticsShowPendingAverageParams): __Observable<LasgroAnalytics_pending_avg> {
    return this.analyticsShowPendingAverageResponse(params).pipe(
      __map(_r => _r.body as LasgroAnalytics_pending_avg)
    );
  }

  /**
   * add_pending_average analytics
   *
   * generate some pending average data
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `AnalyticsService.AnalyticsAddPendingAverageParams` containing the following parameters:
   *
   * - `payload`: input payload for generating pending average analytical data
   *
   * - `organizationID`:
   *
   * @return Accepted
   */
  analyticsAddPendingAverageResponse(params: AnalyticsService.AnalyticsAddPendingAverageParams): __Observable<__StrictHttpResponse<LasgroGeneral_success_payload>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/analytics/average-pending/generate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroGeneral_success_payload>;
      })
    );
  }
  /**
   * add_pending_average analytics
   *
   * generate some pending average data
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `AnalyticsService.AnalyticsAddPendingAverageParams` containing the following parameters:
   *
   * - `payload`: input payload for generating pending average analytical data
   *
   * - `organizationID`:
   *
   * @return Accepted
   */
  analyticsAddPendingAverage(params: AnalyticsService.AnalyticsAddPendingAverageParams): __Observable<LasgroGeneral_success_payload> {
    return this.analyticsAddPendingAverageResponse(params).pipe(
      __map(_r => _r.body as LasgroGeneral_success_payload)
    );
  }

  /**
   * fr_from_submission_to_approval_or_rejection analytics
   *
   * fetches the historical data of fr from submission to approval or rejection
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  analyticsFrFromSubmissionToApprovalOrRejectionResponse(organizationID: string): __Observable<__StrictHttpResponse<LasgroAnalytics_fr_from_submission_to_approval_or_rejectionCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(organizationID))}/analytics/fr-from-submission-to-approval-or-rejection/generate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroAnalytics_fr_from_submission_to_approval_or_rejectionCollection>;
      })
    );
  }
  /**
   * fr_from_submission_to_approval_or_rejection analytics
   *
   * fetches the historical data of fr from submission to approval or rejection
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  analyticsFrFromSubmissionToApprovalOrRejection(organizationID: string): __Observable<LasgroAnalytics_fr_from_submission_to_approval_or_rejectionCollection> {
    return this.analyticsFrFromSubmissionToApprovalOrRejectionResponse(organizationID).pipe(
      __map(_r => _r.body as LasgroAnalytics_fr_from_submission_to_approval_or_rejectionCollection)
    );
  }

  /**
   * fr_from_submission_to_open analytics
   *
   * fetches the historical data of fr from submission to open
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  analyticsFrFromSubmissionToOpenResponse(organizationID: string): __Observable<__StrictHttpResponse<LasgroAnalytics_fr_from_submission_to_openingCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(organizationID))}/analytics/fr-from-submission-to-open/generate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroAnalytics_fr_from_submission_to_openingCollection>;
      })
    );
  }
  /**
   * fr_from_submission_to_open analytics
   *
   * fetches the historical data of fr from submission to open
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  analyticsFrFromSubmissionToOpen(organizationID: string): __Observable<LasgroAnalytics_fr_from_submission_to_openingCollection> {
    return this.analyticsFrFromSubmissionToOpenResponse(organizationID).pipe(
      __map(_r => _r.body as LasgroAnalytics_fr_from_submission_to_openingCollection)
    );
  }

  /**
   * fr_by_supplier_report analytics
   *
   * fetches the historical data of fr by vendor
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `AnalyticsService.AnalyticsFrBySupplierReportParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `to`:
   *
   * - `from`:
   *
   * @return OK
   */
  analyticsFrBySupplierReportResponse(params: AnalyticsService.AnalyticsFrBySupplierReportParams): __Observable<__StrictHttpResponse<LasgroAnalytics_fr_by_supplierCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.to != null) __params = __params.set('to', params.to.toString());
    if (params.from != null) __params = __params.set('from', params.from.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/analytics/fr_by_supplier_report/generate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroAnalytics_fr_by_supplierCollection>;
      })
    );
  }
  /**
   * fr_by_supplier_report analytics
   *
   * fetches the historical data of fr by vendor
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `AnalyticsService.AnalyticsFrBySupplierReportParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `to`:
   *
   * - `from`:
   *
   * @return OK
   */
  analyticsFrBySupplierReport(params: AnalyticsService.AnalyticsFrBySupplierReportParams): __Observable<LasgroAnalytics_fr_by_supplierCollection> {
    return this.analyticsFrBySupplierReportResponse(params).pipe(
      __map(_r => _r.body as LasgroAnalytics_fr_by_supplierCollection)
    );
  }

  /**
   * vendors_not_submitted_fr_in_time analytics
   *
   * fetches the historical data of not submitted in time vendors
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  analyticsVendorsNotSubmittedFrInTimeResponse(organizationID: string): __Observable<__StrictHttpResponse<LasgroAnalytics_not_submitted_fr_in_time_vendorsCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(organizationID))}/analytics/not_submitted_in_time_vendors/generate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroAnalytics_not_submitted_fr_in_time_vendorsCollection>;
      })
    );
  }
  /**
   * vendors_not_submitted_fr_in_time analytics
   *
   * fetches the historical data of not submitted in time vendors
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  analyticsVendorsNotSubmittedFrInTime(organizationID: string): __Observable<LasgroAnalytics_not_submitted_fr_in_time_vendorsCollection> {
    return this.analyticsVendorsNotSubmittedFrInTimeResponse(organizationID).pipe(
      __map(_r => _r.body as LasgroAnalytics_not_submitted_fr_in_time_vendorsCollection)
    );
  }

  /**
   * show_resources analytics
   *
   * fetches the available analytical resources
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  analyticsShowResourcesResponse(organizationID: string): __Observable<__StrictHttpResponse<LasgroAnalytics_resourcesCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(organizationID))}/analytics/resources`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroAnalytics_resourcesCollection>;
      })
    );
  }
  /**
   * show_resources analytics
   *
   * fetches the available analytical resources
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  analyticsShowResources(organizationID: string): __Observable<LasgroAnalytics_resourcesCollection> {
    return this.analyticsShowResourcesResponse(organizationID).pipe(
      __map(_r => _r.body as LasgroAnalytics_resourcesCollection)
    );
  }

  /**
   * show analytics
   *
   * fetches the analytical data of submitted documents
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `AnalyticsService.AnalyticsShowParams` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `organizationID`:
   *
   * - `to`:
   *
   * - `from`:
   *
   * @return OK
   */
  analyticsShowResponse(params: AnalyticsService.AnalyticsShowParams): __Observable<__StrictHttpResponse<LasgroAnalytics>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.to != null) __params = __params.set('to', params.to.toString());
    if (params.from != null) __params = __params.set('from', params.from.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/analytics/resources/${encodeURIComponent(String(params.resourceID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroAnalytics>;
      })
    );
  }
  /**
   * show analytics
   *
   * fetches the analytical data of submitted documents
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `AnalyticsService.AnalyticsShowParams` containing the following parameters:
   *
   * - `resourceID`:
   *
   * - `organizationID`:
   *
   * - `to`:
   *
   * - `from`:
   *
   * @return OK
   */
  analyticsShow(params: AnalyticsService.AnalyticsShowParams): __Observable<LasgroAnalytics> {
    return this.analyticsShowResponse(params).pipe(
      __map(_r => _r.body as LasgroAnalytics)
    );
  }

  /**
   * voucher_from_submission_to_approval_or_rejection analytics
   *
   * fetches the historical data of voucher from submission to approval or rejection
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  analyticsVoucherFromSubmissionToApprovalOrRejectionResponse(organizationID: string): __Observable<__StrictHttpResponse<LasgroAnalytics_voucher_from_submission_to_approval_or_rejectionCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(organizationID))}/analytics/voucher-from-submission-to-approval-or-rejection/generate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroAnalytics_voucher_from_submission_to_approval_or_rejectionCollection>;
      })
    );
  }
  /**
   * voucher_from_submission_to_approval_or_rejection analytics
   *
   * fetches the historical data of voucher from submission to approval or rejection
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  analyticsVoucherFromSubmissionToApprovalOrRejection(organizationID: string): __Observable<LasgroAnalytics_voucher_from_submission_to_approval_or_rejectionCollection> {
    return this.analyticsVoucherFromSubmissionToApprovalOrRejectionResponse(organizationID).pipe(
      __map(_r => _r.body as LasgroAnalytics_voucher_from_submission_to_approval_or_rejectionCollection)
    );
  }

  /**
   * voucher_from_submission_to_open analytics
   *
   * fetches the historical data of voucher from submission to open
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  analyticsVoucherFromSubmissionToOpenResponse(organizationID: string): __Observable<__StrictHttpResponse<LasgroAnalytics_voucher_from_submission_to_openingCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(organizationID))}/analytics/voucher-from-submission-to-open/generate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroAnalytics_voucher_from_submission_to_openingCollection>;
      })
    );
  }
  /**
   * voucher_from_submission_to_open analytics
   *
   * fetches the historical data of voucher from submission to open
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  analyticsVoucherFromSubmissionToOpen(organizationID: string): __Observable<LasgroAnalytics_voucher_from_submission_to_openingCollection> {
    return this.analyticsVoucherFromSubmissionToOpenResponse(organizationID).pipe(
      __map(_r => _r.body as LasgroAnalytics_voucher_from_submission_to_openingCollection)
    );
  }
}

module AnalyticsService {

  /**
   * Parameters for analyticsShowFinancialReportAverage
   */
  export interface AnalyticsShowFinancialReportAverageParams {
    organizationID: string;
    to?: string;
    from?: string;
  }

  /**
   * Parameters for analyticsShowPendingAverage
   */
  export interface AnalyticsShowPendingAverageParams {
    organizationID: string;
    to?: string;
    from?: string;
  }

  /**
   * Parameters for analyticsAddPendingAverage
   */
  export interface AnalyticsAddPendingAverageParams {

    /**
     * input payload for generating pending average analytical data
     */
    payload: AddPendingAverageInputPayload;
    organizationID: string;
  }

  /**
   * Parameters for analyticsFrBySupplierReport
   */
  export interface AnalyticsFrBySupplierReportParams {
    organizationID: string;
    to?: string;
    from?: string;
  }

  /**
   * Parameters for analyticsShow
   */
  export interface AnalyticsShowParams {
    resourceID: string;
    organizationID: string;
    to?: string;
    from?: string;
  }
}

export { AnalyticsService }
