/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UpdateMetadataPurchaseOrderFilesPayload } from '../models/update-metadata-purchase-order-files-payload';
import { LasgroPurchase_order_files } from '../models/lasgro-purchase-_order-_files';
import { LasgroFile_infoCollection } from '../models/lasgro-file-_info-collection';
import { LasgroPanda_doc_link_data } from '../models/lasgro-panda-_doc-_link-_data';
import { LasgroPanda_doc_document } from '../models/lasgro-panda-_doc-_document';
import { LasgroFile_info } from '../models/lasgro-file-_info';
import { FileInfoInputPayload } from '../models/file-info-input-payload';
import { LasgroGeneral_success_payload } from '../models/lasgro-general-_success-_payload';
import { FileConvertAndMergeInputPayload } from '../models/file-convert-and-merge-input-payload';
import { FileTagUpdateInputPayload } from '../models/file-tag-update-input-payload';
@Injectable({
  providedIn: 'root',
})
class PurchaseOrderFilesService extends __BaseService {
  static readonly purchaseOrderFilesDownloadPath = '/file/{fileID}';
  static readonly purchaseOrderFilesAddPath = '/files/{resourceID}/upload';
  static readonly purchaseOrderFilesAddLinkPath = '/files/{resourceID}/upload_link';
  static readonly purchaseOrderFilesUpdateMetadataPath = '/organizations/{organizationID}/files/{fileID}/metadata';
  static readonly purchaseOrderFilesUpdateShipmentFilePath = '/organizations/{organizationID}/files/{resourceID}/shipment_file/{fileID}';
  static readonly purchaseOrderFilesAdd1Path = '/organizations/{organizationID}/files/{resourceID}/upload';
  static readonly purchaseOrderFilesAddLink1Path = '/organizations/{organizationID}/files/{resourceID}/upload_link';
  static readonly purchaseOrderFilesFullList1Path = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/purchase_order_all_files';
  static readonly purchaseOrderFilesList1Path = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/purchase_order_files';
  static readonly purchaseOrderFilesRcdssCreateContractDocumentLinkPath = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/purchase_order_files/rcdss_create_contract_document_link';
  static readonly purchaseOrderFilesRcdssDownloadContractFilePath = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/purchase_order_files/rcdss_download_contract_file';
  static readonly purchaseOrderFilesRcdssRemoveContractFilePath = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/purchase_order_files/rcdss_remove_contract_file';
  static readonly purchaseOrderFilesRcdssUploadContractFilePath = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/purchase_order_files/rcdss_upload_contract_file';
  static readonly purchaseOrderFilesShow1Path = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/purchase_order_files/{fileID}';
  static readonly purchaseOrderFilesUpdate1Path = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/purchase_order_files/{fileID}';
  static readonly purchaseOrderFilesDelete1Path = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/purchase_order_files/{fileID}';
  static readonly purchaseOrderFilesShowPath = '/purchase_order_files/{fileID}';
  static readonly purchaseOrderFilesUpdatePath = '/purchase_order_files/{fileID}';
  static readonly purchaseOrderFilesDeletePath = '/purchase_order_files/{fileID}';
  static readonly purchaseOrderFilesSetMainFilePath = '/purchase_order_files/{fileID}/set_main_file';
  static readonly purchaseOrderFilesFullListPath = '/purchase_orders/{purchaseOrderID}/purchase_order_all_files';
  static readonly purchaseOrderFilesListPath = '/purchase_orders/{purchaseOrderID}/purchase_order_files';
  static readonly purchaseOrderFilesConvertAndMergePath = '/purchase_orders/{purchaseOrderID}/purchase_order_files';
  static readonly purchaseOrderFilesUpdateFileTagsPath = '/purchase_orders/{purchaseOrderID}/purchase_order_files/{fileID}/tags';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * download purchase_order_files
   *
   * Download file by ID
   * @param fileID undefined
   */
  purchaseOrderFilesDownloadResponse(fileID: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/file/${encodeURIComponent(String(fileID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * download purchase_order_files
   *
   * Download file by ID
   * @param fileID undefined
   */
  purchaseOrderFilesDownload(fileID: string): __Observable<null> {
    return this.purchaseOrderFilesDownloadResponse(fileID).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * add purchase_order_files
   *
   * Upload file
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderFilesService.PurchaseOrderFilesAddParams` containing the following parameters:
   *
   * - `resourceType`:
   *
   * - `resourceID`:
   *
   * - `file`: uploaded file
   *
   * - `tag`:
   *
   * - `shipmentTrackingID`:
   *
   * - `shipmentFileType`:
   *
   * - `periodTag`:
   *
   * - `organizationID`:
   *
   * - `isMainFile`:
   *
   * - `isMainContractFile`:
   *
   * - `isApprovalRequired`:
   */
  purchaseOrderFilesAddResponse(params: PurchaseOrderFilesService.PurchaseOrderFilesAddParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.resourceType != null) __params = __params.set('resourceType', params.resourceType.toString());

    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    if (params.tag != null) __params = __params.set('tag', params.tag.toString());
    if (params.shipmentTrackingID != null) __params = __params.set('shipmentTrackingID', params.shipmentTrackingID.toString());
    if (params.shipmentFileType != null) __params = __params.set('shipmentFileType', params.shipmentFileType.toString());
    if (params.periodTag != null) __params = __params.set('periodTag', params.periodTag.toString());
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    if (params.isMainFile != null) __params = __params.set('isMainFile', params.isMainFile.toString());
    if (params.isMainContractFile != null) __params = __params.set('isMainContractFile', params.isMainContractFile.toString());
    if (params.isApprovalRequired != null) __params = __params.set('isApprovalRequired', params.isApprovalRequired.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/files/${encodeURIComponent(String(params.resourceID))}/upload`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add purchase_order_files
   *
   * Upload file
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderFilesService.PurchaseOrderFilesAddParams` containing the following parameters:
   *
   * - `resourceType`:
   *
   * - `resourceID`:
   *
   * - `file`: uploaded file
   *
   * - `tag`:
   *
   * - `shipmentTrackingID`:
   *
   * - `shipmentFileType`:
   *
   * - `periodTag`:
   *
   * - `organizationID`:
   *
   * - `isMainFile`:
   *
   * - `isMainContractFile`:
   *
   * - `isApprovalRequired`:
   */
  purchaseOrderFilesAdd(params: PurchaseOrderFilesService.PurchaseOrderFilesAddParams): __Observable<null> {
    return this.purchaseOrderFilesAddResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * add_link purchase_order_files
   *
   * Upload link file
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderFilesService.PurchaseOrderFilesAddLinkParams` containing the following parameters:
   *
   * - `url`:
   *
   * - `resourceID`:
   *
   * - `organizationID`:
   *
   * - `metadata`:
   *
   * - `isMainFile`:
   */
  purchaseOrderFilesAddLinkResponse(params: PurchaseOrderFilesService.PurchaseOrderFilesAddLinkParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.url != null) __params = __params.set('url', params.url.toString());

    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    if (params.metadata != null) __params = __params.set('metadata', params.metadata.toString());
    if (params.isMainFile != null) __params = __params.set('isMainFile', params.isMainFile.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/files/${encodeURIComponent(String(params.resourceID))}/upload_link`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add_link purchase_order_files
   *
   * Upload link file
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderFilesService.PurchaseOrderFilesAddLinkParams` containing the following parameters:
   *
   * - `url`:
   *
   * - `resourceID`:
   *
   * - `organizationID`:
   *
   * - `metadata`:
   *
   * - `isMainFile`:
   */
  purchaseOrderFilesAddLink(params: PurchaseOrderFilesService.PurchaseOrderFilesAddLinkParams): __Observable<null> {
    return this.purchaseOrderFilesAddLinkResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * update_metadata purchase_order_files
   *
   * Set a file's metadata
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderFilesService.PurchaseOrderFilesUpdateMetadataParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `organizationID`:
   *
   * - `fileID`:
   */
  purchaseOrderFilesUpdateMetadataResponse(params: PurchaseOrderFilesService.PurchaseOrderFilesUpdateMetadataParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;


    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/files/${encodeURIComponent(String(params.fileID))}/metadata`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * update_metadata purchase_order_files
   *
   * Set a file's metadata
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderFilesService.PurchaseOrderFilesUpdateMetadataParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `organizationID`:
   *
   * - `fileID`:
   */
  purchaseOrderFilesUpdateMetadata(params: PurchaseOrderFilesService.PurchaseOrderFilesUpdateMetadataParams): __Observable<null> {
    return this.purchaseOrderFilesUpdateMetadataResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * update_shipment_file purchase_order_files
   *
   * Update shipment file info by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderFilesService.PurchaseOrderFilesUpdateShipmentFileParams` containing the following parameters:
   *
   * - `shipmentFileType`:
   *
   * - `resourceID`:
   *
   * - `organizationID`:
   *
   * - `fileID`:
   *
   * - `shipmentTrackingID`:
   */
  purchaseOrderFilesUpdateShipmentFileResponse(params: PurchaseOrderFilesService.PurchaseOrderFilesUpdateShipmentFileParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.shipmentFileType != null) __params = __params.set('shipmentFileType', params.shipmentFileType.toString());



    if (params.shipmentTrackingID != null) __params = __params.set('shipmentTrackingID', params.shipmentTrackingID.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/files/${encodeURIComponent(String(params.resourceID))}/shipment_file/${encodeURIComponent(String(params.fileID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * update_shipment_file purchase_order_files
   *
   * Update shipment file info by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderFilesService.PurchaseOrderFilesUpdateShipmentFileParams` containing the following parameters:
   *
   * - `shipmentFileType`:
   *
   * - `resourceID`:
   *
   * - `organizationID`:
   *
   * - `fileID`:
   *
   * - `shipmentTrackingID`:
   */
  purchaseOrderFilesUpdateShipmentFile(params: PurchaseOrderFilesService.PurchaseOrderFilesUpdateShipmentFileParams): __Observable<null> {
    return this.purchaseOrderFilesUpdateShipmentFileResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * add purchase_order_files
   *
   * Upload file
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderFilesService.PurchaseOrderFilesAdd1Params` containing the following parameters:
   *
   * - `resourceType`:
   *
   * - `resourceID`:
   *
   * - `organizationID`:
   *
   * - `file`: uploaded file
   *
   * - `tag`:
   *
   * - `shipmentTrackingID`:
   *
   * - `shipmentFileType`:
   *
   * - `periodTag`:
   *
   * - `isMainFile`:
   *
   * - `isMainContractFile`:
   *
   * - `isApprovalRequired`:
   */
  purchaseOrderFilesAdd1Response(params: PurchaseOrderFilesService.PurchaseOrderFilesAdd1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.resourceType != null) __params = __params.set('resourceType', params.resourceType.toString());


    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    if (params.tag != null) __params = __params.set('tag', params.tag.toString());
    if (params.shipmentTrackingID != null) __params = __params.set('shipmentTrackingID', params.shipmentTrackingID.toString());
    if (params.shipmentFileType != null) __params = __params.set('shipmentFileType', params.shipmentFileType.toString());
    if (params.periodTag != null) __params = __params.set('periodTag', params.periodTag.toString());
    if (params.isMainFile != null) __params = __params.set('isMainFile', params.isMainFile.toString());
    if (params.isMainContractFile != null) __params = __params.set('isMainContractFile', params.isMainContractFile.toString());
    if (params.isApprovalRequired != null) __params = __params.set('isApprovalRequired', params.isApprovalRequired.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/files/${encodeURIComponent(String(params.resourceID))}/upload`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add purchase_order_files
   *
   * Upload file
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderFilesService.PurchaseOrderFilesAdd1Params` containing the following parameters:
   *
   * - `resourceType`:
   *
   * - `resourceID`:
   *
   * - `organizationID`:
   *
   * - `file`: uploaded file
   *
   * - `tag`:
   *
   * - `shipmentTrackingID`:
   *
   * - `shipmentFileType`:
   *
   * - `periodTag`:
   *
   * - `isMainFile`:
   *
   * - `isMainContractFile`:
   *
   * - `isApprovalRequired`:
   */
  purchaseOrderFilesAdd1(params: PurchaseOrderFilesService.PurchaseOrderFilesAdd1Params): __Observable<null> {
    return this.purchaseOrderFilesAdd1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * add_link purchase_order_files
   *
   * Upload link file
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderFilesService.PurchaseOrderFilesAddLink1Params` containing the following parameters:
   *
   * - `url`:
   *
   * - `resourceID`:
   *
   * - `organizationID`:
   *
   * - `metadata`:
   *
   * - `isMainFile`:
   */
  purchaseOrderFilesAddLink1Response(params: PurchaseOrderFilesService.PurchaseOrderFilesAddLink1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.url != null) __params = __params.set('url', params.url.toString());


    if (params.metadata != null) __params = __params.set('metadata', params.metadata.toString());
    if (params.isMainFile != null) __params = __params.set('isMainFile', params.isMainFile.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/files/${encodeURIComponent(String(params.resourceID))}/upload_link`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add_link purchase_order_files
   *
   * Upload link file
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderFilesService.PurchaseOrderFilesAddLink1Params` containing the following parameters:
   *
   * - `url`:
   *
   * - `resourceID`:
   *
   * - `organizationID`:
   *
   * - `metadata`:
   *
   * - `isMainFile`:
   */
  purchaseOrderFilesAddLink1(params: PurchaseOrderFilesService.PurchaseOrderFilesAddLink1Params): __Observable<null> {
    return this.purchaseOrderFilesAddLink1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * full_list purchase_order_files
   *
   * List all PO's files info.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderFilesService.PurchaseOrderFilesFullList1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  purchaseOrderFilesFullList1Response(params: PurchaseOrderFilesService.PurchaseOrderFilesFullList1Params): __Observable<__StrictHttpResponse<LasgroPurchase_order_files>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/purchase_order_all_files`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroPurchase_order_files>;
      })
    );
  }
  /**
   * full_list purchase_order_files
   *
   * List all PO's files info.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderFilesService.PurchaseOrderFilesFullList1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  purchaseOrderFilesFullList1(params: PurchaseOrderFilesService.PurchaseOrderFilesFullList1Params): __Observable<LasgroPurchase_order_files> {
    return this.purchaseOrderFilesFullList1Response(params).pipe(
      __map(_r => _r.body as LasgroPurchase_order_files)
    );
  }

  /**
   * list purchase_order_files
   *
   * List all PO's files info.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderFilesService.PurchaseOrderFilesList1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `tag`:
   *
   * - `isProduct`:
   *
   * - `isLine`:
   *
   * @return OK
   */
  purchaseOrderFilesList1Response(params: PurchaseOrderFilesService.PurchaseOrderFilesList1Params): __Observable<__StrictHttpResponse<LasgroFile_infoCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.tag != null) __params = __params.set('tag', params.tag.toString());
    if (params.isProduct != null) __params = __params.set('isProduct', params.isProduct.toString());
    if (params.isLine != null) __params = __params.set('isLine', params.isLine.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/purchase_order_files`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroFile_infoCollection>;
      })
    );
  }
  /**
   * list purchase_order_files
   *
   * List all PO's files info.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderFilesService.PurchaseOrderFilesList1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `tag`:
   *
   * - `isProduct`:
   *
   * - `isLine`:
   *
   * @return OK
   */
  purchaseOrderFilesList1(params: PurchaseOrderFilesService.PurchaseOrderFilesList1Params): __Observable<LasgroFile_infoCollection> {
    return this.purchaseOrderFilesList1Response(params).pipe(
      __map(_r => _r.body as LasgroFile_infoCollection)
    );
  }

  /**
   * rcdss_create_contract_document_link purchase_order_files
   *
   * Create a link to a contract document
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderFilesService.PurchaseOrderFilesRcdssCreateContractDocumentLinkParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  purchaseOrderFilesRcdssCreateContractDocumentLinkResponse(params: PurchaseOrderFilesService.PurchaseOrderFilesRcdssCreateContractDocumentLinkParams): __Observable<__StrictHttpResponse<LasgroPanda_doc_link_data>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/purchase_order_files/rcdss_create_contract_document_link`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroPanda_doc_link_data>;
      })
    );
  }
  /**
   * rcdss_create_contract_document_link purchase_order_files
   *
   * Create a link to a contract document
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderFilesService.PurchaseOrderFilesRcdssCreateContractDocumentLinkParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  purchaseOrderFilesRcdssCreateContractDocumentLink(params: PurchaseOrderFilesService.PurchaseOrderFilesRcdssCreateContractDocumentLinkParams): __Observable<LasgroPanda_doc_link_data> {
    return this.purchaseOrderFilesRcdssCreateContractDocumentLinkResponse(params).pipe(
      __map(_r => _r.body as LasgroPanda_doc_link_data)
    );
  }

  /**
   * rcdss_download_contract_file purchase_order_files
   *
   * Download contract file by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderFilesService.PurchaseOrderFilesRcdssDownloadContractFileParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   */
  purchaseOrderFilesRcdssDownloadContractFileResponse(params: PurchaseOrderFilesService.PurchaseOrderFilesRcdssDownloadContractFileParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/purchase_order_files/rcdss_download_contract_file`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * rcdss_download_contract_file purchase_order_files
   *
   * Download contract file by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderFilesService.PurchaseOrderFilesRcdssDownloadContractFileParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   */
  purchaseOrderFilesRcdssDownloadContractFile(params: PurchaseOrderFilesService.PurchaseOrderFilesRcdssDownloadContractFileParams): __Observable<null> {
    return this.purchaseOrderFilesRcdssDownloadContractFileResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * rcdss_remove_contract_file purchase_order_files
   *
   * Remove contract file
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderFilesService.PurchaseOrderFilesRcdssRemoveContractFileParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   */
  purchaseOrderFilesRcdssRemoveContractFileResponse(params: PurchaseOrderFilesService.PurchaseOrderFilesRcdssRemoveContractFileParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/purchase_order_files/rcdss_remove_contract_file`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * rcdss_remove_contract_file purchase_order_files
   *
   * Remove contract file
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderFilesService.PurchaseOrderFilesRcdssRemoveContractFileParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   */
  purchaseOrderFilesRcdssRemoveContractFile(params: PurchaseOrderFilesService.PurchaseOrderFilesRcdssRemoveContractFileParams): __Observable<null> {
    return this.purchaseOrderFilesRcdssRemoveContractFileResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * rcdss_upload_contract_file purchase_order_files
   *
   * Update contract file
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderFilesService.PurchaseOrderFilesRcdssUploadContractFileParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `file`: uploaded file
   *
   * - `vendorSignatoryID`:
   *
   * @return OK
   */
  purchaseOrderFilesRcdssUploadContractFileResponse(params: PurchaseOrderFilesService.PurchaseOrderFilesRcdssUploadContractFileParams): __Observable<__StrictHttpResponse<LasgroPanda_doc_document>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;


    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    if (params.vendorSignatoryID != null) __params = __params.set('vendorSignatoryID', params.vendorSignatoryID.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/purchase_order_files/rcdss_upload_contract_file`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroPanda_doc_document>;
      })
    );
  }
  /**
   * rcdss_upload_contract_file purchase_order_files
   *
   * Update contract file
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderFilesService.PurchaseOrderFilesRcdssUploadContractFileParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `file`: uploaded file
   *
   * - `vendorSignatoryID`:
   *
   * @return OK
   */
  purchaseOrderFilesRcdssUploadContractFile(params: PurchaseOrderFilesService.PurchaseOrderFilesRcdssUploadContractFileParams): __Observable<LasgroPanda_doc_document> {
    return this.purchaseOrderFilesRcdssUploadContractFileResponse(params).pipe(
      __map(_r => _r.body as LasgroPanda_doc_document)
    );
  }

  /**
   * show purchase_order_files
   *
   * Download PO file by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderFilesService.PurchaseOrderFilesShow1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `fileID`:
   *
   * - `isProduct`:
   *
   * - `isLine`:
   */
  purchaseOrderFilesShow1Response(params: PurchaseOrderFilesService.PurchaseOrderFilesShow1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    if (params.isProduct != null) __params = __params.set('isProduct', params.isProduct.toString());
    if (params.isLine != null) __params = __params.set('isLine', params.isLine.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/purchase_order_files/${encodeURIComponent(String(params.fileID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * show purchase_order_files
   *
   * Download PO file by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderFilesService.PurchaseOrderFilesShow1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `fileID`:
   *
   * - `isProduct`:
   *
   * - `isLine`:
   */
  purchaseOrderFilesShow1(params: PurchaseOrderFilesService.PurchaseOrderFilesShow1Params): __Observable<null> {
    return this.purchaseOrderFilesShow1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * update purchase_order_files
   *
   * Update file details
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderFilesService.PurchaseOrderFilesUpdate1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   *
   * - `fileID`:
   *
   * - `isProduct`:
   *
   * - `isLine`:
   *
   * @return OK
   */
  purchaseOrderFilesUpdate1Response(params: PurchaseOrderFilesService.PurchaseOrderFilesUpdate1Params): __Observable<__StrictHttpResponse<LasgroFile_info>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;


    if (params.isProduct != null) __params = __params.set('isProduct', params.isProduct.toString());
    if (params.isLine != null) __params = __params.set('isLine', params.isLine.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/purchase_order_files/${encodeURIComponent(String(params.fileID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroFile_info>;
      })
    );
  }
  /**
   * update purchase_order_files
   *
   * Update file details
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderFilesService.PurchaseOrderFilesUpdate1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   *
   * - `fileID`:
   *
   * - `isProduct`:
   *
   * - `isLine`:
   *
   * @return OK
   */
  purchaseOrderFilesUpdate1(params: PurchaseOrderFilesService.PurchaseOrderFilesUpdate1Params): __Observable<LasgroFile_info> {
    return this.purchaseOrderFilesUpdate1Response(params).pipe(
      __map(_r => _r.body as LasgroFile_info)
    );
  }

  /**
   * delete purchase_order_files
   *
   * Deletes checklist with all tasks.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderFilesService.PurchaseOrderFilesDelete1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `fileID`:
   *
   * - `isProduct`:
   *
   * - `isLine`:
   */
  purchaseOrderFilesDelete1Response(params: PurchaseOrderFilesService.PurchaseOrderFilesDelete1Params): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    if (params.isProduct != null) __params = __params.set('isProduct', params.isProduct.toString());
    if (params.isLine != null) __params = __params.set('isLine', params.isLine.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/purchase_order_files/${encodeURIComponent(String(params.fileID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete purchase_order_files
   *
   * Deletes checklist with all tasks.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderFilesService.PurchaseOrderFilesDelete1Params` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `fileID`:
   *
   * - `isProduct`:
   *
   * - `isLine`:
   */
  purchaseOrderFilesDelete1(params: PurchaseOrderFilesService.PurchaseOrderFilesDelete1Params): __Observable<null> {
    return this.purchaseOrderFilesDelete1Response(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * show purchase_order_files
   *
   * Download PO file by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderFilesService.PurchaseOrderFilesShowParams` containing the following parameters:
   *
   * - `fileID`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `isProduct`:
   *
   * - `isLine`:
   */
  purchaseOrderFilesShowResponse(params: PurchaseOrderFilesService.PurchaseOrderFilesShowParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.purchaseOrderID != null) __params = __params.set('purchaseOrderID', params.purchaseOrderID.toString());
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    if (params.isProduct != null) __params = __params.set('isProduct', params.isProduct.toString());
    if (params.isLine != null) __params = __params.set('isLine', params.isLine.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/purchase_order_files/${encodeURIComponent(String(params.fileID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * show purchase_order_files
   *
   * Download PO file by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderFilesService.PurchaseOrderFilesShowParams` containing the following parameters:
   *
   * - `fileID`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `isProduct`:
   *
   * - `isLine`:
   */
  purchaseOrderFilesShow(params: PurchaseOrderFilesService.PurchaseOrderFilesShowParams): __Observable<null> {
    return this.purchaseOrderFilesShowResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * update purchase_order_files
   *
   * Update file details
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderFilesService.PurchaseOrderFilesUpdateParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `fileID`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `isProduct`:
   *
   * - `isLine`:
   *
   * @return OK
   */
  purchaseOrderFilesUpdateResponse(params: PurchaseOrderFilesService.PurchaseOrderFilesUpdateParams): __Observable<__StrictHttpResponse<LasgroFile_info>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    if (params.purchaseOrderID != null) __params = __params.set('purchaseOrderID', params.purchaseOrderID.toString());
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    if (params.isProduct != null) __params = __params.set('isProduct', params.isProduct.toString());
    if (params.isLine != null) __params = __params.set('isLine', params.isLine.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/purchase_order_files/${encodeURIComponent(String(params.fileID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroFile_info>;
      })
    );
  }
  /**
   * update purchase_order_files
   *
   * Update file details
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderFilesService.PurchaseOrderFilesUpdateParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `fileID`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `isProduct`:
   *
   * - `isLine`:
   *
   * @return OK
   */
  purchaseOrderFilesUpdate(params: PurchaseOrderFilesService.PurchaseOrderFilesUpdateParams): __Observable<LasgroFile_info> {
    return this.purchaseOrderFilesUpdateResponse(params).pipe(
      __map(_r => _r.body as LasgroFile_info)
    );
  }

  /**
   * delete purchase_order_files
   *
   * Deletes checklist with all tasks.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderFilesService.PurchaseOrderFilesDeleteParams` containing the following parameters:
   *
   * - `fileID`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `isProduct`:
   *
   * - `isLine`:
   */
  purchaseOrderFilesDeleteResponse(params: PurchaseOrderFilesService.PurchaseOrderFilesDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.purchaseOrderID != null) __params = __params.set('purchaseOrderID', params.purchaseOrderID.toString());
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    if (params.isProduct != null) __params = __params.set('isProduct', params.isProduct.toString());
    if (params.isLine != null) __params = __params.set('isLine', params.isLine.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/purchase_order_files/${encodeURIComponent(String(params.fileID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete purchase_order_files
   *
   * Deletes checklist with all tasks.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderFilesService.PurchaseOrderFilesDeleteParams` containing the following parameters:
   *
   * - `fileID`:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `isProduct`:
   *
   * - `isLine`:
   */
  purchaseOrderFilesDelete(params: PurchaseOrderFilesService.PurchaseOrderFilesDeleteParams): __Observable<null> {
    return this.purchaseOrderFilesDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * set_main_file purchase_order_files
   *
   * Sets the file as the main file for its PO.
   *
   * Required security scopes:
   *   * `api:access`
   * @param fileID undefined
   */
  purchaseOrderFilesSetMainFileResponse(fileID: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/purchase_order_files/${encodeURIComponent(String(fileID))}/set_main_file`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * set_main_file purchase_order_files
   *
   * Sets the file as the main file for its PO.
   *
   * Required security scopes:
   *   * `api:access`
   * @param fileID undefined
   */
  purchaseOrderFilesSetMainFile(fileID: string): __Observable<null> {
    return this.purchaseOrderFilesSetMainFileResponse(fileID).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * full_list purchase_order_files
   *
   * List all PO's files info.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderFilesService.PurchaseOrderFilesFullListParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  purchaseOrderFilesFullListResponse(params: PurchaseOrderFilesService.PurchaseOrderFilesFullListParams): __Observable<__StrictHttpResponse<LasgroPurchase_order_files>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/purchase_order_all_files`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroPurchase_order_files>;
      })
    );
  }
  /**
   * full_list purchase_order_files
   *
   * List all PO's files info.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderFilesService.PurchaseOrderFilesFullListParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  purchaseOrderFilesFullList(params: PurchaseOrderFilesService.PurchaseOrderFilesFullListParams): __Observable<LasgroPurchase_order_files> {
    return this.purchaseOrderFilesFullListResponse(params).pipe(
      __map(_r => _r.body as LasgroPurchase_order_files)
    );
  }

  /**
   * list purchase_order_files
   *
   * List all PO's files info.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderFilesService.PurchaseOrderFilesListParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `tag`:
   *
   * - `organizationID`:
   *
   * - `isProduct`:
   *
   * - `isLine`:
   *
   * @return OK
   */
  purchaseOrderFilesListResponse(params: PurchaseOrderFilesService.PurchaseOrderFilesListParams): __Observable<__StrictHttpResponse<LasgroFile_infoCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.tag != null) __params = __params.set('tag', params.tag.toString());
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    if (params.isProduct != null) __params = __params.set('isProduct', params.isProduct.toString());
    if (params.isLine != null) __params = __params.set('isLine', params.isLine.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/purchase_order_files`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroFile_infoCollection>;
      })
    );
  }
  /**
   * list purchase_order_files
   *
   * List all PO's files info.
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderFilesService.PurchaseOrderFilesListParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `tag`:
   *
   * - `organizationID`:
   *
   * - `isProduct`:
   *
   * - `isLine`:
   *
   * @return OK
   */
  purchaseOrderFilesList(params: PurchaseOrderFilesService.PurchaseOrderFilesListParams): __Observable<LasgroFile_infoCollection> {
    return this.purchaseOrderFilesListResponse(params).pipe(
      __map(_r => _r.body as LasgroFile_infoCollection)
    );
  }

  /**
   * convert_and_merge purchase_order_files
   *
   * Converts(if necessary) and merges all the provided files into one pdf
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderFilesService.PurchaseOrderFilesConvertAndMergeParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`: input payload for converting and merging files into pdf
   *
   * @return OK
   */
  purchaseOrderFilesConvertAndMergeResponse(params: PurchaseOrderFilesService.PurchaseOrderFilesConvertAndMergeParams): __Observable<__StrictHttpResponse<LasgroGeneral_success_payload>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/purchase_order_files`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroGeneral_success_payload>;
      })
    );
  }
  /**
   * convert_and_merge purchase_order_files
   *
   * Converts(if necessary) and merges all the provided files into one pdf
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderFilesService.PurchaseOrderFilesConvertAndMergeParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`: input payload for converting and merging files into pdf
   *
   * @return OK
   */
  purchaseOrderFilesConvertAndMerge(params: PurchaseOrderFilesService.PurchaseOrderFilesConvertAndMergeParams): __Observable<LasgroGeneral_success_payload> {
    return this.purchaseOrderFilesConvertAndMergeResponse(params).pipe(
      __map(_r => _r.body as LasgroGeneral_success_payload)
    );
  }

  /**
   * update_file_tags purchase_order_files
   *
   * Updates a file's tags
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderFilesService.PurchaseOrderFilesUpdateFileTagsParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`: input payload for updating a file's tags
   *
   * - `fileID`:
   */
  purchaseOrderFilesUpdateFileTagsResponse(params: PurchaseOrderFilesService.PurchaseOrderFilesUpdateFileTagsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/purchase_order_files/${encodeURIComponent(String(params.fileID))}/tags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * update_file_tags purchase_order_files
   *
   * Updates a file's tags
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderFilesService.PurchaseOrderFilesUpdateFileTagsParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`: input payload for updating a file's tags
   *
   * - `fileID`:
   */
  purchaseOrderFilesUpdateFileTags(params: PurchaseOrderFilesService.PurchaseOrderFilesUpdateFileTagsParams): __Observable<null> {
    return this.purchaseOrderFilesUpdateFileTagsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module PurchaseOrderFilesService {

  /**
   * Parameters for purchaseOrderFilesAdd
   */
  export interface PurchaseOrderFilesAddParams {
    resourceType: 'PURCHASE_ORDER' | 'PRODUCT';
    resourceID: string;

    /**
     * uploaded file
     */
    file: Blob;
    tag?: string;
    shipmentTrackingID?: string;
    shipmentFileType?: 'PACKING_LIST' | 'BILL_OF_LADING' | 'IMPORTER_SECURITY_FILLING' | 'INSPECTION_DOCUMENT';
    periodTag?: string;
    organizationID?: string;
    isMainFile?: boolean;
    isMainContractFile?: boolean;
    isApprovalRequired?: boolean;
  }

  /**
   * Parameters for purchaseOrderFilesAddLink
   */
  export interface PurchaseOrderFilesAddLinkParams {
    url: string;
    resourceID: string;
    organizationID?: string;
    metadata?: string;
    isMainFile?: boolean;
  }

  /**
   * Parameters for purchaseOrderFilesUpdateMetadata
   */
  export interface PurchaseOrderFilesUpdateMetadataParams {
    payload: UpdateMetadataPurchaseOrderFilesPayload;
    organizationID: string;
    fileID: string;
  }

  /**
   * Parameters for purchaseOrderFilesUpdateShipmentFile
   */
  export interface PurchaseOrderFilesUpdateShipmentFileParams {
    shipmentFileType: 'PACKING_LIST' | 'BILL_OF_LADING' | 'IMPORTER_SECURITY_FILLING' | 'INSPECTION_DOCUMENT';
    resourceID: string;
    organizationID: string;
    fileID: string;
    shipmentTrackingID?: string;
  }

  /**
   * Parameters for purchaseOrderFilesAdd1
   */
  export interface PurchaseOrderFilesAdd1Params {
    resourceType: 'PURCHASE_ORDER' | 'PRODUCT';
    resourceID: string;
    organizationID: string;

    /**
     * uploaded file
     */
    file: Blob;
    tag?: string;
    shipmentTrackingID?: string;
    shipmentFileType?: 'PACKING_LIST' | 'BILL_OF_LADING' | 'IMPORTER_SECURITY_FILLING' | 'INSPECTION_DOCUMENT';
    periodTag?: string;
    isMainFile?: boolean;
    isMainContractFile?: boolean;
    isApprovalRequired?: boolean;
  }

  /**
   * Parameters for purchaseOrderFilesAddLink1
   */
  export interface PurchaseOrderFilesAddLink1Params {
    url: string;
    resourceID: string;
    organizationID: string;
    metadata?: string;
    isMainFile?: boolean;
  }

  /**
   * Parameters for purchaseOrderFilesFullList1
   */
  export interface PurchaseOrderFilesFullList1Params {
    purchaseOrderID: string;
    organizationID: string;
  }

  /**
   * Parameters for purchaseOrderFilesList1
   */
  export interface PurchaseOrderFilesList1Params {
    purchaseOrderID: string;
    organizationID: string;
    tag?: string;
    isProduct?: boolean;
    isLine?: boolean;
  }

  /**
   * Parameters for purchaseOrderFilesRcdssCreateContractDocumentLink
   */
  export interface PurchaseOrderFilesRcdssCreateContractDocumentLinkParams {
    purchaseOrderID: string;
    organizationID: string;
  }

  /**
   * Parameters for purchaseOrderFilesRcdssDownloadContractFile
   */
  export interface PurchaseOrderFilesRcdssDownloadContractFileParams {
    purchaseOrderID: string;
    organizationID: string;
  }

  /**
   * Parameters for purchaseOrderFilesRcdssRemoveContractFile
   */
  export interface PurchaseOrderFilesRcdssRemoveContractFileParams {
    purchaseOrderID: string;
    organizationID: string;
  }

  /**
   * Parameters for purchaseOrderFilesRcdssUploadContractFile
   */
  export interface PurchaseOrderFilesRcdssUploadContractFileParams {
    purchaseOrderID: string;
    organizationID: string;

    /**
     * uploaded file
     */
    file: Blob;
    vendorSignatoryID?: string;
  }

  /**
   * Parameters for purchaseOrderFilesShow1
   */
  export interface PurchaseOrderFilesShow1Params {
    purchaseOrderID: string;
    organizationID: string;
    fileID: string;
    isProduct?: boolean;
    isLine?: boolean;
  }

  /**
   * Parameters for purchaseOrderFilesUpdate1
   */
  export interface PurchaseOrderFilesUpdate1Params {
    purchaseOrderID: string;
    payload: FileInfoInputPayload;
    organizationID: string;
    fileID: string;
    isProduct?: boolean;
    isLine?: boolean;
  }

  /**
   * Parameters for purchaseOrderFilesDelete1
   */
  export interface PurchaseOrderFilesDelete1Params {
    purchaseOrderID: string;
    organizationID: string;
    fileID: string;
    isProduct?: boolean;
    isLine?: boolean;
  }

  /**
   * Parameters for purchaseOrderFilesShow
   */
  export interface PurchaseOrderFilesShowParams {
    fileID: string;
    purchaseOrderID?: string;
    organizationID?: string;
    isProduct?: boolean;
    isLine?: boolean;
  }

  /**
   * Parameters for purchaseOrderFilesUpdate
   */
  export interface PurchaseOrderFilesUpdateParams {
    payload: FileInfoInputPayload;
    fileID: string;
    purchaseOrderID?: string;
    organizationID?: string;
    isProduct?: boolean;
    isLine?: boolean;
  }

  /**
   * Parameters for purchaseOrderFilesDelete
   */
  export interface PurchaseOrderFilesDeleteParams {
    fileID: string;
    purchaseOrderID?: string;
    organizationID?: string;
    isProduct?: boolean;
    isLine?: boolean;
  }

  /**
   * Parameters for purchaseOrderFilesFullList
   */
  export interface PurchaseOrderFilesFullListParams {
    purchaseOrderID: string;
    organizationID?: string;
  }

  /**
   * Parameters for purchaseOrderFilesList
   */
  export interface PurchaseOrderFilesListParams {
    purchaseOrderID: string;
    tag?: string;
    organizationID?: string;
    isProduct?: boolean;
    isLine?: boolean;
  }

  /**
   * Parameters for purchaseOrderFilesConvertAndMerge
   */
  export interface PurchaseOrderFilesConvertAndMergeParams {
    purchaseOrderID: string;

    /**
     * input payload for converting and merging files into pdf
     */
    payload: FileConvertAndMergeInputPayload;
  }

  /**
   * Parameters for purchaseOrderFilesUpdateFileTags
   */
  export interface PurchaseOrderFilesUpdateFileTagsParams {
    purchaseOrderID: string;

    /**
     * input payload for updating a file's tags
     */
    payload: FileTagUpdateInputPayload;
    fileID: string;
  }
}

export { PurchaseOrderFilesService }
