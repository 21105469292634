/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroFinancial_report_signer } from '../models/lasgro-financial-_report-_signer';
import { FinancialReportSignerInputPayload } from '../models/financial-report-signer-input-payload';
import { LasgroFinancial_report_signerCollection } from '../models/lasgro-financial-_report-_signer-collection';
@Injectable({
  providedIn: 'root',
})
class FinancialReportSignerService extends __BaseService {
  static readonly financialReportSignerUpdatePath = '/financial-report-signer';
  static readonly financialReportSignerAddPath = '/financial-report-signer';
  static readonly financialReportSignerShowPath = '/financial-report-signer/{financialReportSignerID}';
  static readonly financialReportSignerDeletePath = '/financial-report-signer/{financialReportSignerID}';
  static readonly financialReportSignerListPath = '/financial-report-signers/{organizationID}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * update financial-report-signer
   *
   * Updates a financial report signer
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload The input payload for financial report signer
   * @return OK
   */
  financialReportSignerUpdateResponse(payload: FinancialReportSignerInputPayload): __Observable<__StrictHttpResponse<LasgroFinancial_report_signer>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/financial-report-signer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroFinancial_report_signer>;
      })
    );
  }
  /**
   * update financial-report-signer
   *
   * Updates a financial report signer
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload The input payload for financial report signer
   * @return OK
   */
  financialReportSignerUpdate(payload: FinancialReportSignerInputPayload): __Observable<LasgroFinancial_report_signer> {
    return this.financialReportSignerUpdateResponse(payload).pipe(
      __map(_r => _r.body as LasgroFinancial_report_signer)
    );
  }

  /**
   * add financial-report-signer
   *
   * Add a new financial report signer
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload The input payload for financial report signer
   */
  financialReportSignerAddResponse(payload: FinancialReportSignerInputPayload): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/financial-report-signer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add financial-report-signer
   *
   * Add a new financial report signer
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload The input payload for financial report signer
   */
  financialReportSignerAdd(payload: FinancialReportSignerInputPayload): __Observable<null> {
    return this.financialReportSignerAddResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * show financial-report-signer
   *
   * Get financial report signer by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param financialReportSignerID financial report signer ID
   * @return OK
   */
  financialReportSignerShowResponse(financialReportSignerID: string): __Observable<__StrictHttpResponse<LasgroFinancial_report_signer>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/financial-report-signer/${encodeURIComponent(String(financialReportSignerID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroFinancial_report_signer>;
      })
    );
  }
  /**
   * show financial-report-signer
   *
   * Get financial report signer by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param financialReportSignerID financial report signer ID
   * @return OK
   */
  financialReportSignerShow(financialReportSignerID: string): __Observable<LasgroFinancial_report_signer> {
    return this.financialReportSignerShowResponse(financialReportSignerID).pipe(
      __map(_r => _r.body as LasgroFinancial_report_signer)
    );
  }

  /**
   * delete financial-report-signer
   *
   * Delete financial report signer by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param financialReportSignerID Purchase Order financial report signer ID
   */
  financialReportSignerDeleteResponse(financialReportSignerID: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/financial-report-signer/${encodeURIComponent(String(financialReportSignerID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete financial-report-signer
   *
   * Delete financial report signer by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param financialReportSignerID Purchase Order financial report signer ID
   */
  financialReportSignerDelete(financialReportSignerID: string): __Observable<null> {
    return this.financialReportSignerDeleteResponse(financialReportSignerID).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * list financial-report-signer
   *
   * Lists financial report signers
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID Organization ID
   * @return OK
   */
  financialReportSignerListResponse(organizationID: string): __Observable<__StrictHttpResponse<LasgroFinancial_report_signerCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/financial-report-signers/${encodeURIComponent(String(organizationID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroFinancial_report_signerCollection>;
      })
    );
  }
  /**
   * list financial-report-signer
   *
   * Lists financial report signers
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID Organization ID
   * @return OK
   */
  financialReportSignerList(organizationID: string): __Observable<LasgroFinancial_report_signerCollection> {
    return this.financialReportSignerListResponse(organizationID).pipe(
      __map(_r => _r.body as LasgroFinancial_report_signerCollection)
    );
  }
}

module FinancialReportSignerService {
}

export { FinancialReportSignerService }
