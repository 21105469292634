/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroOrganization_notificationCollection } from '../models/lasgro-organization-_notification-collection';
import { LasgroOrganization_notification } from '../models/lasgro-organization-_notification';
import { NotificationClearByListIDPayload } from '../models/notification-clear-by-list-idpayload';
@Injectable({
  providedIn: 'root',
})
class OrganizationNotificationService extends __BaseService {
  static readonly organizationNotificationListPath = '/organizations/{organizationID}/notifications';
  static readonly organizationNotificationClearAllPath = '/organizations/{organizationID}/notifications';
  static readonly organizationNotificationClearByListIdPath = '/organizations/{organizationID}/notifications/clear_by_list_id';
  static readonly organizationNotificationClearPath = '/organizations/{organizationID}/notifications/{notificationID}';
  static readonly organizationNotificationEscalatePath = '/organizations/{organizationID}/notifications/{notificationID}/escalate';
  static readonly organizationNotificationPostponePath = '/organizations/{organizationID}/notifications/{notificationID}/postpone';
  static readonly organizationNotificationUserReadNotificationsPath = '/organizations/{organizationID}/notifications_readen';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * list organization_notification
   *
   * Lists all available notifications
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `OrganizationNotificationService.OrganizationNotificationListParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `filter`: Determines how to filter the notifications to be retrieved
   *
   * @return OK
   */
  organizationNotificationListResponse(params: OrganizationNotificationService.OrganizationNotificationListParams): __Observable<__StrictHttpResponse<LasgroOrganization_notificationCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/notifications`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOrganization_notificationCollection>;
      })
    );
  }
  /**
   * list organization_notification
   *
   * Lists all available notifications
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `OrganizationNotificationService.OrganizationNotificationListParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `filter`: Determines how to filter the notifications to be retrieved
   *
   * @return OK
   */
  organizationNotificationList(params: OrganizationNotificationService.OrganizationNotificationListParams): __Observable<LasgroOrganization_notificationCollection> {
    return this.organizationNotificationListResponse(params).pipe(
      __map(_r => _r.body as LasgroOrganization_notificationCollection)
    );
  }

  /**
   * clear_all organization_notification
   *
   * Clears all notifications
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  organizationNotificationClearAllResponse(organizationID: string): __Observable<__StrictHttpResponse<LasgroOrganization_notification>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/organizations/${encodeURIComponent(String(organizationID))}/notifications`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOrganization_notification>;
      })
    );
  }
  /**
   * clear_all organization_notification
   *
   * Clears all notifications
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  organizationNotificationClearAll(organizationID: string): __Observable<LasgroOrganization_notification> {
    return this.organizationNotificationClearAllResponse(organizationID).pipe(
      __map(_r => _r.body as LasgroOrganization_notification)
    );
  }

  /**
   * clear_by_list_id organization_notification
   *
   * Clears by notifications list id
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `OrganizationNotificationService.OrganizationNotificationClearByListIdParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  organizationNotificationClearByListIdResponse(params: OrganizationNotificationService.OrganizationNotificationClearByListIdParams): __Observable<__StrictHttpResponse<LasgroOrganization_notification>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/notifications/clear_by_list_id`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOrganization_notification>;
      })
    );
  }
  /**
   * clear_by_list_id organization_notification
   *
   * Clears by notifications list id
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `OrganizationNotificationService.OrganizationNotificationClearByListIdParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  organizationNotificationClearByListId(params: OrganizationNotificationService.OrganizationNotificationClearByListIdParams): __Observable<LasgroOrganization_notification> {
    return this.organizationNotificationClearByListIdResponse(params).pipe(
      __map(_r => _r.body as LasgroOrganization_notification)
    );
  }

  /**
   * clear organization_notification
   *
   * Clears a notification
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `OrganizationNotificationService.OrganizationNotificationClearParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `notificationID`:
   *
   * @return OK
   */
  organizationNotificationClearResponse(params: OrganizationNotificationService.OrganizationNotificationClearParams): __Observable<__StrictHttpResponse<LasgroOrganization_notification>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/notifications/${encodeURIComponent(String(params.notificationID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOrganization_notification>;
      })
    );
  }
  /**
   * clear organization_notification
   *
   * Clears a notification
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `OrganizationNotificationService.OrganizationNotificationClearParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `notificationID`:
   *
   * @return OK
   */
  organizationNotificationClear(params: OrganizationNotificationService.OrganizationNotificationClearParams): __Observable<LasgroOrganization_notification> {
    return this.organizationNotificationClearResponse(params).pipe(
      __map(_r => _r.body as LasgroOrganization_notification)
    );
  }

  /**
   * escalate organization_notification
   *
   * Escalates a notification
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `OrganizationNotificationService.OrganizationNotificationEscalateParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `notificationID`:
   *
   * @return OK
   */
  organizationNotificationEscalateResponse(params: OrganizationNotificationService.OrganizationNotificationEscalateParams): __Observable<__StrictHttpResponse<LasgroOrganization_notification>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/notifications/${encodeURIComponent(String(params.notificationID))}/escalate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOrganization_notification>;
      })
    );
  }
  /**
   * escalate organization_notification
   *
   * Escalates a notification
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `OrganizationNotificationService.OrganizationNotificationEscalateParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `notificationID`:
   *
   * @return OK
   */
  organizationNotificationEscalate(params: OrganizationNotificationService.OrganizationNotificationEscalateParams): __Observable<LasgroOrganization_notification> {
    return this.organizationNotificationEscalateResponse(params).pipe(
      __map(_r => _r.body as LasgroOrganization_notification)
    );
  }

  /**
   * postpone organization_notification
   *
   * Postpone a notifications
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `OrganizationNotificationService.OrganizationNotificationPostponeParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `notificationID`:
   *
   * - `days`:
   *
   * @return OK
   */
  organizationNotificationPostponeResponse(params: OrganizationNotificationService.OrganizationNotificationPostponeParams): __Observable<__StrictHttpResponse<LasgroOrganization_notification>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.days != null) __params = __params.set('days', params.days.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/notifications/${encodeURIComponent(String(params.notificationID))}/postpone`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOrganization_notification>;
      })
    );
  }
  /**
   * postpone organization_notification
   *
   * Postpone a notifications
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `OrganizationNotificationService.OrganizationNotificationPostponeParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `notificationID`:
   *
   * - `days`:
   *
   * @return OK
   */
  organizationNotificationPostpone(params: OrganizationNotificationService.OrganizationNotificationPostponeParams): __Observable<LasgroOrganization_notification> {
    return this.organizationNotificationPostponeResponse(params).pipe(
      __map(_r => _r.body as LasgroOrganization_notification)
    );
  }

  /**
   * user_read_notifications organization_notification
   *
   * Update user timestamp on when the notifications were readen
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  organizationNotificationUserReadNotificationsResponse(organizationID: string): __Observable<__StrictHttpResponse<LasgroOrganization_notification>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(organizationID))}/notifications_readen`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOrganization_notification>;
      })
    );
  }
  /**
   * user_read_notifications organization_notification
   *
   * Update user timestamp on when the notifications were readen
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  organizationNotificationUserReadNotifications(organizationID: string): __Observable<LasgroOrganization_notification> {
    return this.organizationNotificationUserReadNotificationsResponse(organizationID).pipe(
      __map(_r => _r.body as LasgroOrganization_notification)
    );
  }
}

module OrganizationNotificationService {

  /**
   * Parameters for organizationNotificationList
   */
  export interface OrganizationNotificationListParams {
    organizationID: string;

    /**
     * Determines how to filter the notifications to be retrieved
     */
    filter?: 'escalated' | 'non-escalated' | 'all' | 'subscribed';
  }

  /**
   * Parameters for organizationNotificationClearByListId
   */
  export interface OrganizationNotificationClearByListIdParams {
    payload: NotificationClearByListIDPayload;
    organizationID: string;
  }

  /**
   * Parameters for organizationNotificationClear
   */
  export interface OrganizationNotificationClearParams {
    organizationID: string;
    notificationID: string;
  }

  /**
   * Parameters for organizationNotificationEscalate
   */
  export interface OrganizationNotificationEscalateParams {
    organizationID: string;
    notificationID: string;
  }

  /**
   * Parameters for organizationNotificationPostpone
   */
  export interface OrganizationNotificationPostponeParams {
    organizationID: string;
    notificationID: string;
    days: number;
  }
}

export { OrganizationNotificationService }
