/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroSigner } from '../models/lasgro-signer';
import { SignerInputPayload } from '../models/signer-input-payload';
import { LasgroSignerCollection } from '../models/lasgro-signer-collection';
@Injectable({
  providedIn: 'root',
})
class SignerService extends __BaseService {
  static readonly signerUpdatePath = '/signer';
  static readonly signerAddPath = '/signer';
  static readonly signerShowPath = '/signer/{signerID}';
  static readonly signerDeletePath = '/signer/{signerID}';
  static readonly signerListPath = '/signers/{organizationID}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * update signer
   *
   * Updates a signer
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload The input payload for signer
   * @return OK
   */
  signerUpdateResponse(payload: SignerInputPayload): __Observable<__StrictHttpResponse<LasgroSigner>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/signer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroSigner>;
      })
    );
  }
  /**
   * update signer
   *
   * Updates a signer
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload The input payload for signer
   * @return OK
   */
  signerUpdate(payload: SignerInputPayload): __Observable<LasgroSigner> {
    return this.signerUpdateResponse(payload).pipe(
      __map(_r => _r.body as LasgroSigner)
    );
  }

  /**
   * add signer
   *
   * Add a new signer
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload The input payload for signer
   */
  signerAddResponse(payload: SignerInputPayload): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/signer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add signer
   *
   * Add a new signer
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload The input payload for signer
   */
  signerAdd(payload: SignerInputPayload): __Observable<null> {
    return this.signerAddResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * show signer
   *
   * Get signer by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param signerID signer ID
   * @return OK
   */
  signerShowResponse(signerID: string): __Observable<__StrictHttpResponse<LasgroSigner>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/signer/${encodeURIComponent(String(signerID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroSigner>;
      })
    );
  }
  /**
   * show signer
   *
   * Get signer by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param signerID signer ID
   * @return OK
   */
  signerShow(signerID: string): __Observable<LasgroSigner> {
    return this.signerShowResponse(signerID).pipe(
      __map(_r => _r.body as LasgroSigner)
    );
  }

  /**
   * delete signer
   *
   * Delete signer by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param signerID Purchase Order signer ID
   */
  signerDeleteResponse(signerID: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/signer/${encodeURIComponent(String(signerID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete signer
   *
   * Delete signer by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param signerID Purchase Order signer ID
   */
  signerDelete(signerID: string): __Observable<null> {
    return this.signerDeleteResponse(signerID).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * list signer
   *
   * Lists signers
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID Organization ID
   * @return OK
   */
  signerListResponse(organizationID: string): __Observable<__StrictHttpResponse<LasgroSignerCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/signers/${encodeURIComponent(String(organizationID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroSignerCollection>;
      })
    );
  }
  /**
   * list signer
   *
   * Lists signers
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID Organization ID
   * @return OK
   */
  signerList(organizationID: string): __Observable<LasgroSignerCollection> {
    return this.signerListResponse(organizationID).pipe(
      __map(_r => _r.body as LasgroSignerCollection)
    );
  }
}

module SignerService {
}

export { SignerService }
