import { FileItem } from '@models/file-item.model';
import { ContainerData, Milestone, OceanTrackingData, VesselData } from '@models/enhanced_tracking-data';
import * as faker from 'faker';

export class ShipmentFile {
  customer_po_number: string;
  file: FileItem;
}

export class AssociatedPos {
  purchase_order_id?: string;
  customer_po_number?: string;
  supplier_name?: string;

  constructor(data?) {
    Object.assign(this, data);
  }
}

export class ShipmentLineItem {
  id?: string;
  customer_po_number?: string;
  purchase_order_id?: string;
  shipment_tracking_id?: string;
  line_item_id: string;
  product_id?: string;
  total_ordered?: number;
  quantity_shipped: number;
  total_shipped_to_date?: number;
  name?: string;
}

export class Reference {
  reference_id: string;
  organization_id: string;
  shipment_tracking_id: string;
  name: string;
  color?: string;
}

export class Shipment {
  created_at: string;
  created_by: string;
  created_by_name: string;
  description: string;
  id: string;
  organization_id: string;
  name: string;
  file: ShipmentFile;
  files: ShipmentFile[];
  line_items: ShipmentLineItem[];
  link: string;
  purchase_order_id: string;
  shipped_at: string;
  arrives_at: string;
  tracking_code: string;
  references?: Reference[];

  enhanced_tracking_carrier_id?: string;
  enhanced_tracking_identifier_type?: string;
  enhanced_tracking_identifier?: string;

  enhanced_tracking_data: OceanTrackingData;
  associated_pos: AssociatedPos[];

  customer_po_number?: string;
  supplier_name?: string;

  is_lane_estimable?: boolean;

  constructor(data?) {
    Object.assign(this, data);
    if (data.enhanced_tracking_data) {
      this.enhanced_tracking_data = new OceanTrackingData(data.enhanced_tracking_data);
    }
    if (data.associated_pos) {
      this.associated_pos = data.associated_pos.map(a => new AssociatedPos(a));
    }
  }
}

export function randomShipmentData() {
  const arr = [];
  const statusOptions = [
    'Vessel departure from T/S port 1',
    'Vessel arrival at final POD',
    'Container discharge at final POD',
    'Container departure from final POD (Gate out)',
    'Container delivery to consignee',
    'Container empty return to depot',
  ];
  const vesselNames = [
    'KMTC MUNDRA',
    'CMA CGM LEO',
  ];
  for (let i = 0; i < 20; i++) {
    const shipment = new Shipment({
      associated_pos: [
        { customer_po_number: faker.datatype.number(), supplier_name: faker.company.companyName() },
        { customer_po_number: faker.datatype.number(), supplier_name: faker.company.companyName() },
      ],
      enhanced_tracking_data: {
        last_carrier_update: faker.date.recent(),
        last_actuals_update: faker.date.recent(),
        container: new ContainerData({
          container_number: faker.datatype.number(),
          bol_number: faker.datatype.number(),
          booking_number: faker.datatype.number(),
          container_type_iso: faker.lorem.word(),
        }),
        current_milestone: new Milestone({
          status: faker.random.arrayElement(statusOptions),
          planned_initial: faker.date.past(),
          planned_last: faker.date.past(),
          actual: faker.date.recent(),
        }),
        pod_milestone: new Milestone({
          status: faker.random.arrayElement(statusOptions),
          planned_initial: faker.date.past(),
          planned_last: faker.date.past(),
          actual: faker.date.recent(),
          location: faker.address.cityName(),
          latitude: parseFloat(faker.address.latitude()),
          longitude: parseFloat(faker.address.latitude()),
        }),
        origin_milestone: new Milestone({
          status: faker.random.arrayElement(statusOptions),
          planned_initial: faker.date.past(),
          planned_last: faker.date.past(),
          location: faker.address.cityName(),
          latitude: parseFloat(faker.address.latitude()),
          longitude: parseFloat(faker.address.latitude()),
        }),
        current_vessel: new VesselData({
          name: faker.random.arrayElement(vesselNames),
          latitude: parseFloat(faker.address.latitude()),
          longitude: parseFloat(faker.address.latitude()),
        }),
        milestones: [1, 2, 3].map(m => new Milestone({
          status: faker.random.arrayElement(statusOptions),
          planned_initial: faker.date.past(),
          planned_last: faker.date.past(),
          location: faker.address.cityName(),
          actual: faker.date.recent(),
          // latitude: parseFloat(faker.address.latitude()),
          // longitude: parseFloat(faker.address.latitude()),
        })),
      },
    });
    arr.push(shipment);
  }
  return arr;
}
