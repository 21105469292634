import { ThemeModule } from '@theme/theme.module';
import { APP_BASE_HREF } from '@angular/common';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthGuardService, TokenInterceptor } from '@app/helpers';
import { IonSocketsModule } from '@app/ion-sockets/ion-sockets.module';
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AuthService } from '@services/auth.service';
import { DashboardService } from '@services/dashboard.service';
import { FileService } from '@services/file.service';
import { NotificationService } from '@services/notification.service';
import { PreviousRouteService } from '@services/previousroute.service';
import { SweetAlertService } from '@services/sweet-alert.service';
import { ToasterModule } from 'angular2-toaster';
import { DragulaModule } from 'ng2-dragula';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from 'ngx-perfect-scrollbar';
// local storage library
import { LocalStorageService, NgxWebstorageModule } from 'ngx-webstorage';
import { CoreModule } from './@core/core.module';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { DashboardModule } from './modules/dashboard/dashboard.module';
// import { ReportsModule } from './modules/reports/reports.module';
import { NgxUserSnapModule } from './ngx-usersnap/ngx-user-snap.module';
import {
  AccountService,
  Broadcaster,
  ClientCompanyService,
  InvoiceService,
  LayoutService,
  SharedService,
  Toaster,
} from './services';
import { UserEffects } from './store/effects/user.effects';
import { userReducer } from './store/reducers/user.reducer';
import { CalendlyModule } from '@app/modules/calendly/calendly.module';
import { CalendlyService } from '@app/modules/calendly/services/calendly.service';
import { NguiMapModule } from '@ngui/map';
import { environment } from '@env/environment';
import { MarkdownModule } from 'ngx-markdown';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ApiModule } from 'api/api.module';
import { MatButtonModule } from '@angular/material/button';
import * as $ from 'jquery';

export function jwtOptionsFactory(localStorageService) {
  return {
    tokenGetter: () => {
      return localStorageService.retrieve('token');
    },
  };
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  // suppressScrollX: true,
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    CoreModule.forRoot(),
    DragulaModule.forRoot(),
    NgxWebstorageModule.forRoot({ prefix: 'ev', separator: '_', caseSensitive: true }),
    EffectsModule.forRoot([UserEffects]),
    StoreModule.forRoot({ user: userReducer }),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [LocalStorageService],
      },
    }),
    ToasterModule,
    DashboardModule,
    // ReportsModule,
    NbEvaIconsModule,
    IonSocketsModule.forRoot(),
    NgxUserSnapModule.forRoot({
      apiUrl: 'https://widget.usersnap.com/load/ce1154e9-a22a-4634-b7d1-0fa14a12417c',
      apiKey: 'ce1154e9-a22a-4634-b7d1-0fa14a12417c',
    }),
    MatDatepickerModule,
    MatMomentDateModule,
    PerfectScrollbarModule,
    CalendlyModule,
    NguiMapModule.forRoot({ apiUrl: `https://maps.google.com/maps/api/js?key=${environment.googleMapsApiKey}` }),
    MarkdownModule.forRoot(),
    MatSnackBarModule,
    ThemeModule.forRoot(),
    ApiModule.forRoot({ rootUrl: environment.apiUrl }),
  ],
  bootstrap: [AppComponent],
  providers: [
    AuthService,
    AuthGuardService,
    DashboardService,
    AccountService,
    PreviousRouteService,
    SharedService,
    Broadcaster,
    SweetAlertService,
    FileService,
    NotificationService,
    InvoiceService,
    LayoutService,
    ClientCompanyService,
    Toaster,
    CalendlyService,
    {
      provide: APP_BASE_HREF,
      useValue: '/',
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
})
export class AppModule {}
