import { Directive, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { FileService } from '@app/services';
import { resultMemoize } from '@ngrx/store';

@Directive({
  selector: '[ngxFilesPreview]',
})
export class FilesPreviewDirective {
  @Input() fileId;
  @Input() fileName;
  @Output() finishedLoading: EventEmitter<any> = new EventEmitter<boolean>();

  constructor(private fileService: FileService) {}

  @HostListener('click', ['$event'])
  clickHandler($event) {
    this.fileService.downloadFile(this.fileId).subscribe(
      (result) => {
        this.finishedLoading.emit(true);
        const fileExt = this.fileName.split('.').pop();
        if (fileExt === 'pdf') {
          this.fileService.viewPdf(result, this.fileName);
        } else if (['jpg', 'jpeg', 'png', 'svg', 'webp'].includes(fileExt)) {
          this.fileService.viewImage(result, this.fileName);
        } else {
          this.fileService.downloadBlobFile(result, this.fileName);
        }
      },
      (error) => {
        this.finishedLoading.emit(true);
        console.error(error);
      },
    );
  }
}
