import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { throwIfAlreadyLoaded } from '@app/@core/module-import-guard';
import { ConfigOption } from '@app/ion-sockets/config-options';
import { SocketsConnectorService } from '@app/ion-sockets/services/sockets-connector.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  providers: [
    SocketsConnectorService,
  ],
})
export class IonSocketsModule {
  constructor(@Optional() @SkipSelf() parentModule: IonSocketsModule) {
    throwIfAlreadyLoaded(parentModule, 'IonSocketsModule');
  }

  static forRoot(config: ConfigOption = {}): ModuleWithProviders<IonSocketsModule>  {
    return {
      ngModule: IonSocketsModule,
      providers: [
        SocketsConnectorService,
      ],
    };
  }
}
