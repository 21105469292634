import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FileService } from '@services/file.service';

@Component({
  selector: 'ngx-download-file',
  templateUrl: './download-file.component.html',
  styleUrls: ['./download-file.component.scss'],
})
export class DownloadFileComponent implements OnInit {
  downloaded;

  constructor(private activatedRoute: ActivatedRoute,
              private fileService: FileService,
  ) { }

  ngOnInit() {
    setTimeout(() => this.getFile(), 1000);
  }

  getFile() {
    this.fileService.downloadFile(this.activatedRoute.snapshot.params.fileId)
      .subscribe(result => {
        this.fileService.downloadBlobFile(result,'test');
        this.downloaded = true;
      });
  }

}
