import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@env/environment';


@Pipe({
  name: 'pendingStatusToWaitingForApproval',
})
export class PendingStatusToWaitingForApprovalPipe implements PipeTransform {
  isRCDSS = environment.name === 'rcdss';

  transform(value: string = ''): any {
    return this.getStatus(value);
  }

  getStatus(value: string) {
    if (value && value.toLowerCase() === 'pending') {
      // if (!this.isRCDSS) {
        return 'Waiting for Approval';
      // } else {
        // return 'Sent to Vendor';
      // }
    }

    return value;
  }
}
