<div class="modal-header po-modal font-weight-bold">
  TRAINING GUIDES
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body ev-form po-modal text-center d-flex flex-column align-items-center">
  <h5 class="mb-4">Click any of the buttons to download:</h5>
  <a
    href="https://rcdss-documents.s3.amazonaws.com/guides/Alerts+and+Contracts.pdf"
    target="_blank"
    download class="btn btn-success btn-download mb-4">
    ALERTS AND CONTRACTS
  </a>
  <a
    href="https://rcdss-documents.s3.amazonaws.com/guides/Required+Contract+Documents+and+Forms.pdf"
    target="_blank"
    download class="btn btn-success btn-download mb-4">
    REQUIRED CONTRACT DOCUMENTS AND FORMS
  </a>
  <a
    href="https://rcdss-documents.s3.amazonaws.com/guides/Required+Voucher+Documents+%26+Forms.pdf"
    target="_blank"
    download class="btn btn-success btn-download mb-4">
    REQUIRED VOUCHER DOCUMENTS & FORMS
  </a>
  <a
    href="https://rcdss-documents.s3.amazonaws.com/guides/Sending+a+Message.pdf"
    target="_blank"
    download class="btn btn-success btn-download mb-4">
    SENDING A MESSAGE
  </a>
  <a
    href="https://rcdss-documents.s3.amazonaws.com/guides/Task+Management.pdf"
    target="_blank"
    download class="btn btn-success btn-download mb-4">
    TASK MANAGEMENT
  </a>

</div>

 

 

