import { Company } from './company.model';
import { Step } from './step.model';
import { User } from './user.model';

export class Product {
  id: string;
  name: string;
  description: string;
  organization_id: string;
  created_at: any;
  href: string;
  customer_id: string;
  customer_part_identifier: string;
  customer_part_id: string;
  updated_at: string;
  editable: boolean;

  // custom properties for the frontend
  // customer identifiers
  partIdentifiers: any;

  // array of steps
  steps: Step[];

  barcode: string;

  files?: any;
  url_files?: any;

  created_by?: string;
  creator: {
    user: User;
    company: Company;
  };
  unit_length?: number;
  unit_height?: number;
  unit_width?: number;
  unit_weight?: number;
  case_length?: number;
  case_height?: number;
  case_width?: number;
  case_weight?: number;
  units_per_case?: number;

  case_barcode?: string;
  master_pack_barcode?: string;
  currency?: string;
  unit_price?: number;
  case_price?: number;
  master_pack_price?: number;
  unit_length_mu_id?: number;
  unit_weight_mu_id?: number;
  master_pack_length?: number;
  master_pack_height?: number;
  master_pack_width?: number;
  cases_per_master_pack?: number;

  unit_length_mu?: MeasurementUnit;
  unit_weight_mu?: MeasurementUnit;
  cbm?: number;

  constructor(data?) {
    Object.assign(this, data);
  }
}

export class MeasurementUnit {
  id: number;
  type: string;
  name: string;
  alias: string;
}

export class ProductHistory {
  purchase_order_id: string;
  customer_po_number: string;
  product_id: string;
  product_name: string;
  customer_part_id: string;
  quantity: number;
  currency: string;
  unit_price: number;
  total_price: number;
}
