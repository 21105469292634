<!--
If you want to get focus on an element when opening the modal you need to add an
id="main_focus" to the DOM element you want to focus on

If you want to add a hint label to the button in order to indicate which filter is active use the
'btnHint' property
-->

<ngx-filter-container [btnTitle]="getTitle('number')" typePill="number" (onFilter)="onFilter('number', $event)">
  <div class="d-flex flex-column">
    <span>
      Write a {{isRCDSS ? 'Contract number' : 'Purchase Order number'}} if you want to look for a specific one.
    </span>
    <mat-form-field>
      <input
        ngxClickOnEnter="number"
        matInput
        [placeholder]="isRCDSS ? 'Contract number' : 'Purchase Order number'"
        [attr.aria-label]="isRCDSS ? 'Contract number' : 'Purchase Order number'"
        [(ngModel)]="selectedPO"
        id="main_focus"
      />
    </mat-form-field>
  </div>
</ngx-filter-container>

<ngx-filter-container [btnTitle]="getTitle('product')" typePill="product" (onFilter)="onFilter('product', $event)"
  *ngIf="!isRCDSS">
  <div class="d-flex flex-column">
    <span>
      Write a Line Item if you want to know about {{isRCDSS ? 'Contracts' : 'Purchase Orders'}} that have them.
    </span>
    <mat-form-field>
      <input
        ngxClickOnEnter="product"
        matInput
        placeholder="Line Item"
        aria-label="Line Item"
        [(ngModel)]="selectedProduct"
        id="main_focus"
      />
    </mat-form-field>
  </div>
</ngx-filter-container>

<!-- <ngx-filter-container [btnTitle]="Program" typePill="program" (onFilter)="onFilter('', $event)"
  *ngIf="isRCDSS">
  <div class="d-flex flex-column">
    <span>
    </span>
    <mat-form-field>
      <input
        matInput
        placeholder=""
        aria-label=""
      />
    </mat-form-field>
  </div>
</ngx-filter-container> -->

<ngx-filter-container *ngIf="!isRCDSS || (isRCDSS && companyType === 'customer')" [btnTitle]="getTitle('company')" typePill="company" (onFilter)="onFilter('company', $event)">
  <div class="d-flex flex-column">
    <small>
      Select your {{ isCustomer ? 'Vendor' : 'Department' }} from the list below. You can filter the result by writing
      the name.
    </small>
    <!-- {{ isRCDSS ? (isCustomer ? 'Vendor' : 'Department') : (isCustomer ? 'Supplier' : 'Customer'); }} -->
    <mat-form-field>
      <input
        ngxClickOnEnter="company"
        matInput
        [matAutocomplete]="auto"
        [formControl]="companyCtrl"
        [placeholder]="isCustomer ? 'Vendor' : 'Department'"
      />
        <!-- [placeholder]="isRCDSS ? (isCustomer ? 'Vendor' : 'Department') : (isCustomer ? 'Supplier' : 'Customer');" -->
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let company of filteredCompanies | async" [value]="company.name">
          {{ company.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
</ngx-filter-container>

<ngx-filter-container
  *ngIf="!isRCDSS"
  btnHint="Order Date"
  [btnTitle]="getTitle('order')"
  typePill="order"
  (onFilter)="onFilter('order', $event)"
>
  <ngx-date-selector
    (dateSelected)="selectedOrderDates = $event"
    [fromDate]="selectedOrderDates && selectedOrderDates[0] ? selectedOrderDates[0] : null"
    [untilDate]="selectedOrderDates && selectedOrderDates[1] ? selectedOrderDates[1] : null"
  ></ngx-date-selector>
</ngx-filter-container>

<ngx-filter-container
  *ngIf="!isRCDSS"
  btnHint="Start Ship Date"
  [btnTitle]="getTitle('ship')"
  typePill="ship"
  (onFilter)="onFilter('ship', $event)"
>
  <ngx-date-selector
    (dateSelected)="selectedShipDates = $event"
    [fromDate]="selectedShipDates && selectedShipDates[0] ? selectedShipDates[0] : null"
    [untilDate]="selectedShipDates && selectedShipDates[1] ? selectedShipDates[1] : null"
  ></ngx-date-selector>
</ngx-filter-container>

<ngx-filter-container
  btnHint="{{ isRCDSS ? 'Due' : 'Cancel'}} Date"
  [btnTitle]="getTitle('cancel')"
  typePill="cancel"
  (onFilter)="onFilter('cancel', $event)"
>
  <ngx-date-selector
    (dateSelected)="selectedCancelDates = $event"
    [fromDate]="selectedCancelDates && selectedCancelDates[0] ? selectedCancelDates[0] : null"
    [untilDate]="selectedCancelDates && selectedCancelDates[1] ? selectedCancelDates[1] : null"
  ></ngx-date-selector>
</ngx-filter-container>

<ngx-filter-container *ngIf="!isRCDSS" [btnTitle]="getTitle('status')" typePill="status" (onFilter)="onFilter('status', $event)">
  <div class="d-flex flex-column">
    <small>Select a status for the {{isRCDSS ? 'Contracts' : 'Purchase Orders'}} you want to see.</small>
    <mat-form-field>
      <mat-select [(value)]="selectedStatus">
        <mat-option>---</mat-option>
        <mat-option value="pending">{{ 'Pending' | pendingStatusToWaitingForApproval }}</mat-option>
        <mat-option value="open">Open</mat-option>
        <mat-option value="rejected">Rejected</mat-option>
        <mat-option value="closed">Closed</mat-option>
        <mat-option value="archived">Archived</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</ngx-filter-container>

<!-- <ngx-filter-container *ngIf="isRCDSS" [btnTitle]="Status" typePill="status" (onFilter)="onFilter('', $event)">
  <div class="d-flex flex-column">
    <small>Select a stage for the Contracts you want to see.</small>
    <mat-form-field>
      <mat-select>
        <mat-option>---</mat-option>
        <mat-option value="">Application form submission</mat-option>
        <mat-option value="">Application form approval</mat-option>
        <mat-option value="">Waiting for contract submission</mat-option>
        <mat-option value="">Contract consumed</mat-option>
        <mat-option value="">Application rejected</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</ngx-filter-container> -->

<ngx-filter-container btnTitle="Completed" typePill="completed" (onFilter)="onFilter('completed', $event)"
  *ngIf="!isRCDSS">
  <small>Click the slider to see only completed {{isRCDSS ? 'Contracts' : 'Purchase Orders'}}.</small>
  <mat-slide-toggle [(ngModel)]="completedPOs" color="primary"></mat-slide-toggle>
</ngx-filter-container>
