/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroFinancial_report_column } from '../models/lasgro-financial-_report-_column';
import { FinancialReportColumnInputPayload } from '../models/financial-report-column-input-payload';
import { LasgroFinancial_report_columnCollection } from '../models/lasgro-financial-_report-_column-collection';
@Injectable({
  providedIn: 'root',
})
class FinancialReportColumnService extends __BaseService {
  static readonly financialReportColumnUpdatePath = '/purchase_order/financial_report_column';
  static readonly financialReportColumnShowPath = '/purchase_order/financial_report_column/{financialReportColumnID}';
  static readonly financialReportColumnListPath = '/purchase_order/financial_report_columns/{financialReportTypeID}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * update financial_report_column
   *
   * Updates a financial report column
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload The input payload for financial report column
   * @return OK
   */
  financialReportColumnUpdateResponse(payload: FinancialReportColumnInputPayload): __Observable<__StrictHttpResponse<LasgroFinancial_report_column>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/purchase_order/financial_report_column`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroFinancial_report_column>;
      })
    );
  }
  /**
   * update financial_report_column
   *
   * Updates a financial report column
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload The input payload for financial report column
   * @return OK
   */
  financialReportColumnUpdate(payload: FinancialReportColumnInputPayload): __Observable<LasgroFinancial_report_column> {
    return this.financialReportColumnUpdateResponse(payload).pipe(
      __map(_r => _r.body as LasgroFinancial_report_column)
    );
  }

  /**
   * show financial_report_column
   *
   * Get financial report column by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param financialReportColumnID Financial Report column ID
   * @return OK
   */
  financialReportColumnShowResponse(financialReportColumnID: string): __Observable<__StrictHttpResponse<LasgroFinancial_report_column>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/purchase_order/financial_report_column/${encodeURIComponent(String(financialReportColumnID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroFinancial_report_column>;
      })
    );
  }
  /**
   * show financial_report_column
   *
   * Get financial report column by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param financialReportColumnID Financial Report column ID
   * @return OK
   */
  financialReportColumnShow(financialReportColumnID: string): __Observable<LasgroFinancial_report_column> {
    return this.financialReportColumnShowResponse(financialReportColumnID).pipe(
      __map(_r => _r.body as LasgroFinancial_report_column)
    );
  }

  /**
   * list financial_report_column
   *
   * Lists financial report columns
   *
   * Required security scopes:
   *   * `api:access`
   * @param financialReportTypeID Financial Report type ID
   * @return OK
   */
  financialReportColumnListResponse(financialReportTypeID: string): __Observable<__StrictHttpResponse<LasgroFinancial_report_columnCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/purchase_order/financial_report_columns/${encodeURIComponent(String(financialReportTypeID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroFinancial_report_columnCollection>;
      })
    );
  }
  /**
   * list financial_report_column
   *
   * Lists financial report columns
   *
   * Required security scopes:
   *   * `api:access`
   * @param financialReportTypeID Financial Report type ID
   * @return OK
   */
  financialReportColumnList(financialReportTypeID: string): __Observable<LasgroFinancial_report_columnCollection> {
    return this.financialReportColumnListResponse(financialReportTypeID).pipe(
      __map(_r => _r.body as LasgroFinancial_report_columnCollection)
    );
  }
}

module FinancialReportColumnService {
}

export { FinancialReportColumnService }
