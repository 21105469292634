// Static values taken from the DB
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SelectBox } from './../models';

class SelectOption {
  id: number;
  name: string;
}

class Subscription {
  value: string;
  title: string;
}

@Injectable()
export class SharedService {
  constructor(private http: HttpClient) {
    this.http = http;
  }

  purchaseOrderStatus(): string[] {
    return ['pending', 'approved', 'rejected', 'closed', 'archived'];
  }

  currencies(): string[] {
    return ['USD', 'GBP', 'EUR', 'CNY'];
  }

  taxTypes(): SelectBox[] {
    return [{ id: 1, name: 'EIN' }, { id: 2, name: 'DUNS' }];
  }

  roles(): SelectBox[] {
    return [{ id: 6, name: 'Manager' }, { id: 13, name: 'Company Admin' }];
  }

  rolesEmployees(): SelectBox[] {
    return [{ id: 7, name: 'Supervisor' }, { id: 8, name: 'Worker' }];
  }

  frequencies(): { text: string, value: string }[] {
    return [
      { text: 'No report needed', value: 'No report needed' },
      { text: 'Every day', value: 'daily' },
      { text: 'Once a week', value: 'weekly' },
      { text: 'Every two weeks', value: 'biweekly' },
      { text: 'Once a month', value: 'monthly' },
    ];
  }

  shipmentFileTypes(): { text: string, value: string}[] {
    return [
      { text: 'Packing List', value: 'PACKING_LIST'},
      { text: 'Bill Of Lading', value: 'BILL_OF_LADING'},
      { text: 'Importer Security Filling', value: 'IMPORTER_SECURITY_FILLING'},
      { text: 'Inspection Document', value: 'INSPECTION_DOCUMENT'},
    ];
  }

  priorities(): SelectOption[] {
    return [{ id: 1, name: 'Low' }, { id: 2, name: 'Medium' }, { id: 3, name: 'High' }];
  }

  terms(): SelectOption[] {
    return [{ id: 1, name: 'NET 15' }, { id: 2, name: 'NET 30' }, { id: 3, name: 'NET 45' }];
  }

  shiftNames(): string[] {
    return ['First Shift', 'Second Shift', 'Third Shift'];
  }

  shifts() {
    return [
      {
        shift_id: 'ed3acebf-a386-4346-82ad-bdc19a6eba6a',
        shift_name: 'Morning',
      },
      {
        shift_id: '8dd3f44e-ba5a-497d-8158-e9519c17279b',
        shift_name: 'Afternoon',
      },
      {
        shift_id: 'b4b94703-eef1-46bf-b81c-35c0acc4767d',
        shift_name: 'Evening',
      },
      {
        shift_id: '7455e339-e8b1-4115-9beb-44d5413d98db',
        shift_name: 'Night',
      },
    ];
  }

  riskLevels() {
    return [{ id: 1, name: 'low' }, { id: 3, name: 'high' }, { id: 2, name: 'medium' }, { id: 4, name: 'critical' }];
  }

  payInterval(): string[] {
    return ['weeks', 'months', 'days'];
  }

  subscriptions(): Subscription[] {
    return [
      { value: 'surechain', title: 'iONSupply' },
    ];
  }
}
