import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'dateformat',
})
export class DatePipe implements PipeTransform {
    transform(array: string): any {
            return moment(array.replace('T02:00:00+02:00', '')).format('MMM D');
    }
}
