import { Batch } from './batch.model';
import { Product } from './product.model';
import { PurchaseOrderDto } from '@app/models/purchase-order.model.dto';
import { LineItemInputPayload } from 'api/models';

export interface ILineItem extends LineItemInputPayload {
  cancel_date: any;
  created_at: any;
  href: string;
  id: string;
  identifier: string;
  is_parent: boolean;
  order_date: any;
  parent_line_item_id: string;
  purchase_order_id: string;
  quantity_complete: number;
  quantity_ordered: number;
  shipped_to_date: number;
  product_id: string;
  ship_date: any;
  status: "open" | "closed" | "archived" | "rejected" | "pending";
  unit_price: number;
  unit_price_currency: "USD" | "GBP" | "EUR" | "CNY";
  updated_at: any;
  barcode?: string;


  // temporary fields
  product_name: string;
  customer_part_id: string;
  customer_part_identifier?: string;

  batches: Batch[];

  product: Product;
}

export class LineItem implements ILineItem {
  cancel_date: any;
  created_at: any;
  href: string;
  id: string;
  identifier: string;
  is_parent: boolean;
  order_date: any;
  parent_line_item_id: string;
  purchase_order_id: string;
  quantity_complete: number;
  quantity_ordered: number;
  shipped_to_date: number;
  product_id: string;
  ship_date: any;
  status: "open" | "closed" | "archived" | "rejected" | "pending";
  unit_price: number;
  unit_price_currency: "USD" | "GBP" | "EUR" | "CNY";
  updated_at: any;
  barcode?: string;


  // temporary fields
  product_name: string;
  customer_part_id: string;
  customer_part_identifier?: string;

  batches: Batch[];

  product: Product;

  constructor(data?: ILineItem) {
    Object.assign(this, data);
  }

  public setPurchaseOrder(po: PurchaseOrderDto) {
    this.purchase_order_id = po.id;
    this.order_date = po.order_date;
    this.cancel_date = po.cancel_date;
  }

  public static fromProduct(product: Product, quantityOrdered: number): LineItem {
    const data = {
      product_id: product.id,
      barcode: product.barcode,
      product_name: product.name,
      quantity_ordered: quantityOrdered,
      unit_price: product.unit_price || 0,
      unit_price_currency: product.currency || 'USD',
      customer_part_id: product.customer_part_id,
    } as ILineItem;

    return new LineItem(data);
  }

}
