/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroFinancial_report_document } from '../models/lasgro-financial-_report-_document';
import { FinancialReportDocumentInputPayload } from '../models/financial-report-document-input-payload';
import { LasgroFinancial_report_documentCollection } from '../models/lasgro-financial-_report-_document-collection';
@Injectable({
  providedIn: 'root',
})
class FinancialReportDocumentService extends __BaseService {
  static readonly financialReportDocumentDownloadPath = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/financial_report_document/download/{financialReportDocumentID}';
  static readonly financialReportDocumentUpdatePath = '/purchase_order/financial_report_document';
  static readonly financialReportDocumentAddPath = '/purchase_order/financial_report_document';
  static readonly financialReportDocumentShowPath = '/purchase_order/financial_report_document/{financialReportDocumentID}';
  static readonly financialReportDocumentDeletePath = '/purchase_order/financial_report_document/{financialReportDocumentID}';
  static readonly financialReportDocumentListPath = '/purchase_order/financial_report_documents/{financialReportID}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * download financial_report_document
   *
   * Download financial report file by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `FinancialReportDocumentService.FinancialReportDocumentDownloadParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `financialReportDocumentID`:
   */
  financialReportDocumentDownloadResponse(params: FinancialReportDocumentService.FinancialReportDocumentDownloadParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/financial_report_document/download/${encodeURIComponent(String(params.financialReportDocumentID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * download financial_report_document
   *
   * Download financial report file by ID
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `FinancialReportDocumentService.FinancialReportDocumentDownloadParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * - `financialReportDocumentID`:
   */
  financialReportDocumentDownload(params: FinancialReportDocumentService.FinancialReportDocumentDownloadParams): __Observable<null> {
    return this.financialReportDocumentDownloadResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * update financial_report_document
   *
   * Updates a financial report document
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload The input payload for financial report document column
   * @return OK
   */
  financialReportDocumentUpdateResponse(payload: FinancialReportDocumentInputPayload): __Observable<__StrictHttpResponse<LasgroFinancial_report_document>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/purchase_order/financial_report_document`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroFinancial_report_document>;
      })
    );
  }
  /**
   * update financial_report_document
   *
   * Updates a financial report document
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload The input payload for financial report document column
   * @return OK
   */
  financialReportDocumentUpdate(payload: FinancialReportDocumentInputPayload): __Observable<LasgroFinancial_report_document> {
    return this.financialReportDocumentUpdateResponse(payload).pipe(
      __map(_r => _r.body as LasgroFinancial_report_document)
    );
  }

  /**
   * add financial_report_document
   *
   * Add a new financial report document
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload The input payload for financial report document column
   */
  financialReportDocumentAddResponse(payload: FinancialReportDocumentInputPayload): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/purchase_order/financial_report_document`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add financial_report_document
   *
   * Add a new financial report document
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload The input payload for financial report document column
   */
  financialReportDocumentAdd(payload: FinancialReportDocumentInputPayload): __Observable<null> {
    return this.financialReportDocumentAddResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * show financial_report_document
   *
   * Get financial report document by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param financialReportDocumentID Financial Report document ID
   * @return OK
   */
  financialReportDocumentShowResponse(financialReportDocumentID: string): __Observable<__StrictHttpResponse<LasgroFinancial_report_document>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/purchase_order/financial_report_document/${encodeURIComponent(String(financialReportDocumentID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroFinancial_report_document>;
      })
    );
  }
  /**
   * show financial_report_document
   *
   * Get financial report document by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param financialReportDocumentID Financial Report document ID
   * @return OK
   */
  financialReportDocumentShow(financialReportDocumentID: string): __Observable<LasgroFinancial_report_document> {
    return this.financialReportDocumentShowResponse(financialReportDocumentID).pipe(
      __map(_r => _r.body as LasgroFinancial_report_document)
    );
  }

  /**
   * delete financial_report_document
   *
   * Delete financial report document by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param financialReportDocumentID Financial Report document ID
   */
  financialReportDocumentDeleteResponse(financialReportDocumentID: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/purchase_order/financial_report_document/${encodeURIComponent(String(financialReportDocumentID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete financial_report_document
   *
   * Delete financial report document by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param financialReportDocumentID Financial Report document ID
   */
  financialReportDocumentDelete(financialReportDocumentID: string): __Observable<null> {
    return this.financialReportDocumentDeleteResponse(financialReportDocumentID).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * list financial_report_document
   *
   * Lists financial report documents
   *
   * Required security scopes:
   *   * `api:access`
   * @param financialReportID Financial Report ID
   * @return OK
   */
  financialReportDocumentListResponse(financialReportID: string): __Observable<__StrictHttpResponse<LasgroFinancial_report_documentCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/purchase_order/financial_report_documents/${encodeURIComponent(String(financialReportID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroFinancial_report_documentCollection>;
      })
    );
  }
  /**
   * list financial_report_document
   *
   * Lists financial report documents
   *
   * Required security scopes:
   *   * `api:access`
   * @param financialReportID Financial Report ID
   * @return OK
   */
  financialReportDocumentList(financialReportID: string): __Observable<LasgroFinancial_report_documentCollection> {
    return this.financialReportDocumentListResponse(financialReportID).pipe(
      __map(_r => _r.body as LasgroFinancial_report_documentCollection)
    );
  }
}

module FinancialReportDocumentService {

  /**
   * Parameters for financialReportDocumentDownload
   */
  export interface FinancialReportDocumentDownloadParams {
    purchaseOrderID: string;
    organizationID: string;
    financialReportDocumentID: string;
  }
}

export { FinancialReportDocumentService }
