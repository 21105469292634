import { FileItem } from '@models/file-item.model';

export enum NOTIFICATION_TYPE {
  HIGH_RISK_PO = 'HIGH_RISK_PO',
  PENDING_PO = 'PENDING_PO',
  PENDING_INVITATION = 'PENDING_INVITATION',
  TASK_ABOUT_TO_EXPIRE = 'TASK_ABOUT_TO_EXPIRE',
  EXPIRED_TASK = 'EXPIRED_TASK',
  ASSIGNED_TASK = 'ASSIGNED_TASK',
  INVITATION_SENT = 'INVITATION_SENT',
  PO_SENT = 'PO_SENT',
  INVOICE_SENT = 'INVOICE_SENT',
  REPORT_FGI = 'REPORT_FGI',
  INVOICE_APPROVED = 'INVOICE_APPROVED',
  INVOICE_REJECTED = 'INVOICE_REJECTED',
  PO_APPROVED = 'PO_APPROVED',
  PO_REJECTED = 'PO_REJECTED',
  PROFORMA_INVOICE_APPROVED = 'PROFORMA_INVOICE_APPROVED',
  PROFORMA_INVOICE_REJECTED = 'PROFORMA_INVOICE_REJECTED',
  PROFORMA_INVOICE_SENT = 'PROFORMA_INVOICE_SENT',
  ONBOARDING_REJECTED = 'ONBOARDING_REJECTED',
  COMPLETED_CONTRACT = 'COMPLETED_CONTRACT',
  REQUIRED_CONTRACT_DOCUMENT_REQUIRES_APPROVAL = 'REQUIRED_CONTRACT_DOCUMENT_REQUIRES_APPROVAL',
  VOUCHER_DOCUMENT_REQUIRES_APPROVAL = 'VOUCHER_DOCUMENT_REQUIRES_APPROVAL',
  REQUIRED_CONTRACT_DOCUMENT_REJECTED = 'REQUIRED_CONTRACT_DOCUMENT_REJECTED',
  VOUCHER_DOCUMENT_REJECTED = 'VOUCHER_DOCUMENT_REJECTED',
  FINAL_CONTRACT_FILE_AVAILABLE = 'FINAL_CONTRACT_FILE_AVAILABLE',
}

export enum RESOURCE_TYPE {
  CHECKLIST = 'CHECKLIST',
  CHECKLIST_TASK = 'CHECKLIST_TASK',
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  INVOICE = 'INVOICE',
  PROFORMA_INVOICE = 'PROFORMA_INVOICE',
  LINE_ITEM = 'LINE_ITEM',
  USER = 'USER',
  COMPANY = 'COMPANY',
  ROUTE = 'ROUTE',
  FILE = 'FILE',
}

export class Notification {
  description: string;
  cleared_by: string[];
  organization_id: string;
  created_at: Date;
  ordering_date: Date;
  escalated_at: Date;
  escalated_by: string;
  id: string;
  message: string;
  notification_type: any;
  parent_resource_id: string;
  priority: number;
  resource_id: string;
  resource_link: string;
  resource_type: any;
  file: FileItem;

  supplier_contact: NotificationContact;
  customer_contact: NotificationContact;
  is_new: boolean;
}

export class NotificationContact {
  organization_name: string;
  email: string;
  first_name: string;
  id: string;
  last_name: string;
  phone_number: string;
}

export function GetNotificationReadableType(type: string, isContractManagement: boolean) {
  switch (type) {
    case NOTIFICATION_TYPE.HIGH_RISK_PO: {
      return isContractManagement ? 'HIGH RISK CONTRACT' : 'HIGH RISK PURCHASE ORDER';
    }
    case NOTIFICATION_TYPE.PENDING_PO: {
      return isContractManagement ? 'CONTRACT WAITING FOR APPROVAL' : 'PURCHASE ORDER WAITING FOR APPROVAL';
    }
    case NOTIFICATION_TYPE.EXPIRED_TASK: {
      return 'OVERDUE TASK';
    }
    case NOTIFICATION_TYPE.ASSIGNED_TASK: {
      return 'TASK WAITING TO BE COMPLETED';
    }
    case NOTIFICATION_TYPE.INVOICE_SENT: {
      return isContractManagement ? 'VOUCHER WAITING FOR APPROVAL' : 'INVOICE WAITING FOR APPROVAL';
    }
    case NOTIFICATION_TYPE.REPORT_FGI: {
      return 'REPORT FGI';
    }
    case NOTIFICATION_TYPE.INVOICE_REJECTED: {
      return isContractManagement ? 'VOUCHER REJECTED' : 'INVOICE REJECTED';
    }
    case NOTIFICATION_TYPE.PO_REJECTED: {
      return isContractManagement ? 'REJECTED CONTRACT' : 'REJECTED PURCHASE ORDER';
    }
    case NOTIFICATION_TYPE.PROFORMA_INVOICE_REJECTED: {
      return 'REJECTED PROFORMA INVOICE';
    }
    case NOTIFICATION_TYPE.PROFORMA_INVOICE_SENT: {
      return isContractManagement ? 'PROFORMA VOUCHER WAITING FOR APPROVAL' : 'PROFORMA INVOICE WAITING FOR APPROVAL';
    }
    case NOTIFICATION_TYPE.ONBOARDING_REJECTED: {
      return 'SUPPLIER ONBOARDING REJECTED';
    }
    case NOTIFICATION_TYPE.COMPLETED_CONTRACT: {
      return 'ALL DIGITAL SIGNATURES COMPLETED';
    }
    case NOTIFICATION_TYPE.FINAL_CONTRACT_FILE_AVAILABLE: {
      return 'CONTRACT HAS BEEN FULLY EXECUTED';
    }
    case NOTIFICATION_TYPE.REQUIRED_CONTRACT_DOCUMENT_REQUIRES_APPROVAL: {
      return 'CONTRACT DOCUMENT WAITING FOR APPROVAL';
    }
    case NOTIFICATION_TYPE.VOUCHER_DOCUMENT_REQUIRES_APPROVAL: {
      return 'VOUCHER DOCUMENT WAITING FOR APPROVAL';
    }
    case NOTIFICATION_TYPE.REQUIRED_CONTRACT_DOCUMENT_REJECTED: {
      return 'REQUIRED CONTRACT DOCUMENT REJECTED';
    }
    case NOTIFICATION_TYPE.VOUCHER_DOCUMENT_REJECTED: {
      return 'VOUCHER DOCUMENT REJECTED';
    }
    default: {
      return '';
    }
  }
}