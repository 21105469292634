<div class="modal-header">
  <h3>
    {{ title }}
  </h3>
  <button
    class="ml-auto"
    mat-raised-button
    color="primary"
    *ngIf="isConfirmationModal"
    mat-flat-button
    type="button"
    (click)="close('confirmed')"
  >
    Confirm
  </button>
  <button type="button" class="close" aria-label="close" (click)="close('close')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <h3 *ngIf="loading">Loading...</h3>
  <iframe [src]="pdfSrc" width="100%" height="100%"></iframe>
  <!-- <ng2-pdfjs-viewer  [openFile]="true" #pdfViewer></ng2-pdfjs-viewer> -->
  <!-- [externalWindow]="true" -->
</div>
