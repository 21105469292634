import { HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Company } from '@models/index';
import { AccountService, CompanyService } from '@services/index';
import { LocalStorage } from 'ngx-webstorage';
import { Observable } from 'rxjs';

@Component({
  selector: 'ngx-company-select',
  templateUrl: './company-select.component.html',
  styleUrls: ['./company-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CompanySelectComponent implements OnInit {
  @LocalStorage() organizationId: string;
  @LocalStorage() companyLogo: string;
  @LocalStorage() siteName: string;
  @LocalStorage() token: string;
  companies: Company[];
  activeCompanyId: string;

  constructor(private $account: AccountService,
              private $company: CompanyService) {}

  ngOnInit() {
    this.activeCompanyId = this.organizationId;
    this.$company.fetchCompany()
      .subscribe(companies => {
        this.companies = companies;
      });
  }

  async switchCompany($event) {
    let id = $event.value;
    const result: HttpResponse<any> = await this.$account.exchangeToken(id);
    const newToken: string = String(result.headers.getAll('Authorization'));

    let c = this.companies.find(c => c.id === id);
    this.organizationId = id;
    this.companyLogo = c?.logo;
    this.siteName = c?.site_name;
    this.token = newToken;
    if (newToken) window.location.reload();
  }
}
