import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'ngxSort',
})
export class SortPipe implements PipeTransform {

  transform(array: any): any {
    return array.sort((a, b) => {
      return moment(a.started_at.replace('T02:00:00+02:00', '')).diff(moment(b.started_at.replace('T02:00:00+02:00', '')));
    });
  }

}
