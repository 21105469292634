import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Company, UserDTO } from '@models/index';
import { User } from '@app/models';
import { CompanyService } from '@services/company.service';
import { Toaster } from '@services/toaster.service';
import { Store } from '@ngrx/store';
import { MentionConfig } from 'angular-mentions';
import { Observable, forkJoin, of, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { LocalStorage } from 'ngx-webstorage';
import { environment } from '@env/environment';
import Swal from 'sweetalert2';
import * as es6printJS from 'print-js';

@Component({
  selector: 'ngx-po-notes-form',
  templateUrl: './po-notes-form.component.html',
  styleUrls: ['./po-notes-form.component.scss'],
})
export class PoNotesFormComponent implements OnInit {
  isRCDSS = environment.name === 'rcdss';

  @Input() unreadMessages: number;
  @Input() poID: string;
  @Input() customerID: string;
  @Input() supplierID: string;
  @Input() allowEdit: boolean;
  @Input() showTitle: boolean = true;
  @Input() notes;
  @Input() supplier: Company;
  @Input() customer: Company;
  @Input() isScrollingEnabled: boolean = false;

  @LocalStorage() organizationId: string;

  @Output() notesChangedEvent: EventEmitter<any> = new EventEmitter<any>();

  user$: Observable<User>;
  currentUser: User;

  visibility = 'PRIVATE';
  visibilityEnabled = true;

  loading = false;

  users: UserDTO[] = [];
  note: string = '';
  noteMentions = [];
  parentNoteID: string;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private $company: CompanyService,
    private $toaster: Toaster,
    private store: Store<{ user: User }>,
  ) {
  }

  get mentionsConfig(): MentionConfig {
    return {
      mentions: [
        {
          items: this.users,
          triggerChar: '@',
          disableSort: true,
          labelKey: 'fullName',
          returnTrigger: true,
          mentionSelect: this.mentionItemSelected,
        },
      ],
    };
  }

  get noteHasMentions() {
    return this.noteMentions.length > 0;
  }

  ngOnInit() {
    this.user$ = this.store.select('user');
    this.user$
      .subscribe((user) => {
        this.currentUser = (user as User);
        if (this.isRCDSS && this.currentUser.organization_id === this.supplierID) {
          this.visibilityEnabled = false;
          this.visibility = 'PUBLIC';
        }
      });

    this.getMentionableUsers();
  }

  getMentionableUsers() {
    const customerCompanyId = this.customerID;
    const supplierCompanyId = this.supplierID;
    return forkJoin(
      [
        this.$company.users(customerCompanyId),
        this.$company.users(supplierCompanyId),
        this.customer ? of(this.customer) : this.$company.getById(customerCompanyId),
        this.supplier ? of(this.supplier) : this.$company.getById(supplierCompanyId),
      ],
    )
      .pipe(takeUntil(this.destroy$))
      .pipe(
        switchMap(([customerUsers, supplierUsers, customerCompany, supplierCompany]) => {
          return of([
            ...customerUsers
              // .filter(u => u.id !== this.currentUser.id)
              .map(u => ({ ...u, company: customerCompany })),
            ...supplierUsers
              // .filter(u => u.id !== this.currentUser.id)
              .map(u => ({ ...u, company: supplierCompany })),
          ]);
        }),
      )
      .subscribe(users => {
        this.users = users.map(u => new UserDTO(u));
      });
  }

  async addNote() {
    this.loading = true;
    try {
      if (this.note.length <= 0 && !this.noteHasMentions) {
        return;
      }

      await this.$company.addNote(this.organizationId, this.poID, {
        content: this.note,
        mentions: this.noteMentions,
        parent_note_id: this.parentNoteID ? this.parentNoteID : undefined,
        visibility: this.visibility,
      });

      this.parentNoteID = undefined;
      this.note = '';
      this.$toaster.show('success', 'Success', 'Message sent');
      if (this.visibilityEnabled === true) {
        this.visibility = 'PRIVATE';
      }
      this.loading = false;
      this.notesChangedEvent.emit();

    } catch (error) {
      console.error(error);
      this.$toaster.show('error', 'Error', 'An error occurred, please try again later.');
      this.loading = false;
    }
  }

  deleteNote(note) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Mentioned users may have a copy of this message in their emails, delete anyways?',
      icon: 'warning',
      showCancelButton: true,
      buttonsStyling: false,
      confirmButtonText: 'Confirm',
      customClass: {
        confirmButton: 'btn btn-primary mr-2',
        cancelButton: 'btn btn-danger ml-2',
      },
      confirmButtonColor: '#41b146',
      cancelButtonColor: '#c74141',
    }).then((swalResult) => {
      if (swalResult && swalResult?.isConfirmed === true) {
        this.loading = true;
        this.$company.deleteNote(this.organizationId, this.poID, note.id)
        .then((result) => {
          this.$toaster.show('success', 'Success', 'The message was deleted successfully.');
          this.loading = false;
          this.notesChangedEvent.emit();
        }, (error) => {
          console.error(error);
          this.loading = false;
          this.$toaster.show('error', 'Error', 'An error ocurred, please try again later');
        });
      }
    }, (error) => {
      console.error(error);
    });
  }

  async handleNoteChange($event: string) {
    const mentionRegex = /@([\w\.]+)/g;
    const regexMatch = $event.match(mentionRegex);

    if (!(regexMatch && regexMatch.length > 0)) {
      this.noteMentions = [];
      return;
    }

    const userIdsMap = new Map<string, boolean>();
    let match = mentionRegex.exec($event);
    let hasOutsideUsers = false;

    while (match != null) {

      const mentionedUserFullName = match[1];
      const mentionedUser = this.users.find(user => user.fullName.split(' ').join('.').split('-').join('.') === mentionedUserFullName);

      if (mentionedUser) {

        console.log(mentionedUser);
        console.log(this.currentUser);

        const showConfirmation = mentionedUser.company.id !== this.currentUser.organization_id &&
          !userIdsMap.get(mentionedUser.id) &&
          this.visibility === 'PRIVATE';

        if (showConfirmation) {
          Swal.fire({
            title: 'Confirmation required',
            text: 'Mentioning an user outside your organization will set this message as public. Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonText: 'Confirm',
            customClass: {
              confirmButton: 'btn btn-primary mr-2',
              cancelButton: 'btn btn-danger ml-2',
            },
            confirmButtonColor: '#41b146',
            cancelButtonColor: '#c74141',
          }).then((swalResult) => {
            if (swalResult && swalResult?.isConfirmed === true) {
              userIdsMap.set(mentionedUser.id, true);
              this.visibility = 'PUBLIC';
              hasOutsideUsers = true;
            }
            if (swalResult && swalResult?.isDismissed === true) {
              this.note = this.note.replace('@'+mentionedUserFullName, '');
            }
          }, (error) => {
            this.note = this.note.replace('@'+mentionedUserFullName, '');
            console.log(error);
          });
        } else {
          if (mentionedUser.company.id !== this.currentUser.organization_id) {
            hasOutsideUsers = true;
          }
          userIdsMap.set(mentionedUser.id, true);
        }

      }

      match = mentionRegex.exec($event);
    }

    if (!hasOutsideUsers && this.visibilityEnabled === true) {
      this.visibility = 'PRIVATE';
    }
    this.noteMentions = Array.from(userIdsMap.keys());
  }

  mentionItemSelected = ($event: UserDTO) => {
    return `@${$event.fullName.split(' ').join('.').split('-').join('.')} `;
  };

  getCompanyName(companyID) {
    if (companyID === this.supplier.id) {
      return this.supplier.name;
    }
    if (companyID === this.customer.id) {
      return this.customer.name;
    }
    return ""
  }

  printNotes() {
    es6printJS({
      printable: 'notes-container',
      type: 'html',
    });
  }
}
