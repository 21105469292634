import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'reportDate',
})
export class ReportDatePipe implements PipeTransform {
    transform(date: string): any {
        const locale = navigator.language;
        moment.locale(locale);

        const mo = moment.utc(date).toObject();
        const d = new Date();
        d.setFullYear(mo.years);
        d.setMonth(mo.months);
        d.setDate(mo.date);
        d.setHours(0);
        d.setMinutes(0);
        d.setSeconds(0);

        let formatL = moment(d).localeData().longDateFormat('L');
        formatL = formatL.replace(/Y/g, '').replace(/^\W|\W$|\W\W/, '');

        return moment(d).format(formatL);
    }
}
