import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { User } from '@app/models';
import {
  NbMediaBreakpoint,
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from '@nebular/theme';
import { Store } from '@ngrx/store';
import { LayoutService } from '@services/layout.service';
import { SidebarState } from 'app/shared/enums';
import { Observable, Subscription } from 'rxjs';
import { delay, withLatestFrom } from 'rxjs/operators';
import { environment } from '@env/environment';
import { LocalStorage } from 'ngx-webstorage';

@Component({
  selector: 'ngx-default-layout',
  styleUrls: ['./default.layout.scss'],
  templateUrl: './default.layout.html',
})
export class DefaultLayoutComponent implements OnInit, OnDestroy, AfterViewInit {
  @LocalStorage() companyLogo: string;
  @LocalStorage() siteName: string;
  isExpanded: boolean = true;
  isCollapsed: boolean;
  user$: Observable<User>;
  isRCDSS = environment.name === 'rcdss';
  protected menuClick$: Subscription;
  protected resize$: Subscription;

  constructor(
    protected $layout: LayoutService,
    protected $menu: NbMenuService,
    protected $theme: NbThemeService,
    protected $breakpoint: NbMediaBreakpointsService,
    protected $sidebar: NbSidebarService,
    private store: Store<{ user: User }>,
  ) {}

  ngOnInit() {
    this.user$ = this.store.select('user');

    const isBp = this.$breakpoint.getByName('lg');
    this.menuClick$ = this.$menu
      .onItemSelect().pipe(
        withLatestFrom(this.$theme.onMediaQueryChange()),
        delay(20))
      .subscribe(([item, [bpFrom, bpTo]]: [any, [NbMediaBreakpoint, NbMediaBreakpoint]]) => {
        if (bpTo.width <= isBp.width) {
          this.isExpanded = false;
          this.$layout.SideBarState = SidebarState.Collapsed;
          this.$sidebar.collapse('menu-sidebar');
        }
      });

    this.resize$ = this.$theme
      .onMediaQueryChange()
      .pipe(
        delay(20))
      .subscribe(ts => {
        this.isCollapsed = ['xs', 'is', 'sm', 'md', 'lg'].includes(ts[1].name);

        if (this.isCollapsed) {
          this.$sidebar.collapse('menu-sidebar');
          this.$layout.SideBarState = SidebarState.Collapsed;
        } else {
          this.$layout.SideBarState = SidebarState.Expanded;
        }

        setTimeout(() => {
          this.customizeDesign();
        }, 100);
      });
  }

  ngAfterViewInit() {
    window.addEventListener('resize', () => {
      this.customizeDesign();
    });

    this.customizeDesign();
  }

  toggleCompact() {
    this.isExpanded = !this.isExpanded;
    this.$sidebar.toggle(!this.isCollapsed, 'menu-sidebar');
    this.$layout.SideBarState = this.isExpanded ? SidebarState.Expanded : SidebarState.Collapsed;

    setTimeout(() => {
      this.customizeDesign();
    }, 100);
  }

  toggleSidebar() {
    this.isExpanded = true;
    this.$sidebar.toggle(false, 'menu-sidebar');
    this.$layout.SideBarState = SidebarState.Expanded;
  }

  customizeDesign() {
  }

  ngOnDestroy() {
    this.menuClick$.unsubscribe();
    this.resize$.unsubscribe();
  }
}
