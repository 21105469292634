import { FileItem } from '@models/file-item.model';
import { PurchaseOrderDto } from './purchase-order.model.dto';
import { User } from './user.model';

export class Company {
  id: string;
  name: string;
  plan: string;
  postal_code: number;
  region: string;
  customer_id: string;
  address1: string;
  address2: string;
  city: string;
  country: string;
  government_id: string;
  government_id_type: number;
  can_edit: boolean;
  can_receive_pos: boolean;
  login_as: string;
  contact: {
    email: string;
    first_name: string;
    last_name: string;
    phone_number: string;
  };
  created_at: any;
  created_by: string;
  priorities: any;
  priority: string;
  priority_id: string;
  purchaseOrders: PurchaseOrderDto[];
  manager: User;
  fob_point: string;
  shipped_via: string;
  port_of_origin: string;
  documents: SupplierLeadDocument[];
  insurance_files: SupplierLeadDocument[];

  logo?: string;
  site_name?: string;
}

export class SupplierLeadDocument {
  customer_lead: { email: string, first_name: string, last_name: string, id: string, phone_number: string };
  expires_at: string | Date;
  file: FileItem;
  std_lead_time_days: number;
  std_lead_time_months: number;
  supplier_lead: { email: string, first_name: string, last_name: string, id: string, phone_number: string };
}
