/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroOrganizationCollection } from '../models/lasgro-organization-collection';
import { LasgroOrganization } from '../models/lasgro-organization';
import { AddOrganizationPayload } from '../models/add-organization-payload';
import { OrganizationInputPayload } from '../models/organization-input-payload';
import { LasgroOrganization_metaCollection } from '../models/lasgro-organization-_meta-collection';
import { LasgroContract_time_periodCollection } from '../models/lasgro-contract-_time-_period-collection';
import { LasgroShould_request_info } from '../models/lasgro-should-_request-_info';
import { LasgroOrganization_terminologyCollection } from '../models/lasgro-organization-_terminology-collection';
@Injectable({
  providedIn: 'root',
})
class OrganizationService extends __BaseService {
  static readonly organizationListPath = '/organizations';
  static readonly organizationAddPath = '/organizations';
  static readonly organizationFullListPath = '/organizations/organizations/full_list';
  static readonly organizationShowPath = '/organizations/{organizationID}';
  static readonly organizationUpdatePath = '/organizations/{organizationID}';
  static readonly organizationDeletePath = '/organizations/{organizationID}';
  static readonly organizationSendSupplierInvitationsPath = '/organizations/{organizationID}';
  static readonly organizationListMetaPath = '/organizations/{organizationID}/meta';
  static readonly organizationPeriodListPath = '/organizations/{organizationID}/periods';
  static readonly organizationShouldRequestInfoPath = '/organizations/{organizationID}/should_request_info';
  static readonly organizationListTerminologyPath = '/organizations/{organizationID}/terminology';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * list organization
   *
   * Lists organizations
   *
   * Required security scopes:
   *   * `api:access`
   * @return OK
   */
  organizationListResponse(): __Observable<__StrictHttpResponse<LasgroOrganizationCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOrganizationCollection>;
      })
    );
  }
  /**
   * list organization
   *
   * Lists organizations
   *
   * Required security scopes:
   *   * `api:access`
   * @return OK
   */
  organizationList(): __Observable<LasgroOrganizationCollection> {
    return this.organizationListResponse().pipe(
      __map(_r => _r.body as LasgroOrganizationCollection)
    );
  }

  /**
   * add organization
   *
   * adds a new organization
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   * @return Created
   */
  organizationAddResponse(payload: AddOrganizationPayload): __Observable<__StrictHttpResponse<LasgroOrganization>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOrganization>;
      })
    );
  }
  /**
   * add organization
   *
   * adds a new organization
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   * @return Created
   */
  organizationAdd(payload: AddOrganizationPayload): __Observable<LasgroOrganization> {
    return this.organizationAddResponse(payload).pipe(
      __map(_r => _r.body as LasgroOrganization)
    );
  }

  /**
   * FullList organization
   *
   * Full lists organizations
   *
   * Required security scopes:
   *   * `api:access`
   * @return OK
   */
  organizationFullListResponse(): __Observable<__StrictHttpResponse<LasgroOrganizationCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/organizations/full_list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOrganizationCollection>;
      })
    );
  }
  /**
   * FullList organization
   *
   * Full lists organizations
   *
   * Required security scopes:
   *   * `api:access`
   * @return OK
   */
  organizationFullList(): __Observable<LasgroOrganizationCollection> {
    return this.organizationFullListResponse().pipe(
      __map(_r => _r.body as LasgroOrganizationCollection)
    );
  }

  /**
   * show organization
   *
   * Retrieve organization with given id. IDs start at 1
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID Organization ID
   * @return OK
   */
  organizationShowResponse(organizationID: string): __Observable<__StrictHttpResponse<LasgroOrganization>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(organizationID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOrganization>;
      })
    );
  }
  /**
   * show organization
   *
   * Retrieve organization with given id. IDs start at 1
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID Organization ID
   * @return OK
   */
  organizationShow(organizationID: string): __Observable<LasgroOrganization> {
    return this.organizationShowResponse(organizationID).pipe(
      __map(_r => _r.body as LasgroOrganization)
    );
  }

  /**
   * update organization
   *
   * Update a organization
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `OrganizationService.OrganizationUpdateParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `organizationID`: Oompany ID
   *
   * @return OK
   */
  organizationUpdateResponse(params: OrganizationService.OrganizationUpdateParams): __Observable<__StrictHttpResponse<LasgroOrganization>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOrganization>;
      })
    );
  }
  /**
   * update organization
   *
   * Update a organization
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `OrganizationService.OrganizationUpdateParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `organizationID`: Oompany ID
   *
   * @return OK
   */
  organizationUpdate(params: OrganizationService.OrganizationUpdateParams): __Observable<LasgroOrganization> {
    return this.organizationUpdateResponse(params).pipe(
      __map(_r => _r.body as LasgroOrganization)
    );
  }

  /**
   * delete organization
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID Organization ID
   */
  organizationDeleteResponse(organizationID: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/organizations/${encodeURIComponent(String(organizationID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete organization
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID Organization ID
   */
  organizationDelete(organizationID: string): __Observable<null> {
    return this.organizationDeleteResponse(organizationID).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * send_supplier_invitations organization
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID Organization ID
   */
  organizationSendSupplierInvitationsResponse(organizationID: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/organizations/${encodeURIComponent(String(organizationID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * send_supplier_invitations organization
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID Organization ID
   */
  organizationSendSupplierInvitations(organizationID: string): __Observable<null> {
    return this.organizationSendSupplierInvitationsResponse(organizationID).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * list_meta organization
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  organizationListMetaResponse(organizationID: string): __Observable<__StrictHttpResponse<LasgroOrganization_metaCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(organizationID))}/meta`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOrganization_metaCollection>;
      })
    );
  }
  /**
   * list_meta organization
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  organizationListMeta(organizationID: string): __Observable<LasgroOrganization_metaCollection> {
    return this.organizationListMetaResponse(organizationID).pipe(
      __map(_r => _r.body as LasgroOrganization_metaCollection)
    );
  }

  /**
   * period_list organization
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `OrganizationService.OrganizationPeriodListParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `is_active`: If provided and true, would return only list of active periods
   *
   * @return OK
   */
  organizationPeriodListResponse(params: OrganizationService.OrganizationPeriodListParams): __Observable<__StrictHttpResponse<LasgroContract_time_periodCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.isActive != null) __params = __params.set('is_active', params.isActive.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/periods`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroContract_time_periodCollection>;
      })
    );
  }
  /**
   * period_list organization
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `OrganizationService.OrganizationPeriodListParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `is_active`: If provided and true, would return only list of active periods
   *
   * @return OK
   */
  organizationPeriodList(params: OrganizationService.OrganizationPeriodListParams): __Observable<LasgroContract_time_periodCollection> {
    return this.organizationPeriodListResponse(params).pipe(
      __map(_r => _r.body as LasgroContract_time_periodCollection)
    );
  }

  /**
   * should_request_info organization
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID Organization ID
   * @return OK
   */
  organizationShouldRequestInfoResponse(organizationID: string): __Observable<__StrictHttpResponse<LasgroShould_request_info>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(organizationID))}/should_request_info`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroShould_request_info>;
      })
    );
  }
  /**
   * should_request_info organization
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID Organization ID
   * @return OK
   */
  organizationShouldRequestInfo(organizationID: string): __Observable<LasgroShould_request_info> {
    return this.organizationShouldRequestInfoResponse(organizationID).pipe(
      __map(_r => _r.body as LasgroShould_request_info)
    );
  }

  /**
   * list_terminology organization
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  organizationListTerminologyResponse(organizationID: string): __Observable<__StrictHttpResponse<LasgroOrganization_terminologyCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(organizationID))}/terminology`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroOrganization_terminologyCollection>;
      })
    );
  }
  /**
   * list_terminology organization
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  organizationListTerminology(organizationID: string): __Observable<LasgroOrganization_terminologyCollection> {
    return this.organizationListTerminologyResponse(organizationID).pipe(
      __map(_r => _r.body as LasgroOrganization_terminologyCollection)
    );
  }
}

module OrganizationService {

  /**
   * Parameters for organizationUpdate
   */
  export interface OrganizationUpdateParams {
    payload: OrganizationInputPayload;

    /**
     * Oompany ID
     */
    organizationID: string;
  }

  /**
   * Parameters for organizationPeriodList
   */
  export interface OrganizationPeriodListParams {
    organizationID: string;

    /**
     * If provided and true, would return only list of active periods
     */
    isActive?: boolean;
  }
}

export { OrganizationService }
