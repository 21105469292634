import { Company } from '@models/company.model';

export class User {

  company: {
    href: string;
    id: string;
    name: string;
  };
  email: string;
  first_name: string;
  last_name: string;
  full_name?: string;
  href?: string;
  id: string;
  phone_number: string;
  role: string;
  text_message_notify: boolean;
  country_code: string;
  organization_id?: string;
  // Whether the user gets email notifications
  email_notify: boolean;
  username: string;
  password?: string;
  invite_to_login: boolean;
  // Employees fields
  barcode: string;
  pay_period_duration: string;
  pay_interval: string;
  total_compensation: string;
  total_raw_hours: string;
  average_hourly_wage: string;
  supervisior_percentage: string;
  hourly_pay_rate: string;
  currency: string;
  show_company_info: boolean;
  can_edit?: boolean;
  first_po_id?: string;

  constructor(data?) {
    Object.assign(this, data);
  }

  get fullName(): string {
    return this.full_name || `${this.first_name} ${this.last_name}`.trim();
  }
}

export class UserDTO {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  username: string;
  href: string;
  role: string;
  show_company_info: boolean;
  organization_id?: string;
  company?: Company;

  constructor(data) {
    Object.assign(this, data);
  }

  get fullName() {
    return `${this.first_name} ${this.last_name}`;
  }

  get searchDisplayFullName() {
    return `${this.fullName} (${this.username})`;
  }
}
