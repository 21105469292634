import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FilterMenuBtnComponent } from '@app/shared/components/filter-menu-btn/filter-menu-btn.component';
import { startWith, map } from 'rxjs/operators';
import { Product } from '@models/product.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'ngx-product-filter-bar',
  templateUrl: './product-filter-bar.component.html',
  styleUrls: ['./product-filter-bar.component.scss'],
})
export class ProductFilterBarComponent implements OnInit {
  private _customerParts: string[] = [];
  private _names: string[] = [];
  private _descriptions: string[] = [];
  private _barcodes: string[] = [];
  private _products: Product[] = [];
  filters = {
    customerPart: '',
    name: '',
    description: '',
    barcode: '',
  };

  customerPartControl = new FormControl();
  nameControl = new FormControl();
  descControl = new FormControl();
  barcodeControl = new FormControl();

  filteredCustomerParts: Observable<string[]>;
  filteredNames: Observable<string[]>;
  filteredDesc: Observable<string[]>;
  filteredBarcode: Observable<string[]>;

  @ViewChild('customerPartMenu', { static: false })
  private customerPartMenu: FilterMenuBtnComponent;

  @ViewChild('nameMenu', { static: false })
  private nameMenu: FilterMenuBtnComponent;

  @ViewChild('descMenu', { static: false })
  private descMenu: FilterMenuBtnComponent;

  @ViewChild('barcodeMenu', { static: false })
  private barcodeMenu: FilterMenuBtnComponent;

  @Input()
  public showCustomerPartBtn = false;

  @Input()
  public set products(products: Product[]) {
    this._products = products || [];
    this._customerParts = [];
    this._names = [];
    this._descriptions = [];
    this._barcodes = [];

    this._products.forEach(p => {
      if (p.customer_part_id)
        this._customerParts.push(p.customer_part_id);
      if (p.name)
        this._names.push(p.name);
      if (p.description)
        this._descriptions.push(p.description);
      if (p.barcode)
        this._barcodes.push(p.barcode);
    });

    // Trigger a value change to load the autocomplete list.
    this._clearFilterInputs();
  }

  @Output() onFilterChange = new EventEmitter<any>();

  private _clearFilterInputs() {
    this.customerPartControl.setValue('');
    this.nameControl.setValue('');
    this.descControl.setValue('');
    this.barcodeControl.setValue('');
  }

  constructor() { }

  ngOnInit() {
    this.filteredCustomerParts = this.customerPartControl.valueChanges.pipe(
      startWith(''),
      map(filter => this.filterStrArray(filter, this._customerParts)),
    );
    this.filteredNames = this.nameControl.valueChanges.pipe(
      startWith(''),
      map(filter => this.filterStrArray(filter, this._names)),
    );
    this.filteredDesc = this.descControl.valueChanges.pipe(
      startWith(''),
      map(filter => this.filterStrArray(filter, this._descriptions)),
    );
    this.filteredBarcode = this.barcodeControl.valueChanges.pipe(
      startWith(''),
      map(filter => this.filterStrArray(filter, this._barcodes)),
    );
  }

  filterStrArray(filter: string, arr: string[]) {
    filter = filter.toLowerCase();

    return arr.filter(value => {
      return value.toLowerCase().indexOf(filter) === 0;
    });
  }

  onSaveClick() {
    this.onFilterChange.emit(this.filters);
  }

  onSaveCustomerPartClick() {
    this.filters.customerPart = this.customerPartControl.value.trim().toLowerCase();
    // this.customerPartControl.setValue('');
    this.customerPartMenu.hide();
    this.onSaveClick();
  }

  onSaveNameClick() {
    this.filters.name = this.nameControl.value.trim().toLowerCase();
    // this.nameControl.setValue('');
    this.nameMenu.hide();
    this.onSaveClick();
  }

  onSaveDescClick() {
    this.filters.description = this.descControl.value.trim().toLowerCase();
    // this.descControl.setValue('');
    this.descMenu.hide();
    this.onSaveClick();
  }

  onSaveBarcodeClick() {
    this.filters.barcode = this.barcodeControl.value.trim().toLowerCase();
    // this.barcodeControl.setValue('');
    this.barcodeMenu.hide();
    this.onSaveClick();
  }

  onClearClick() {
    this.onFilterChange.emit(this.filters);
  }

  onClearCustomerPartClick() {
    this.filters.customerPart = '';
    this.customerPartControl.setValue('');
    this.customerPartMenu.hide();
    this.onClearClick();
  }

  onClearNameClick() {
    this.filters.name = '';
    this.nameControl.setValue('');
    this.nameMenu.hide();
    this.onClearClick();
  }

  onClearDescClick() {
    this.filters.description = '';
    this.descControl.setValue('');
    this.descMenu.hide();
    this.onClearClick();
  }

  onClearBarcodeClick() {
    this.filters.barcode = '';
    this.barcodeControl.setValue('');
    this.barcodeMenu.hide();
    this.onClearClick();
  }
}
