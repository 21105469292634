import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Company, SupplierLeadDocument } from '@models/company.model';
import { User } from '@models/user.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyService } from '@services/company.service';
import { Toaster } from '@services/toaster.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { LocalStorage } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'ngx-supplier-terms-form',
  templateUrl: './supplier-terms-form.component.html',
  styleUrls: ['./supplier-terms-form.component.scss'],
})
export class SupplierTermsFormComponent implements OnInit {

  @LocalStorage() organizationId: string;
  @Input() supplier: Company;
  @Input() document: SupplierLeadDocument;
  fileInput;

  supplierLeadForm;
  customerUsers$: Observable<User[]>;
  supplierUsers$: Observable<User[]>;

  constructor(
    private companyService: CompanyService,
    private modalRef: NgbActiveModal,
    private $toaster: Toaster,
    private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.supplierLeadForm = this.formBuilder.group({
      customerLeadId: [],
      supplierLeadId: [],
      expiresAt: [null],
      stdLeadTimeValue: [],
      stdLeadTimeLength: ['days'],
    });

    if (this.document) {
      const extraParams: any = {
        customerLeadId: this.document.customer_lead && this.document.customer_lead.id,
        supplierLeadId: this.document.supplier_lead && this.document.supplier_lead.id,
        expiresAt: this.document.expires_at,
      };

      if (this.document.std_lead_time_days) {
        extraParams.stdLeadTimeValue = this.document.std_lead_time_days;
        extraParams.stdLeadTimeLength = 'days';
      }
      if (this.document.std_lead_time_months) {
        extraParams.stdLeadTimeValue = this.document.std_lead_time_days;
        extraParams.stdLeadTimeLength = 'months';
      }
      this.supplierLeadForm.patchValue(extraParams);
    }

    this.customerUsers$ = this.companyService.users(this.organizationId);
    this.supplierUsers$ = this.companyService.users(this.supplier.id);
  }

  closeModal(result?) {
    this.modalRef.close(result);
  }

  save() {
    if (this.supplierLeadForm.invalid || (!this.fileInput && !this.document)) return;
    const formValues = this.supplierLeadForm.getRawValue();

    let extraParams: any = {
      customerLeadID: formValues.customerLeadId,
      supplierLeadID: formValues.supplierLeadId,
      expiresAt: formValues.expiresAt && moment.utc(formValues.expiresAt).format(),
      // stdLeadTimeDays: '',
      // stdLeadTimeMonths: '',
    };

    if (formValues.stdLeadTimeValue) {
      if (formValues.stdLeadTimeLength === 'days') {
        extraParams.stdLeadTimeDays = formValues.stdLeadTimeValue;
      }
      if (formValues.stdLeadTimeLength === 'months') {
        extraParams.stdLeadTimeMonths = formValues.stdLeadTimeValue;
      }
    }

    extraParams = _.pickBy(extraParams, _.identity);
    const request = this.document && this.document.file ?
      this.companyService.updateSupplierFile(this.supplier.id, this.document.file.file_id, null, extraParams)
        .pipe(tap(() => {
          this.$toaster.show('success', 'Supplier File', 'Supplier File updated');
        })) :
      this.companyService.addSupplierFile(this.supplier.id, this.fileInput, extraParams).pipe(tap(() => {
        this.$toaster.show('success', 'Supplier File', 'Supplier File added');
      }));
    request.subscribe((result) => {
      this.closeModal(result);
    }, (error) => {

      const errMsg = error.error && error.error.detail ? error.error.detail : 'An error occurred while fetching the tracking codes.';
      this.$toaster.show('error', 'Tracking Codes', errMsg);
    });
  }
}
