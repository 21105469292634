<ngx-loading [show]="loading"></ngx-loading>

<ngx-title-divider title="Messages" [bold]="true" *ngIf="showTitle">
  <span class="badge badge-dark">{{unreadMessages}}</span>
</ngx-title-divider>

<div class="row">
  <div class="col d-flex align-items-center justify-content-end">
    <button mat-icon-button (click)="printNotes()" *ngIf="notes?.length">
      <mat-icon>print</mat-icon>
    </button>
  </div>
</div>

<div id="notes-container" class="notes-container pr-2 d-flex flex-column-reverse" [ngClass]="{ 'scrolling-enabled': isScrollingEnabled }">
  <div *ngFor="let n of notes" class="purchase-order-notes d-flex m-1 mt-3"
    [ngClass]="{
          'flex-row-reverse': n.user_id === currentUser?.id,
          'flex-row': n.user_id !== currentUser?.id
        }">
    <div [id]="n.id" [attr.id]="'note-'+n.id" class="purchase-order-note m-0">
      <span class="note-author d-block">
        <span *ngIf="n.visibility === 'PUBLIC' && visibilityEnabled" class="pr-1" style="color: #0095ff; font-size: 13px;" [matTooltip]="'Public'">
          <i class="fa fa-globe"></i>
          <!-- Public -->
        </span>
        <span *ngIf="n.visibility === 'PRIVATE' && visibilityEnabled" class="text-secondary pr-1" style="font-size: 13px;" [matTooltip]="'Private'">
          <i class="fa fa-lock"></i>
          <!-- Private -->
        </span>
        <!-- style="color: #40ae45;" -->
        <span class="text-secondary" style="font-size: 13px; font-weight: 500;">
          {{ n.user_firstname }} {{ n.user_lastname }}
        </span>
        <span class="text-secondary" style="font-size: 13px; font-weight: 100;">
          {{ n.created_at | timeAgo }}
        </span>

        <button *ngIf="n.user_id === currentUser?.id" class="btn btn-link text-danger pull-right" type="button" (click)="deleteNote(n)" [matTooltip]="'Delete'">
          <small>
            <i class="fa fa-times fa-2x pl-4"></i>
            <!-- Delete -->
          </small>
        </button>
      </span>
      <pre style="word-wrap: break-word; overflow-wrap: break-word; white-space: pre-wrap;" class="content m-0 px-3 py-2" [innerHTML]="n.content | linky"
        [ngClass]="{
          'own bg-primary text-white': n.user_id === currentUser?.id,
          'other': n.user_id !== currentUser?.id
        }"
      ></pre>
      <!-- {{ n.content }} -->
    </div>
  </div>
</div>

<div *ngIf="poID" class="pt-4 mt-2 border-top">
  <form class="" *ngIf="allowEdit">
    <div class="row">
      <div class="col-md-10 d-flex flex-column">
        <mat-form-field>
          <textarea
            #noteTextarea="ngModel"
            rows="4"
            matInput
            placeholder="Put text here"
            type="text"
            (ngModelChange)="handleNoteChange($event)"
            [(ngModel)]="note"
            [ngModelOptions]="{ standalone: true }"
            [mentionConfig]="mentionsConfig"
            [mentionListTemplate]="userMentionTemplate"
          ></textarea>
          <ng-template #userMentionTemplate let-user="item">
            <div>{{user.fullName}} - {{user.company.name}}</div>
            <small class="d-flex align-items-center">
              <mat-icon
                style="transform: scale(0.7)"
                class="mr-1"
                aria-hidden="false"
                [attr.aria-label]="user.fullName + '\'s email'">
                mail_outline
              </mat-icon>
              <span>{{user.email}}</span>
            </small>
          </ng-template>
        </mat-form-field>
      </div>
      <div class="col-md-2">
        <!-- <div class="dropdown d-block">
          <button class="btn dropdown-toggle" type="button" id="visibilityBtn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="font-size: 12px !important; width: 100%; padding: 5px 0px !important;"
          [ngClass]="{
            'btn-secondary': visibility === 'PRIVATE',
            'btn-info': visibility === 'PUBLIC'
          }">
            <i *ngIf="visibility === 'PRIVATE'" class="fa fa-lock"></i>
            <i *ngIf="visibility === 'PUBLIC'" class="fa fa-globe"></i>
            {{ visibility | titlecase }}
          </button>
          <div class="dropdown-menu" aria-labelledby="visibilityBtn">
            <a *ngIf="visibility === 'PRIVATE'" class="dropdown-item" (click)="setVisibility('PUBLIC')">
              <i class="fa fa-globe"></i>
              Public
            </a>
            <a *ngIf="visibility === 'PUBLIC'" class="dropdown-item" (click)="setVisibility('PRIVATE')">
              <i class="fa fa-lock"></i>
              Private
            </a>
          </div>
        </div> -->
        <!-- <mat-form-field class="w-auto">
          <mat-select [(ngModel)]="visibility" [ngModelOptions]="{standalone: true}" panelClass="menu-select-panel">
            <mat-option value="PRIVATE"><i class="fa fa-lock"></i> Private</mat-option>
            <mat-option value="PUBLIC"><i class="fa fa-globe"></i> Public</mat-option>
          </mat-select>
        </mat-form-field> -->
        <div class="text-center" style="font-size: 12px !important; width: 100%; padding: 5px 0px !important;" *ngIf="visibilityEnabled"
        [ngClass]="{
          'text-secondary': visibility === 'PRIVATE',
          'text-info': visibility === 'PUBLIC'
        }">
          <i *ngIf="visibility === 'PRIVATE'" class="fa fa-lock"></i>
          <i *ngIf="visibility === 'PUBLIC'" class="fa fa-globe"></i>
          {{ visibility | titlecase }}
        </div>
        <button
          type="button"
          [disabled]="!note || !noteHasMentions"
          [matTooltip]="!note ? 'Message field is empty' :
          (!noteHasMentions ? 'Messages require to be directed to at least one user. Type the @ symbol to message them. Example: @UserName' : '')"
          class="btn bg-primary text-white d-block mt-2"
          (click)="addNote()"
        >
          <i class="fa fa-send"></i>
          Send
        </button>
      </div>
      <div class="col-md-12 pt-0">
        <span>
          Type the @ symbol to select the people you want to message (even yourself). Messaged users will receive a notification.
        </span>
      </div>
    </div>
  </form>
</div>