<ngx-loading [show]="loading || fileLoading"></ngx-loading>

<div class="container-fluid p-4">
  <div class="row">
    <div class="col">
      <h2>Notifications</h2>
      <h3>
        <small>You have {{ notifications?.length - completedTasks }} open actions</small>
      </h3>
    </div>
  </div>
</div>

<div class="container-fluid" style="background-color: #ebeff5;" *ngIf="isRCDSS">
  <div class="row">
    <div class="col-md-12">
      <div class="row p-0 m-0 border bg-white rounded shadow">
        <div class="col-md-4 list-side bg-light p-4 border-right">
          <div class="row p-0 m-0">
            <div class="col-md-12 d-flex flex-row">
              <mat-checkbox [checked]="onlySubscriptions" (change)="changeFilter($event)">
                Subscriptions only
              </mat-checkbox>
              <!-- <mat-form-field style="max-width: 150px;" class="ml-2">
                  <mat-select [(value)]="viewMode">
                    <mat-option value="">Agenda</mat-option>
                    <mat-option value="">List</mat-option>
                    <mat-option value="">Calendar</mat-option>
                  </mat-select>
                </mat-form-field> -->
              <mat-icon [matMenuTriggerFor]="menu" class="ml-auto cursor-pointer">more_vert</mat-icon>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="deleteSelectedAlerts()">
                  <span>Delete Selected</span>
                </button>
                <button mat-menu-item (click)="deleteAllAlerts()">
                  <span>Delete All</span>
                </button>
              </mat-menu>
            </div>
          </div>
            <!-- [grouped]="true" -->
          <dx-list
            class="list"
            [dataSource]="notifications"
            [searchEnabled]="true"
            selectionMode="single"
            [selectedItemKeys]="[selectedNotification?.id]"
            (onSelectionChanged)="listSelectionChanged($event)"
          >
            <div *dxTemplate="let item of 'item'">
              <div class="d-flex">
                <div
                  class="text-wrap"
                  [ngClass]="{
                    'text-secondary': !selectedNotification?.id || selectedNotification?.id !== item?.id,
                    'text-dark': selectedNotification?.id === item?.id
                  }"
                >
                  <div class="font-weight-bold line-container">
                    {{ item?.parent_resource_type ? item.parent_resource_type : 'Contract' }}
                    # {{ item?.parent_resource_number }}
                  </div>
                  <div class="line-container">
                    {{ item?.counterpartName }}
                  </div>
                  <div>
                    <b class="badge badge-default mt-2 border p-2">
                      {{ item?.notification_type?.split('_').join(' ') | titlecase }}
                    </b>
                    <br>
                    <small class="badge font-weight-normal p-2">
                      {{ item?.created_at | amTimeAgo }}
                    </small>
                  </div>
                </div>
                <div class="ml-auto">
                  <mat-checkbox (click)="$event.stopPropagation(); onCheckboxChange(item.id)"></mat-checkbox>
                </div>
              </div>
            </div>
            <!-- <div *dxTemplate="let item of 'group'">
              <div class="readable_type">
                {{ item.key | titlecase }}
              </div>
            </div> -->
          </dx-list>
        </div>

        <div
          class="col-md-8 card-side p-4 d-flex flex-column justify-content-between"
          *ngIf="!!selectedNotification?.id && !loading"
        >
          <!-- <div class="header">
              
            </div> -->
          <div class="body">
            <div class="top-bar">
              <div class="text-bar pull-left pt-3 h6 font-weight-normal">
                <b>Action:</b> {{selectedNotification?.notification_type?.split('_').join(' ') | titlecase}}
              </div>
              <div class="action-bar text-right" style="width: fit-content; float: right;">
                <button
                  class="mb-2 mr-2 btn btn-success"
                  *ngIf="selectedNotification?.purchase_order && shouldShowApprovalBtns(selectedNotification)"
                  (click)="acceptOrReject(selectedNotification, true)"
                  title="Approve"
                  style="width: auto;"
                >
                  <i class="fa fa-check"></i>
                </button>
                <button
                  class="mb-2 mr-2 btn btn-danger"
                  *ngIf="selectedNotification?.purchase_order && shouldShowApprovalBtns(selectedNotification)"
                  (click)="acceptOrReject(selectedNotification, false)"
                  title="Reject"
                  style="width: auto;"
                >
                    <i class="fa fa-times"></i>
                </button>
                <button
                  class="mb-2 mr-2 btn btn-secondary"
                  *ngIf="selectedNotification?.resource_type === RESOURCE_TYPE.CHECKLIST_TASK"
                  (click)="addTaskFile(selectedNotification)"
                  title="Add file"
                  style="width: auto;"
                >
                  <i class="fa fa-upload"></i> {{ selectedNotification?.task?.checklist_task_files?.length || 0 }}
                </button>
                <button
                  class="mb-2 mr-2 btn btn-dark"
                  (click)="openTask(selectedNotification)"
                  *ngIf="selectedNotification?.resource_type === RESOURCE_TYPE.CHECKLIST_TASK"
                  title="Open task"
                  style="width: auto;"
                >
                  <i class="fa fa-external-link"></i>
                </button>
                <button class="mb-2 mr-2 btn btn-dark" (click)="openPurchaseOrder(selectedNotification)"
                  title="Open {{ isRCDSS ? 'Contract' : 'Purchase Order' }}"
                  style="width: auto;">
                  <i class="fa fa-external-link"></i>
                </button>
                <button
                  class="mb-2 mr-2 mr-4 btn btn-secondary"
                  (click)="clearNotification(selectedNotification)"
                  *ngIf="selectedNotification?.notification_type !== NOTIFICATION_TYPE.ONBOARDING_REJECTED"
                  title="Clear"
                  style="width: auto;"
                >
                  <i class="fa fa-eraser"></i>
                </button>
                <button
                  (click)="previousNotification()"
                  class="mb-2 btn btn-light border"
                  title="Previous"
                  style="width: auto;"
                >
                  <i class="fa fa-caret-left"></i>
                </button>
                <button
                  (click)="nextNotification()"
                  class="mb-2 btn btn-light border"
                  title="Next"
                  style="width: auto;"
                >
                  <i class="fa fa-caret-right"></i>
                </button>
              </div>
            </div>
            <div class="clearfix"></div>
            <div class="content">
              <large style="line-height: 25px;" class="my-4 d-block">
                {{selectedNotification?.message}}
              </large>
              <table class="table table-borderless table-striped table-hover">
                <tbody>
                  <tr>
                    <th scope="row">
                      Contract
                    </th>
                    <td>
                      {{ selectedNotification.purchase_order?.customer_po_number }}
                    </td>
                    <!-- <th scope="row">
                      Year
                    </th>
                    <td *ngIf="!!selectedNotification.purchase_order?.period">
                      {{ selectedNotification.purchase_order?.period }}
                    </td> -->
                    <th *ngIf="isRCDSS" scope="row">Stage</th>
                    <td *ngIf="isRCDSS">
                      <!-- class="{{getRcdssContractStageClass(selectedNotification?.purchase_order)}}" -->
                      {{ getRcdssContractStage(selectedNotification?.purchase_order) }}
                    </td>
                    <th *ngIf="isRCDSS" scope="row">Status</th>
                    <td *ngIf="isRCDSS">
                      {{ getRcdssStatus(selectedNotification?.purchase_order) }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      {{ selectedNotification.counterpartRole }}
                    </th>
                    <td colspan="5">
                      {{ selectedNotification.counterpartName }}
                    </td>
                  </tr>
                  <tr *ngIf="selectedNotification?.task?.name">
                    <th scope="row">Task</th>
                    <td colspan="5">{{ selectedNotification?.task?.name }}</td>
                  </tr>
                  <tr *ngIf="selectedNotification?.purchase_order?.rcdss_contract_program">
                    <th scope="row">Program</th>
                    <td colspan="5">{{ selectedNotification?.purchase_order?.rcdss_contract_program }}</td>
                  </tr>

                  <!-- INTEGRATE WITH TIMELINE -->
                  <!-- <tr>
                      <th scope="row">Active task</th>
                      <td></td>
                    </tr> -->
                  <!-- <tr>
                      <th scope="row"></th>
                      <td></td>
                    </tr> -->
                </tbody>
              </table>
            </div>

            <div class="file-container p-4 bg-light border content d-flex flex-row">
              <a
                class="file-icon-container border"
                ngxFilesPreview
                (click)="fileLoading = true"
                (finishedLoading)="fileLoading = false"
                [fileId]="selectedNotification?.file?.file_id"
                [fileName]="selectedNotification?.file?.file_name"
              >
                <i class="fa fa-file text-secondary p-4" style="font-size: 64px;"></i>
              </a>
              <div class="file-info-container pl-4 d-flex flex-column">
                <a
                  class="link"
                  ngxFilesPreview
                  (click)="fileLoading = true"
                  (finishedLoading)="fileLoading = false"
                  [fileId]="selectedNotification?.file?.file_id"
                  [fileName]="selectedNotification?.file?.file_name"
                >
                  {{ selectedNotification?.file?.file_name }}
                </a>
                <small class="pt-2">
                  By {{ selectedNotification?.file?.created_by_name }} at
                  {{ selectedNotification?.file?.created_at | amTimeAgo }}
                </small>
              </div>
            </div>
          </div>
          <div class="footer">
            <span class="ref text-secondary"> REF {{ selectedNotification.notification_number }} </span>
            <span class="timestamp pull-right text-secondary">
              {{ selectedNotification.created_at | amTimeAgo }} ({{
                selectedNotification.created_at | date: 'M/dd/yyyy H:mm'
              }})
            </span>
          </div>
          <!-- <i class="fa fa-file-excel-o mr-2"></i> -->
          <!-- <i class="fa fa-file-pdf-o mr-2"></i> -->
          <!-- <i class="fa fa-file-word-o mr-2"></i> -->
          <!-- <i class="fa fa-file-image-o mr-2"></i> -->
          <!-- <i class="fa fa-file-video-o mr-2"></i> -->
          <!-- <i class="fa fa-file-audio-o mr-2"></i> -->
          <!-- <i class="fa {{getIconClass}} mr-2"></i> -->
        </div>
        <!-- <div class="col-md-2 actions p-4 bg-light" *ngIf="!!selectedNotification?.id && !loading">
          <h6>Recommended Actions</h6>
          <button
            class="mb-2 btn btn-success"
            *ngIf="selectedNotification?.purchase_order && shouldShowApprovalBtns(selectedNotification)"
            (click)="acceptOrReject(selectedNotification, true)"
          >
            Approve
          </button>
          <button
            class="mb-2 btn btn-danger"
            *ngIf="selectedNotification?.purchase_order && shouldShowApprovalBtns(selectedNotification)"
            (click)="acceptOrReject(selectedNotification, false)"
          >
            Reject
          </button>
          <span class="completed-task-label" *ngIf="!!selectedNotification?.task?.completed_at">
            <i class="fa fa-check"></i> You completed this task
          </span>
          <button
            class="mb-2 btn btn-success"
            [ngClass]="{ completed: !!selectedNotification?.task?.completed_at }"
            *ngIf="selectedNotification?.resource_type === RESOURCE_TYPE.CHECKLIST_TASK"
            (click)="completeTask(selectedNotification)"
          >
            {{ !!selectedNotification?.task?.completed_at ? 'Undo' : 'Mark As Completed' }}
          </button>

          <h6 class="border-bottom"></h6>

          <h6>Other Actions</h6>
          <button
            class="mb-2 btn btn-secondary"
            *ngIf="selectedNotification?.resource_type === RESOURCE_TYPE.CHECKLIST_TASK"
            (click)="addTaskFile(selectedNotification)"
          >
            See attachments ({{ selectedNotification?.task?.checklist_task_files?.length || 0 }})
          </button>
          <button
            class="mb-2 btn btn-dark"
            (click)="openTask(selectedNotification)"
            *ngIf="selectedNotification?.resource_type === RESOURCE_TYPE.CHECKLIST_TASK"
          >
            Open Task
          </button>
          <button class="mb-2 btn btn-dark" (click)="openPurchaseOrder(selectedNotification)">
            Open {{ isRCDSS ? 'Contract' : 'Purchase Order' }}
          </button>
          <button
            class="mb-2 btn btn-secondary"
            (click)="clearNotification(selectedNotification)"
            *ngIf="selectedNotification?.notification_type !== NOTIFICATION_TYPE.ONBOARDING_REJECTED"
          >
            Clear
          </button>
        </div> -->
        <div class="col" *ngIf="!selectedNotification?.id">
          <div class="d-flex align-items-center" style="height: 100%;">
            <h2 class="text-center text-secondary w-100">
              Pick an alert from the left to open it
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
