import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { NotificationService, CompanyService } from '@app/services';
import { LocalStorage } from 'ngx-webstorage';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { Toaster } from '@services/toaster.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalPONotesComponent } from '@app/shared/modals/modal-po-notes/modal-po-notes.component';
import { environment } from '@env/environment';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

@Component({
  selector: 'ngx-dashboard-mentions',
  templateUrl: './dashboard-mentions.component.html',
  styleUrls: ['./dashboard-mentions.component.scss'],
})
export class DashboardMentionsComponent implements OnInit {
  isRCDSS = environment.name === 'rcdss';
  @LocalStorage() organizationId;

  conversations;
  selectedConversation;
  dataSource: any;

  totalUnread = 0;
  loading = true;
  ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;

  constructor(
    private notificationService: NotificationService,
    private $company: CompanyService,
    private $toaster: Toaster,
    private $modal: NgbModal,
  ) {
  }

  ngOnInit(): void {
    this.getMentions();
  }

  listSelectionChanged = (e) => {
    let c = e.addedItems[0];
    this.selectConversation(c);
  }

  getMentions() {
    this.notificationService.getMentions()
      .subscribe(conversations => {
        this.conversations = conversations;
        this.totalUnread = 0;
        this.conversations.forEach(c => {
          this.fillConversation(c);
          this.totalUnread += c?.unread_messages || 0;
        });
        this.dataSource = new DataSource({
          store: new ArrayStore({
            data: this.conversations,
            key: 'purchase_order_id',
          }),
          group: 'parent_resource_type',
          searchExpr: ['customer_po_number', 'content'],
        });
        this.loading = false;
      });
  }

  openMention(mention) {
    const modalRef = this.$modal.open(ModalPONotesComponent, {
      size: 'lg',
      container: 'nb-layout',
      keyboard: false,
      windowClass: 'modal-po-notes',
    });
    modalRef.componentInstance.mention = mention;
    modalRef.componentInstance.allowEdit = true;
    modalRef.result.then(() => {
      this.getMentions();
    });
  }

  selectConversation(conversation) {
    this.loading = true;
    this.selectedConversation = conversation;
    /*this.$company.getResourceMessages(this.organizationId, conversation.purchase_order_id).subscribe(res => {
      this.selectedConversation.notes = res;
      this.loading = false;
    });*/
    this.$company.purchaseOrder(conversation.purchase_order_id).subscribe(res => {
      this.selectedConversation.notes = res.messages;
      this.loading = false;
    });
  }

  reloadSelectedConversation() {
    this.loading = true;
    /*this.$company.getResourceMessages(this.organizationId, this.selectedConversation.purchase_order_id).subscribe(res => {
      this.selectedConversation.notes = res;
      this.loading = false;
    });*/
    this.$company.purchaseOrder(this.selectedConversation.purchase_order_id).subscribe(res => {
      this.selectedConversation.notes = res.messages;
      this.loading = false;
    });
  }

  archiveMention(mention) {
    this.loading = true;
    this.notificationService.archiveMention(mention.purchase_order_id, mention.message_id).subscribe((res) => {
      this.$toaster.show('success', 'Success', 'Message archived');
      this.getMentions();
    }, (error) => {
      console.log(error);
      this.$toaster.show('errir', 'Error', 'An error occurred, please try again later.');
      this.loading = false;
    });
  }

  fillConversation(conversation: any) {
    conversation.parent_resource_type = 'Contract';
    if (this.organizationId === conversation.customer_id) {
      conversation.counterpartRole = 'Vendor';
      conversation.counterpartName = conversation.supplier_name;
    } else {
      conversation.counterpartRole = this.isRCDSS ? 'Department' : 'Customer';
      conversation.counterpartName = conversation.customer_name;
    }
  }

}
