import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import APP_CONFIG from '@app/app.config';
import { environment } from '@env/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageViewerComponent } from '@shared/modals/image-viewer/image-viewer.component';
import { PdfVisualizerComponent } from '@shared/modals/pdf-visualizer/pdf-visualizer.component';
import { FinancialReportDocumentService } from 'api/services';
import { StrictHttpResponse } from 'api/strict-http-response';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class FileService {
  constructor(private httpClient: HttpClient, private $modal: NgbModal, private domSanitizer: DomSanitizer) {}

  viewPdf(blobFile: Blob, fileName: string, isConfirmationModal = false): EventEmitter<any> {
    const modal = this.$modal.open(PdfVisualizerComponent, {
      size: 'lg',
      windowClass: 'pdf-visualizer',
    });
    modal.componentInstance.title = fileName;
    modal.componentInstance.loading = true;

    //modal.componentInstance.pdfViewer.pdfSrc = blobFile;
    //modal.componentInstance.pdfViewer.refresh();
    modal.componentInstance.loading = false;
    modal.componentInstance.isConfirmationModal = isConfirmationModal;
    modal.componentInstance.pdfSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(
      URL.createObjectURL(blobFile),
    );
    return modal.componentInstance.emitData;
  }

  downloadFile(fileId: string): Observable<Blob> {
    return this.httpClient.get(`${APP_CONFIG.API}/file/${fileId}`, { responseType: 'blob' });
  }

  downloadBlobFile(blobFile: Blob, fileName?: string) {
    const blob = new Blob([blobFile], { type: 'application/octet-stream' });
    saveAs(blob, fileName);
  }

  viewImage(blobFile: Blob, fileName: string) {
    const modal = this.$modal.open(ImageViewerComponent, {
      size: 'lg',
    });
    modal.componentInstance.loading = true;
    modal.componentInstance.title = fileName;
    modal.componentInstance.file = blobFile;
    const fileReader = new FileReader();
    fileReader.onload = () => {
      modal.componentInstance.src = this.domSanitizer.bypassSecurityTrustUrl(fileReader.result as any);
      modal.componentInstance.loading = false;
    };
    fileReader.readAsDataURL(blobFile);
  }

  financialReportDocumentDownload(
    params: FinancialReportDocumentService.FinancialReportDocumentDownloadParams,
  ): Observable<Blob> {
    let url =
      environment.apiUrl +
      `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(
        String(params.purchaseOrderID),
      )}/financial_report_document/download/${encodeURIComponent(String(params.financialReportDocumentID))}`;
    return this.httpClient.get(url, { responseType: 'blob' });
  }
}
