/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroFinancial_report_type } from '../models/lasgro-financial-_report-_type';
import { FinancialReportTypeInputPayload } from '../models/financial-report-type-input-payload';
import { LasgroFinancial_report_typeCollection } from '../models/lasgro-financial-_report-_type-collection';
@Injectable({
  providedIn: 'root',
})
class FinancialReportTypeService extends __BaseService {
  static readonly financialReportTypeUpdatePath = '/purchase_order/financial_report_type';
  static readonly financialReportTypeAddPath = '/purchase_order/financial_report_type';
  static readonly financialReportTypeShowPath = '/purchase_order/financial_report_type/{financialReportTypeID}';
  static readonly financialReportTypeDeletePath = '/purchase_order/financial_report_type/{financialReportTypeID}';
  static readonly financialReportTypeListPath = '/purchase_order/financial_report_types';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * update financial_report_type
   *
   * Updates a financial report type
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload The input payload for financial report type
   * @return OK
   */
  financialReportTypeUpdateResponse(payload: FinancialReportTypeInputPayload): __Observable<__StrictHttpResponse<LasgroFinancial_report_type>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/purchase_order/financial_report_type`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroFinancial_report_type>;
      })
    );
  }
  /**
   * update financial_report_type
   *
   * Updates a financial report type
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload The input payload for financial report type
   * @return OK
   */
  financialReportTypeUpdate(payload: FinancialReportTypeInputPayload): __Observable<LasgroFinancial_report_type> {
    return this.financialReportTypeUpdateResponse(payload).pipe(
      __map(_r => _r.body as LasgroFinancial_report_type)
    );
  }

  /**
   * add financial_report_type
   *
   * Add a new financial report type
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload The input payload for financial report type
   */
  financialReportTypeAddResponse(payload: FinancialReportTypeInputPayload): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/purchase_order/financial_report_type`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add financial_report_type
   *
   * Add a new financial report type
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload The input payload for financial report type
   */
  financialReportTypeAdd(payload: FinancialReportTypeInputPayload): __Observable<null> {
    return this.financialReportTypeAddResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * show financial_report_type
   *
   * Get financial report type by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param financialReportTypeID Financial Report type ID
   * @return OK
   */
  financialReportTypeShowResponse(financialReportTypeID: string): __Observable<__StrictHttpResponse<LasgroFinancial_report_type>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/purchase_order/financial_report_type/${encodeURIComponent(String(financialReportTypeID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroFinancial_report_type>;
      })
    );
  }
  /**
   * show financial_report_type
   *
   * Get financial report type by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param financialReportTypeID Financial Report type ID
   * @return OK
   */
  financialReportTypeShow(financialReportTypeID: string): __Observable<LasgroFinancial_report_type> {
    return this.financialReportTypeShowResponse(financialReportTypeID).pipe(
      __map(_r => _r.body as LasgroFinancial_report_type)
    );
  }

  /**
   * delete financial_report_type
   *
   * Delete financial report type by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param financialReportTypeID Financial Report type ID
   */
  financialReportTypeDeleteResponse(financialReportTypeID: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/purchase_order/financial_report_type/${encodeURIComponent(String(financialReportTypeID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete financial_report_type
   *
   * Delete financial report type by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param financialReportTypeID Financial Report type ID
   */
  financialReportTypeDelete(financialReportTypeID: string): __Observable<null> {
    return this.financialReportTypeDeleteResponse(financialReportTypeID).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * list financial_report_type
   *
   * Lists financial report types
   *
   * Required security scopes:
   *   * `api:access`
   * @return OK
   */
  financialReportTypeListResponse(): __Observable<__StrictHttpResponse<LasgroFinancial_report_typeCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/purchase_order/financial_report_types`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroFinancial_report_typeCollection>;
      })
    );
  }
  /**
   * list financial_report_type
   *
   * Lists financial report types
   *
   * Required security scopes:
   *   * `api:access`
   * @return OK
   */
  financialReportTypeList(): __Observable<LasgroFinancial_report_typeCollection> {
    return this.financialReportTypeListResponse().pipe(
      __map(_r => _r.body as LasgroFinancial_report_typeCollection)
    );
  }
}

module FinancialReportTypeService {
}

export { FinancialReportTypeService }
