import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SearchWidgetComponent } from './search-widget.component';
import { FilterComponent } from './filters/filter.component';
import { SearchWidgetFilterComponent } from './filters/search-widget-filter.component';
import { SearchService } from './../../../services/search.service';

import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

const components = [
    SearchWidgetComponent,
    FilterComponent,
    SearchWidgetFilterComponent,
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatInputModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatSelectModule,
        MatButtonModule ],
    exports: [ components ],
    declarations: [ components ],
    entryComponents: [ SearchWidgetFilterComponent ],
    providers: [ SearchService ],
})
export class SearchWidgetModule {}
