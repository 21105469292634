import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Company, SelectBox, User } from '@models/index';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyService, SharedService, Toaster } from '@services/index';
import { CustomPopupComponent } from '@theme/components/custom-popup/custom-popup.component';
import * as _ from 'lodash';
import { LocalStorage } from 'ngx-webstorage';

@Component({
  selector: 'ngx-create-product',
  // styleUrls: ['../../../pages/company/company.style.scss'],
  templateUrl: './create-company-modal.component.html',
})
export class CreateCompanyModalComponent implements OnInit {
  @LocalStorage() organizationId: string;
  @LocalStorage() companyType: string;

  @Input() company_as: string = '';
  company: Company = new Company();
  email = new FormControl('', [Validators.required, Validators.email]);
  user: User = new User();
  disableSubmit: boolean = false;
  priorities: SelectBox[];

  companyUsers: User[];

  associateExistentCompany: boolean = false;

  constructor(
    private activeModal: NgbActiveModal,
    private matDialog: MatDialog,
    private toaster: Toaster,
    private $company: CompanyService,
    private $shared: SharedService,
    protected router: Router,
  ) {}

  ngOnInit() {
    this.user.password = '123456';
    this.priorities = this.$shared.priorities();
  }

  closeModal() {
    this.activeModal.close();
  }

  async searchClient(event: any) {
    try {
      if (event.target.value) {
        const result: any = await this.$company.searchClient(event.target.value).toPromise();
        if (result) {
          const modal = this.matDialog.open(CustomPopupComponent, {
            maxWidth: '350px',
            disableClose: true,
          });
          modal.componentInstance.description = `
                        That email is already in our system, under the company "${ result['name'] }".
                        Would you like to add that company to your list of ${ this.company_as }s?`;

          modal.afterClosed()
            .subscribe((modalResult) => {
              if (modalResult === 'success') {
                if (result && result.contact) {
                  this.$company.addCompanyClients({
                    client_organization_id: result['id'],
                    client_type: this.company_as,
                    primary_contact_id: result['contact'].id,
                  }).subscribe(() => {
                    this.activeModal.close(result);
                  }, (error) => {
                    const errMsg = error.error && error.error.detail ? error.error.detail : error.message;
                    this.toaster.show('error', 'Error', errMsg);
                  });
                } else {
                  this.email.disable();
                  this.associateExistentCompany = true;
                  this.company = result;
                  this.getCompanyUsers(result.id);
                }
                return;
              }
              this.associateExistentCompany = false;
            });
        }
        this.associateExistentCompany = false;
      }
    } catch (error) {
      if (error === 0) {
        this.activeModal.dismiss();
        return;
      }
    }
  }

  async addCompany() {
    this.disableSubmit = true;
    try {
      this.company.plan = 'Free';
      this.company.login_as = this.company_as;

      this.user.username = this.user.email;
      this.user.role = 'Manager';

      this.company.manager = _.clone(this.user);

      const result: any = await this.$company.create(this.company);

      const createdCompany = {
        result: 'success',
        id: result.id,
        name: this.company.name,
      };

      this.disableSubmit = false;
      this.activeModal.close(createdCompany);
    } catch (error) {
      const errMsg =
        !!error.error && !!error.error.detail ? error.error.detail : 'There was an error creating the company';
      this.toaster.show('error', 'Company', errMsg);
    }
    this.disableSubmit = false;
  }

  getCompanyUsers(organizationId) {
    this.$company.users(organizationId)
      .subscribe(result => {
        this.companyUsers = result;
      });
  }

  saveCompany() {
    this.disableSubmit = true;
    if (this.associateExistentCompany) {
      this.$company.addUserPrimaryContact({
        client_organization_id: this.company.id,
        client_type: this.company_as,
        primary_contact: {
          ...this.user,
          username: this.user.email,
          role: 'Manager',
        },
      }).subscribe(() => {
        const associatedCompany = {
          result: 'success',
          id: this.company.id,
          name: this.company.name,
        };
        this.disableSubmit = false;
        this.activeModal.close(associatedCompany);
      }, (error) => {
        const errMsg = error.error && error.error.detail ? error.error.detail : error.message;
        this.toaster.show('error', 'Error', errMsg);
      });
    } else {
      this.addCompany();
    }
  }

  selectedUser($event) {
    this.user = $event;
  }
}
