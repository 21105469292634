<div class="modal-header po-modal font-weight-bold d-block">
  <a (click)="openNotesInPo()" class="d-inline text-underline">{{ isRCDSS ? 'Contract' : 'Purchase Order' }} # {{ mention?.customer_po_number }}</a> Conversation
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body ev-form po-modal pt-1">
    <ngx-po-notes-form
      [allowEdit]="allowEdit"
      [unreadMessages]="mention?.unread_messages"
      [poID]="mention?.purchase_order_id"
      [customerID]="mention?.customer_id"
      [supplierID]="mention?.supplier_id"
      [notes]="notes"
      (notesChangedEvent)="getNotes()"
    ></ngx-po-notes-form>
</div>

<!-- <div class="modal-footer po-modal">

</div> -->

