import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyService } from '@services/company.service';
import { Note } from '@models/note.model';
import { PdfVisualizerComponent } from '@shared/modals/pdf-visualizer/pdf-visualizer.component';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { LocalStorage } from 'ngx-webstorage';
import { environment } from '@env/environment';


@Component({
  selector: 'ngx-modal-po-notes',
  templateUrl: './modal-po-notes.component.html',
  styleUrls: ['./modal-po-notes.component.scss'],
})
export class ModalPONotesComponent {
  isRCDSS = environment.name === 'rcdss';

  @LocalStorage() organizationId: string;
  @Input() mention: any;
  @Input() allowEdit: boolean;

  notes: Note[] = [];

  constructor(
    private activeModal: NgbActiveModal, 
    private $modal: NgbModal, 
    private $company: CompanyService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.$company.poChangesSeen(this.organizationId, this.mention.purchase_order_id, 'purchase_orders').subscribe();
    this.getNotes();
  }

  getNotes() {
    this.$company.getResourceMessages(this.organizationId, this.mention.purchase_order_id).subscribe(res => {
      this.notes = res;
    });
  }

  openNotesInPo() {
    event.cancelBubble=true;
    const route = this.mention.customer_id === this.organizationId ?
          ['/customers', 'work-commissioned', this.mention.purchase_order_id] :
          ['/suppliers', 'work-in-progress', this.mention.purchase_order_id];
        this.router.navigate(route, { queryParams: { mentionNoteId: this.mention.note_id } });
  }

  closeModal() {
    this.activeModal.close('close');
  }

  
}


