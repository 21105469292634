/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroFinancial_report_unit } from '../models/lasgro-financial-_report-_unit';
import { FinancialReportUnitInputPayload } from '../models/financial-report-unit-input-payload';
import { LasgroFinancial_report_unitCollection } from '../models/lasgro-financial-_report-_unit-collection';
@Injectable({
  providedIn: 'root',
})
class FinancialReportUnitService extends __BaseService {
  static readonly financialReportUnitUpdatePath = '/purchase_order/financial_report_unit';
  static readonly financialReportUnitAddPath = '/purchase_order/financial_report_unit';
  static readonly financialReportUnitShowPath = '/purchase_order/financial_report_unit/{financialReportUnitID}';
  static readonly financialReportUnitDeletePath = '/purchase_order/financial_report_unit/{financialReportUnitID}';
  static readonly financialReportUnitListPath = '/purchase_order/financial_report_units';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * update financial_report_unit
   *
   * Updates a financial report unit
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   * @return OK
   */
  financialReportUnitUpdateResponse(payload: FinancialReportUnitInputPayload): __Observable<__StrictHttpResponse<LasgroFinancial_report_unit>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/purchase_order/financial_report_unit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroFinancial_report_unit>;
      })
    );
  }
  /**
   * update financial_report_unit
   *
   * Updates a financial report unit
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   * @return OK
   */
  financialReportUnitUpdate(payload: FinancialReportUnitInputPayload): __Observable<LasgroFinancial_report_unit> {
    return this.financialReportUnitUpdateResponse(payload).pipe(
      __map(_r => _r.body as LasgroFinancial_report_unit)
    );
  }

  /**
   * add financial_report_unit
   *
   * Add a new financial report unit
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   */
  financialReportUnitAddResponse(payload: FinancialReportUnitInputPayload): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/purchase_order/financial_report_unit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add financial_report_unit
   *
   * Add a new financial report unit
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   */
  financialReportUnitAdd(payload: FinancialReportUnitInputPayload): __Observable<null> {
    return this.financialReportUnitAddResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * show financial_report_unit
   *
   * Get financial report unit by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param financialReportUnitID Financial Report unit ID
   * @return OK
   */
  financialReportUnitShowResponse(financialReportUnitID: string): __Observable<__StrictHttpResponse<LasgroFinancial_report_unit>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/purchase_order/financial_report_unit/${encodeURIComponent(String(financialReportUnitID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroFinancial_report_unit>;
      })
    );
  }
  /**
   * show financial_report_unit
   *
   * Get financial report unit by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param financialReportUnitID Financial Report unit ID
   * @return OK
   */
  financialReportUnitShow(financialReportUnitID: string): __Observable<LasgroFinancial_report_unit> {
    return this.financialReportUnitShowResponse(financialReportUnitID).pipe(
      __map(_r => _r.body as LasgroFinancial_report_unit)
    );
  }

  /**
   * delete financial_report_unit
   *
   * Delete financial report unit by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param financialReportUnitID Financial Report unit ID
   */
  financialReportUnitDeleteResponse(financialReportUnitID: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/purchase_order/financial_report_unit/${encodeURIComponent(String(financialReportUnitID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete financial_report_unit
   *
   * Delete financial report unit by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param financialReportUnitID Financial Report unit ID
   */
  financialReportUnitDelete(financialReportUnitID: string): __Observable<null> {
    return this.financialReportUnitDeleteResponse(financialReportUnitID).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * list financial_report_unit
   *
   * Lists financial report units
   *
   * Required security scopes:
   *   * `api:access`
   * @return OK
   */
  financialReportUnitListResponse(): __Observable<__StrictHttpResponse<LasgroFinancial_report_unitCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/purchase_order/financial_report_units`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroFinancial_report_unitCollection>;
      })
    );
  }
  /**
   * list financial_report_unit
   *
   * Lists financial report units
   *
   * Required security scopes:
   *   * `api:access`
   * @return OK
   */
  financialReportUnitList(): __Observable<LasgroFinancial_report_unitCollection> {
    return this.financialReportUnitListResponse().pipe(
      __map(_r => _r.body as LasgroFinancial_report_unitCollection)
    );
  }
}

module FinancialReportUnitService {
}

export { FinancialReportUnitService }
