import { Component, Input, OnInit } from '@angular/core';
import { Company, SupplierLeadDocument } from '@models/company.model';
import { FileItem } from '@models/file-item.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FileService } from '@app/services';
import { Toaster } from '@services/toaster.service';
import { LocalStorage } from 'ngx-webstorage';

@Component({
  selector: 'ngx-supplier-file-view',
  templateUrl: './supplier-file-view.component.html',
  styleUrls: ['./supplier-file-view.component.scss'],
})
export class SupplierFileViewComponent implements OnInit {
  @LocalStorage() organizationId;
  @Input() document: SupplierLeadDocument;
  @Input() supplier: Company;

  constructor(
    private fileService: FileService,
    private $toaster: Toaster,
    private ngbActiveModal: NgbActiveModal) { }

  ngOnInit() {
  }

  showFile(file: FileItem) {
    this.fileService.downloadFile(file.file_id).subscribe((res) => {
      if (file.file_name.endsWith('.pdf')) {
        this.fileService.viewPdf(res, file.file_name);
      } else {
        this.fileService.downloadBlobFile(res, file.file_name);
      }
    }, (error) => {
      const errMsg =
        error.error && error.error.detail ? error.error.detail : 'An error occurred getting the file.';
      this.$toaster.show('error', 'File download', errMsg);
    });
  }

  close() {
    this.ngbActiveModal.close();
  }
}
