<div class="modal-header">
  <h3>{{ title }}</h3>
  <button class="ml-auto" mat-stroked-button color="primary" type="button" (click)="print()">
    Print <mat-icon>print</mat-icon>
  </button>
  <button class="ml-2" mat-stroked-button type="button" (click)="download()">
    Download <mat-icon>download</mat-icon>
  </button>
  <button type="button" class="close" aria-label="close" (click)="close('close')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <h3 *ngIf="loading">Loading...</h3>
  <img id="image-viewer" *ngIf="src" [src]="src" [alt]="title" />
</div>
