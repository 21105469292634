/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroUnit } from '../models/lasgro-unit';
import { UnitInputPayload } from '../models/unit-input-payload';
import { LasgroUnitCollection } from '../models/lasgro-unit-collection';
@Injectable({
  providedIn: 'root',
})
class UnitService extends __BaseService {
  static readonly unitUpdatePath = '/purchase_order/unit';
  static readonly unitAddPath = '/purchase_order/unit';
  static readonly unitShowPath = '/purchase_order/unit/{UnitID}';
  static readonly unitDeletePath = '/purchase_order/unit/{UnitID}';
  static readonly unitListPath = '/purchase_order/units/{organizationID}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * update unit
   *
   * Updates a unit
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   * @return OK
   */
  unitUpdateResponse(payload: UnitInputPayload): __Observable<__StrictHttpResponse<LasgroUnit>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/purchase_order/unit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroUnit>;
      })
    );
  }
  /**
   * update unit
   *
   * Updates a unit
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   * @return OK
   */
  unitUpdate(payload: UnitInputPayload): __Observable<LasgroUnit> {
    return this.unitUpdateResponse(payload).pipe(
      __map(_r => _r.body as LasgroUnit)
    );
  }

  /**
   * add unit
   *
   * Add a new unit
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   */
  unitAddResponse(payload: UnitInputPayload): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/purchase_order/unit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add unit
   *
   * Add a new unit
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload undefined
   */
  unitAdd(payload: UnitInputPayload): __Observable<null> {
    return this.unitAddResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * show unit
   *
   * Get unit by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param UnitID unit ID
   * @return OK
   */
  unitShowResponse(UnitID: string): __Observable<__StrictHttpResponse<LasgroUnit>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/purchase_order/unit/${encodeURIComponent(String(UnitID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroUnit>;
      })
    );
  }
  /**
   * show unit
   *
   * Get unit by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param UnitID unit ID
   * @return OK
   */
  unitShow(UnitID: string): __Observable<LasgroUnit> {
    return this.unitShowResponse(UnitID).pipe(
      __map(_r => _r.body as LasgroUnit)
    );
  }

  /**
   * delete unit
   *
   * Delete unit by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param UnitID Purchase Order unit ID
   */
  unitDeleteResponse(UnitID: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/purchase_order/unit/${encodeURIComponent(String(UnitID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete unit
   *
   * Delete unit by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param UnitID Purchase Order unit ID
   */
  unitDelete(UnitID: string): __Observable<null> {
    return this.unitDeleteResponse(UnitID).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * list unit
   *
   * Lists units
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID Organization ID
   * @return OK
   */
  unitListResponse(organizationID: string): __Observable<__StrictHttpResponse<LasgroUnitCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/purchase_order/units/${encodeURIComponent(String(organizationID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroUnitCollection>;
      })
    );
  }
  /**
   * list unit
   *
   * Lists units
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID Organization ID
   * @return OK
   */
  unitList(organizationID: string): __Observable<LasgroUnitCollection> {
    return this.unitListResponse(organizationID).pipe(
      __map(_r => _r.body as LasgroUnitCollection)
    );
  }
}

module UnitService {
}

export { UnitService }
