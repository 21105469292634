import { Injectable, NgModule } from '@angular/core';
import { PreloadingStrategy, Route, RouterModule, Routes } from '@angular/router';
import { Observable, of } from 'rxjs';

import { AuthGuardService } from '@app/helpers';
import { DownloadFileComponent } from '@shared/components';
import { ItHelpModalComponent } from '@shared/modals/it-help-modal/it-help-modal.component';
import { TrainingGuidesModalComponent } from '@shared/modals/training-guides-modal/training-guides-modal.component';
import { ItHelpGuard } from './shared/guards/it-help.guard';
import { TrainingGuidesGuard } from './shared/guards/training-guides.guard';

@Injectable()
export class PreloadModules implements PreloadingStrategy {
  preload(route: Route, load: Function): Observable<any> {
    return route.data && route.data.preload ? load() : of(null);
    // return load();
  }
}

const routes: Routes = [
  {
    path: 'dashboard',
    canActivate: [AuthGuardService],
    loadChildren: () => import('app/modules/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'tasks',
    canActivate: [AuthGuardService],
    loadChildren: () => import('app/modules/tasks/tasks.module').then(m => m.TasksModule),
  },
  {
    path: 'customers',
    canActivate: [AuthGuardService],
    loadChildren: () => import('app/customers/customers.module').then(m => m.CustomersModule),
  },
  {
    path: 'suppliers',
    canActivate: [AuthGuardService],
    loadChildren: () => import('app/suppliers/suppliers.module').then(m => m.SuppliersModule),
  },
  {
    path: 'purchase-order',
    canActivate: [AuthGuardService],
    loadChildren: () => import('app/modules/purchase-order/purchase-order.module').then(m => m.PurchaseOrderModule),
  },
  {
    path: 'products',
    canActivate: [AuthGuardService],
    loadChildren: () => import('app/modules/products/products.module').then(m => m.ProductsModule),
  },
  {
    path: 'reports',
    loadChildren: () => import('app/modules/reports/reports.module').then(m => m.ReportsModule),
  },
  {
    path: 'quickbooks',
    loadChildren: () => import('app/modules/quickbooks/quickbooks.module').then(m => m.QuickbooksModule),
  },
  {
    path: 'organization',
    loadChildren: () => import('app/modules/organization/organization.module').then(m => m.OrganizationModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('app/modules/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'info',
    loadChildren: () => import('app/modules/info/info.module').then(m => m.InfoModule),
  },
  {
    path: 'saved-checklist',
    loadChildren: () => import('app/modules/saved-checklist/saved-checklist.module').then(m => m.SavedChecklistModule),
  },
  {
    path: 'training',
    loadChildren: () => import('app/modules/training/training.module').then(m => m.TrainingModule),
  },
  {
    path: 'workflows',
    canActivate: [AuthGuardService],
    loadChildren: () => import('app/modules/workflows/workflows.module').then(m => m.WorkflowsModule),
  },
  {
    path: 'download/:fileId',
    // canActivate: DownloadFileGuard,
    component: DownloadFileComponent,
  },
  {
    path: 'help/it-help',
    canActivate: [ItHelpGuard],
    component: ItHelpModalComponent,
  },
  {
    path: 'help/training-guides',
    canActivate: [TrainingGuidesGuard],
    component: TrainingGuidesModalComponent,
  },
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: '**', redirectTo: 'organization' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadModules,
    }),
  ],
  exports: [RouterModule],
  providers: [PreloadModules],
})
export class AppRoutingModule {
}
