/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroFinancial_report_unit_column } from '../models/lasgro-financial-_report-_unit-_column';
import { FinancialReportUnitColumnInputPayload } from '../models/financial-report-unit-column-input-payload';
import { LasgroFinancial_report_unit_columnCollection } from '../models/lasgro-financial-_report-_unit-_column-collection';
@Injectable({
  providedIn: 'root',
})
class FinancialReportUnitColumnService extends __BaseService {
  static readonly financialReportUnitColumnUpdatePath = '/purchase_order/financial_report_unit_column';
  static readonly financialReportUnitColumnAddPath = '/purchase_order/financial_report_unit_column';
  static readonly financialReportUnitColumnShowPath = '/purchase_order/financial_report_unit_column/{financialReportUnitColumnID}';
  static readonly financialReportUnitColumnDeletePath = '/purchase_order/financial_report_unit_column/{financialReportUnitColumnID}';
  static readonly financialReportUnitColumnListPath = '/purchase_order/financial_report_unit_columns';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * update financial_report_unit_column
   *
   * Updates a financial report unit column
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload The input payload for financial report unit column
   * @return OK
   */
  financialReportUnitColumnUpdateResponse(payload: FinancialReportUnitColumnInputPayload): __Observable<__StrictHttpResponse<LasgroFinancial_report_unit_column>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/purchase_order/financial_report_unit_column`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroFinancial_report_unit_column>;
      })
    );
  }
  /**
   * update financial_report_unit_column
   *
   * Updates a financial report unit column
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload The input payload for financial report unit column
   * @return OK
   */
  financialReportUnitColumnUpdate(payload: FinancialReportUnitColumnInputPayload): __Observable<LasgroFinancial_report_unit_column> {
    return this.financialReportUnitColumnUpdateResponse(payload).pipe(
      __map(_r => _r.body as LasgroFinancial_report_unit_column)
    );
  }

  /**
   * add financial_report_unit_column
   *
   * Add a new financial report column
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload The input payload for financial report unit column
   */
  financialReportUnitColumnAddResponse(payload: FinancialReportUnitColumnInputPayload): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/purchase_order/financial_report_unit_column`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * add financial_report_unit_column
   *
   * Add a new financial report column
   *
   * Required security scopes:
   *   * `api:access`
   * @param payload The input payload for financial report unit column
   */
  financialReportUnitColumnAdd(payload: FinancialReportUnitColumnInputPayload): __Observable<null> {
    return this.financialReportUnitColumnAddResponse(payload).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * show financial_report_unit_column
   *
   * Get financial report column by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param financialReportUnitColumnID Financial Report column ID
   * @return OK
   */
  financialReportUnitColumnShowResponse(financialReportUnitColumnID: string): __Observable<__StrictHttpResponse<LasgroFinancial_report_unit_column>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/purchase_order/financial_report_unit_column/${encodeURIComponent(String(financialReportUnitColumnID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroFinancial_report_unit_column>;
      })
    );
  }
  /**
   * show financial_report_unit_column
   *
   * Get financial report column by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param financialReportUnitColumnID Financial Report column ID
   * @return OK
   */
  financialReportUnitColumnShow(financialReportUnitColumnID: string): __Observable<LasgroFinancial_report_unit_column> {
    return this.financialReportUnitColumnShowResponse(financialReportUnitColumnID).pipe(
      __map(_r => _r.body as LasgroFinancial_report_unit_column)
    );
  }

  /**
   * delete financial_report_unit_column
   *
   * Delete financial report unit column by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param financialReportUnitColumnID Financial Report column ID
   */
  financialReportUnitColumnDeleteResponse(financialReportUnitColumnID: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/purchase_order/financial_report_unit_column/${encodeURIComponent(String(financialReportUnitColumnID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete financial_report_unit_column
   *
   * Delete financial report unit column by id
   *
   * Required security scopes:
   *   * `api:access`
   * @param financialReportUnitColumnID Financial Report column ID
   */
  financialReportUnitColumnDelete(financialReportUnitColumnID: string): __Observable<null> {
    return this.financialReportUnitColumnDeleteResponse(financialReportUnitColumnID).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * list financial_report_unit_column
   *
   * Lists financial report columns
   *
   * Required security scopes:
   *   * `api:access`
   * @return OK
   */
  financialReportUnitColumnListResponse(): __Observable<__StrictHttpResponse<LasgroFinancial_report_unit_columnCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/purchase_order/financial_report_unit_columns`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroFinancial_report_unit_columnCollection>;
      })
    );
  }
  /**
   * list financial_report_unit_column
   *
   * Lists financial report columns
   *
   * Required security scopes:
   *   * `api:access`
   * @return OK
   */
  financialReportUnitColumnList(): __Observable<LasgroFinancial_report_unit_columnCollection> {
    return this.financialReportUnitColumnListResponse().pipe(
      __map(_r => _r.body as LasgroFinancial_report_unit_columnCollection)
    );
  }
}

module FinancialReportUnitColumnService {
}

export { FinancialReportUnitColumnService }
