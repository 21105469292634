import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FileService } from '@app/services';

@Component({
  selector: 'ngx-training-guides',
  templateUrl: './training-guides-modal.component.html',
  styleUrls: ['./training-guides-modal.component.scss'],
})
export class TrainingGuidesModalComponent implements OnInit {

  constructor(private activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
  }

  closeModal() {
    this.activeModal.close();
  }

}
