import { AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PdfJsViewerComponent } from 'ng2-pdfjs-viewer';

@Component({
  selector: 'ngx-pdf-visualizer',
  styleUrls: ['./pdf-visualizer.component.scss'],
  templateUrl: './pdf-visualizer.component.html',
})
export class PdfVisualizerComponent implements OnInit {
  //@ViewChild('pdfViewer', { static: true }) public pdfViewer: PdfJsViewerComponent;
  @Input() loading = false;
  @Input() isConfirmationModal = false;
  @Input() title: string;
  @Output() emitData: EventEmitter<any> = new EventEmitter<any>();
  @Input() pdfSrc;
  constructor(public activeModal: NgbActiveModal) {
    (<any>window).pdfWorkerSrc = '/assets/pdfjs/build/pdf.worker.js';
  }

  ngOnInit(): void {}

  close(status: string) {
    this.emitData.emit(status);
    this.activeModal.close(status);
  }
}
