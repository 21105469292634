import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PreviousRouteService } from '@services/previousroute.service';
import { MENU_ITEMS } from '@theme/pages-menu';
import { SocketStream } from '@app/ion-sockets/socket-stream';
import { SocketsConnectorService } from '@app/ion-sockets/services/sockets-connector.service';
import { LocalStorage, SessionStorage } from 'ngx-webstorage';
import { CompanyService } from '@services/index';
import { TrainingService } from '@app/modules/training/services/training.service';
import { AuthService } from '@services/auth.service';
import { environment } from '@env/environment';
import { User } from '@app/models';
import { Observable } from 'rxjs';

@Component({
  selector: 'ngx-main-layout',
  template: `
    <ngx-default-layout>
      <ngx-menu-select
        [selectedSubscription]="selectedSubscription"
        (onChooseSubscription)="updateMenu($event)"></ngx-menu-select>
      <ngx-company-select></ngx-company-select>
      <nb-menu [items]="menu"
               [autoCollapse]="autoCollapse" #nbMenu></nb-menu>
      <router-outlet></router-outlet>
    </ngx-default-layout>
  `,
  styleUrls: ['../../styles/menu.scss'],
})
export class MainLayoutComponent implements OnInit, AfterViewInit {
  @LocalStorage() organizationId: string;
  @LocalStorage() companyType: string;
  @LocalStorage() role: string;
  @SessionStorage() tourDisplayed: boolean;
  @SessionStorage() trainingsChecked: boolean;

  isRCDSS: boolean = environment.name === 'rcdss';

  menu: Array<any> = [];
  menuOriginal: Array<any> = [];
  selectedSubscription: string;
  previousUrl: string;
  autoCollapse: boolean = false;
  wsStream: SocketStream;

  @ViewChild('nbMenu', { read: ElementRef, static: true }) nbMenu: ElementRef;
  draftsMenuIndex = 10;
  draftsMenu: any;
  numBadge: any;

  // user$: Observable<User>;

  constructor(
    private router: Router,
    private previousRoute: PreviousRouteService,
    private socketsService: SocketsConnectorService,
    private trainingService: TrainingService,
    private authService: AuthService,
    private $company: CompanyService,
  ) {
  }

  ngOnInit() {
    /**
     * TODO: Convert menu items to observable
     */
    
    // rewrite: role check has to be outside the filter
    this.menuOriginal = this.menuOriginal.concat(
      MENU_ITEMS.filter(i => {
        // Admin must see all menus
        if (this.role === 'Lasgro Admin') {
          return true;
        }
        // No filters
        if (!i.data) {
          return true;
        }
        // Role filter
        if (i.data.forRole && i.data.forRole !== this.role) {
          return false;
        }
        // Company type filter
        if (i.data.forCompanyType && i.data.forCompanyType !== this.companyType) {
          return false;
        }
        // Passed all filters
        return true;
      })
    );

    this.selectedSubscription = 'surechain';
    this.menu = this.menuOriginal.filter(item => item.subscription === this.selectedSubscription || !item.subscription);

    /*
    // this.user$ = this.store.select('user');
    this.authService.isAuthenticated$
      .pipe(switchMap(() => this.user$))
      .pipe(filter(v => !_.isEmpty(v)))
      .pipe(filter(v => !this.trainingsChecked))
      .pipe(first())
      .pipe(switchMap((user) => this.trainingService.getTrainingList(this.organizationId, user.id, true)))
      .subscribe((trainings: Training[]) => {
        this.trainingsChecked = true;
        let index = 5;
        if (this.companyType === 'supplier') {
          index = 2;
        }
        if (!trainings.length) {
          this.nbMenu.nativeElement.children[0].children[index].classList.add('d-none');
        }
        if (!trainings[0].completed && !this.tourDisplayed) {
          const tour = new Smartour();
          tour.focus({
            el: '.' + this.nbMenu.nativeElement.children[0].children[index].classList.value.replace(/\s+/g, '.'),
            slot: `
              <div>
                <span class="pl-2 pr-2">Hey there! Please watch our training videos before starting to use the system</span>
                <button class="btn btn-default tour-confirm">Got it!</button>
              </div>
            `,
            options: {
              slotPosition: 'right',
              padding: 5,
              animate: true,
            },
            keyNodes: [
              {
                el: '.tour-confirm',
                event: () => {
                  tour.next.bind(tour)();
                },
              },
            ],
          });

          this.tourDisplayed = true;
        }
      });*/
  }

  closeTour() {
    console.log('closeTour');
  }

  updateMenu(event) {
    if (this.selectedSubscription === event) {
      return;
    }
    this.selectedSubscription = event;
    this.menu = this.menuOriginal.filter(item => item.subscription === event || !item.subscription);
    const prevRoute = this.previousRoute.getPreviousUrl();
    // TODO Change this, this may cause an infinite loop
    if (prevRoute.includes('work-in-progress') || prevRoute.includes('projects-received')) {
      return;
    }
    this.router.navigate(['dashboard']);
  }

  ngAfterViewInit() {
    const baseMenu = this.nbMenu.nativeElement.children[0];
    // disabled for now
    /*if (!this.isRCDSS) {
      this.draftsMenu = baseMenu.children[this.draftsMenuIndex].children[0];
      this.wsStream = this.socketsService.createSocketStream(`imported-drafts:${this.organizationId}`);

      this.updateDraftsBadge();

      this.wsStream.listen()
        .subscribe(data => {
          this.updateDraftsBadge();
        });
    }*/
  }

  updateDraftsBadge() {
    this.$company.countNewPoDrafts(this.organizationId).subscribe(res => {
      const count = res.count;
      if (count > 0) {
        const countText = count > 99 ? '99+' : count.toString();
        this.setBadges(countText);
      } else {
        this.removeBadges();
      }
    });
  }

  setBadges(val: string) {
    this.removeBadges();

    const badgeClass = document.createAttribute('class');
    badgeClass.value = 'badge badge-danger';
    const node = document.createElement('span');
    node.setAttributeNode(badgeClass);
    const textNode = document.createTextNode(val);
    node.appendChild(textNode);
    this.numBadge = node;
    this.draftsMenu.insertAdjacentElement('beforeend', node);
  }

  removeBadges() {
    if (this.numBadge?.parentNode) {
      this.numBadge.parentNode.removeChild(this.numBadge);
      this.numBadge.remove();
    }
  }
}
