<ngx-loading [show]="loading"></ngx-loading>

<div class="container-fluid m-0 p-4 shadow">
  <div class="row">
    <div class="col">
      <h2>Messages</h2>
      <h3>
        <small>You have {{ totalUnread }} unread messages and {{ conversations?.length }} conversations</small>
      </h3>
    </div>
  </div>
</div>

<div class="container-fluid" style="background-color: #ebeff5;" *ngIf="isRCDSS">
  <div class="row">
      <div class="col-md-12">

        <!-- <div class="row p-0 m-0">
          <div class="col-md-12 d-flex flex-row">
            
          </div>
        </div> -->
        
        <div class="row p-0 m-0 border bg-white rounded shadow">

          <div class="col-md-4 list-side bg-light p-4 border-right">
            <dx-list
              class="list"
              [dataSource]="dataSource"
              [grouped]="true"
              [searchEnabled]="true"
              selectionMode="single"
              [selectedItemKeys]="[selectedConversation?.purchase_order_id]"
              (onSelectionChanged)="listSelectionChanged($event)"
            >
              <div *dxTemplate="let item of 'item'">
                <div>
                  <div [ngClass]="{
                    'text-secondary': !selectedConversation?.purchase_order_id || selectedConversation?.purchase_order_id !== item?.purchase_order_id,
                    'text-dark': selectedConversation?.purchase_order_id === item?.purchase_order_id
                  }">
                    <div class="font-weight-bold line-container">
                      {{item?.parent_resource_type ? item.parent_resource_type : 'Contract'}}
                      # {{item?.customer_po_number}}
                      <span class="badge badge-pill pull-right"
                            [ngClass]="{ 'badge-danger': item?.unread_messages > 0, 'badge-secondary': item?.unread_messages === 0 }">
                        {{ item?.unread_messages }} unread
                      </span>
                    </div>
                    <div class="message-content py-2 line-container">
                      {{item?.content}}
                    </div>
                    <div>
                      <small>
                        {{item?.user_first_name}} {{item?.user_last_name}} ({{item?.counterpartName}}), {{item?.created_at | amTimeAgo}}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div *dxTemplate="let item of 'group'" class="border-primary">
                <div class="">
                  {{ item.key | titlecase }}s
                </div>
              </div>
            </dx-list>
          </div>

          <div class="col-md-8 p-4 d-flex flex-column justify-content-end" *ngIf="!!conversations?.length && !loading && !!selectedConversation?.message_id">
            <ngx-po-notes-form
              [allowEdit]="true"
              [unreadMessages]="selectedConversation?.unread_messages"
              [showTitle]="false"
              [poID]="selectedConversation?.purchase_order_id"
              [customerID]="selectedConversation?.customer_id"
              [supplierID]="selectedConversation?.supplier_id"
              [notes]="selectedConversation?.notes"
              [isScrollingEnabled]="true"
              (notesChangedEvent)="reloadSelectedConversation()"
            ></ngx-po-notes-form>
          </div>


          <div class="col no-items" *ngIf="!loading && !conversations?.length">
            <div class="d-flex align-items-center">
              <div class="check"></div>
              <h2 class="text-center text-secondary w-100">
                <span>You have no conversations yet</span>
              </h2>
            </div>
          </div>

          <div class="col" *ngIf="!loading && !!conversations?.length && !selectedConversation?.message_id">
            <div class="d-flex align-items-center" style="height: 100%;">
              <h2 class="text-center text-secondary w-100">
                Pick a conversation from the left to open it
              </h2>
            </div>
          </div>

        </div>

    </div>
  </div>
</div>


<!-- <div class="mentions-container container-fluid" style="background-color: #ebeff5;">
  <div class="row">
    <div class="col-md-10 offset-md-1">
      
      <div class="mentions p-4 bg-white shadow round" 
        *ngIf="mentions?.length" 
        [ngClass]="{'scroll-y': mentions?.length}">
        <div class="mention-item" *ngFor="let mention of mentions; let $index = index"
             (click)="openMention(mention)" onclick="event.cancelBubble=true"
             [ngClass]="{ 'bg-white': mention.unread_messages > 0 }">

          <div class="row notification-detail">
            <div class="col-md-10">

              <div class="company-data">
                <strong class="pr-2">{{isRCDSS ? 'Contract' : 'Purchase Order'}} # {{ mention.customer_po_number }}</strong>
                <span *ngIf="mention.customer_name && organizationId === mention.supplier_id">
                    {{ isRCDSS ? 'Department' : 'Customer'}}: {{ mention.customer_name }}
                  </span>
                <span *ngIf="mention.supplier_name && organizationId === mention.customer_id">
                    {{ isRCDSS ? 'Vendor' : 'Supplier'}}: {{ mention.supplier_name }}
                  </span>
              </div>
              <div class="note-data pr-2 pt-2">
                <strong>{{ mention.user_first_name }} {{ mention.user_last_name }}</strong>: {{ mention.content }}
              </div>

            </div>
            <div class="notification-actions col-md-2">
              <span class="badge badge-pill"
                    [ngClass]="{ 'badge-danger': mention.unread_messages > 0, 'badge-secondary': mention.unread_messages === 0 }">{{ mention.unread_messages }}
                unread</span>
              <small>Last message: <br>{{ mention.created_at | amTimeAgo }}</small>
            </div>
          </div>

        </div>
      </div>
      <div class="no-items" *ngIf="!mentions?.length">
        <div class="d-flex justify-content-center align-items-center">
          <div class="check"></div>
          <div class="text">
            <span>You have no messages yet</span>
          </div>
        </div>
      </div>

    </div>
  </div>
  
</div>
 -->