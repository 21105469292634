/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LasgroPurchase_orderCollection } from '../models/lasgro-purchase-_order-collection';
import { LasgroCount } from '../models/lasgro-count';
import { LasgroPurchase_order } from '../models/lasgro-purchase-_order';
import { PurchaseOrderInputPayload } from '../models/purchase-order-input-payload';
import { LasgroPanda_doc_document } from '../models/lasgro-panda-_doc-_document';
import { PandaDocDocumentInputPayload } from '../models/panda-doc-document-input-payload';
import { LasgroRcdss_application_form } from '../models/lasgro-rcdss-_application-_form';
import { SaveRcdssAppFormPurchaseOrderPayload } from '../models/save-rcdss-app-form-purchase-order-payload';
import { ChangeStatusRcdssAppFormPurchaseOrderPayload } from '../models/change-status-rcdss-app-form-purchase-order-payload';
import { LasgroRcdss_sord_form } from '../models/lasgro-rcdss-_sord-_form';
import { RcdssSordFormInputPayload } from '../models/rcdss-sord-form-input-payload';
import { SaveDraftPurchaseOrderPayload } from '../models/save-draft-purchase-order-payload';
import { ChangeStatusPurchaseOrderPayload } from '../models/change-status-purchase-order-payload';
@Injectable({
  providedIn: 'root',
})
class PurchaseOrderService extends __BaseService {
  static readonly purchaseOrderListPath = '/organizations/{organizationID}/purchase_orders';
  static readonly purchaseOrderCountNewDraftsPath = '/organizations/{organizationID}/purchase_orders/count_new_drafts';
  static readonly purchaseOrderCheckValuesPath = '/organizations/{organizationID}/purchase_orders/is_value_available';
  static readonly purchaseOrderShowPath = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}';
  static readonly purchaseOrderUpdatePath = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}';
  static readonly purchaseOrderDeletePath = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}';
  static readonly purchaseOrderRcdssUploadElectronicVoucherPath = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/electronic_voucher';
  static readonly purchaseOrderGetRcdssAppFormPath = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/rcdss_application_form';
  static readonly purchaseOrderSaveRcdssAppFormPath = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/rcdss_application_form';
  static readonly purchaseOrderChangeStatusRcdssAppFormPath = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/rcdss_application_form/change_status';
  static readonly purchaseOrderFirstAppFormOpeningPath = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/rcdss_application_form/first_app_form_opening';
  static readonly purchaseOrderShowRcdssSordFormPath = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/rcdss_sord_form';
  static readonly purchaseOrderSaveRcdssSordFormPath = '/organizations/{organizationID}/purchase_orders/{purchaseOrderID}/rcdss_sord_form';
  static readonly purchaseOrderSaveDraftPath = '/organizations/{organizationID}/purchase_orders_draft';
  static readonly purchaseOrderDeleteDraftPath = '/organizations/{organizationID}/purchase_orders_draft/{purchaseOrderID}';
  static readonly purchaseOrderPermaDeleteDraftPath = '/organizations/{organizationID}/purchase_orders_draft/{purchaseOrderID}/delete_permanently';
  static readonly purchaseOrderRecoverDraftPath = '/organizations/{organizationID}/purchase_orders_draft/{purchaseOrderID}/recover';
  static readonly purchaseOrderList1Path = '/purchase_orders';
  static readonly purchaseOrderChangeStatusPath = '/purchase_orders/change_state/{purchaseOrderID}';
  static readonly purchaseOrderShow1Path = '/purchase_orders/{purchaseOrderID}';
  static readonly purchaseOrderUpdate1Path = '/purchase_orders/{purchaseOrderID}';
  static readonly purchaseOrderRestorePath = '/purchase_orders/{purchaseOrderID}/restore';
  static readonly purchaseOrderSendToSupplierPath = '/purchase_orders/{purchaseOrderID}/send_to_supplier';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * list purchase_order
   *
   * Lists purchase orders
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderService.PurchaseOrderListParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `viewType`: What view type to show for the checklist
   *
   * @return OK
   */
  purchaseOrderListResponse(params: PurchaseOrderService.PurchaseOrderListParams): __Observable<__StrictHttpResponse<LasgroPurchase_orderCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.viewType != null) __params = __params.set('viewType', params.viewType.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroPurchase_orderCollection>;
      })
    );
  }
  /**
   * list purchase_order
   *
   * Lists purchase orders
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderService.PurchaseOrderListParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `viewType`: What view type to show for the checklist
   *
   * @return OK
   */
  purchaseOrderList(params: PurchaseOrderService.PurchaseOrderListParams): __Observable<LasgroPurchase_orderCollection> {
    return this.purchaseOrderListResponse(params).pipe(
      __map(_r => _r.body as LasgroPurchase_orderCollection)
    );
  }

  /**
   * count_new_drafts purchase_order
   *
   * Gets the amount of new PO drafts for this organization.
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  purchaseOrderCountNewDraftsResponse(organizationID: string): __Observable<__StrictHttpResponse<LasgroCount>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(organizationID))}/purchase_orders/count_new_drafts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroCount>;
      })
    );
  }
  /**
   * count_new_drafts purchase_order
   *
   * Gets the amount of new PO drafts for this organization.
   *
   * Required security scopes:
   *   * `api:access`
   * @param organizationID undefined
   * @return OK
   */
  purchaseOrderCountNewDrafts(organizationID: string): __Observable<LasgroCount> {
    return this.purchaseOrderCountNewDraftsResponse(organizationID).pipe(
      __map(_r => _r.body as LasgroCount)
    );
  }

  /**
   * check_values purchase_order
   *
   * Check for existing po number and sales order number values
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderService.PurchaseOrderCheckValuesParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `salesOrderNumber`:
   *
   * - `poNumber`:
   */
  purchaseOrderCheckValuesResponse(params: PurchaseOrderService.PurchaseOrderCheckValuesParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.salesOrderNumber != null) __params = __params.set('salesOrderNumber', params.salesOrderNumber.toString());
    if (params.poNumber != null) __params = __params.set('poNumber', params.poNumber.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/is_value_available`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * check_values purchase_order
   *
   * Check for existing po number and sales order number values
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderService.PurchaseOrderCheckValuesParams` containing the following parameters:
   *
   * - `organizationID`:
   *
   * - `salesOrderNumber`:
   *
   * - `poNumber`:
   */
  purchaseOrderCheckValues(params: PurchaseOrderService.PurchaseOrderCheckValuesParams): __Observable<null> {
    return this.purchaseOrderCheckValuesResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * show purchase_order
   *
   * Retrieve purchase order with given id
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderService.PurchaseOrderShowParams` containing the following parameters:
   *
   * - `purchaseOrderID`: Purchase Order ID
   *
   * - `organizationID`:
   *
   * @return OK
   */
  purchaseOrderShowResponse(params: PurchaseOrderService.PurchaseOrderShowParams): __Observable<__StrictHttpResponse<LasgroPurchase_order>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroPurchase_order>;
      })
    );
  }
  /**
   * show purchase_order
   *
   * Retrieve purchase order with given id
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderService.PurchaseOrderShowParams` containing the following parameters:
   *
   * - `purchaseOrderID`: Purchase Order ID
   *
   * - `organizationID`:
   *
   * @return OK
   */
  purchaseOrderShow(params: PurchaseOrderService.PurchaseOrderShowParams): __Observable<LasgroPurchase_order> {
    return this.purchaseOrderShowResponse(params).pipe(
      __map(_r => _r.body as LasgroPurchase_order)
    );
  }

  /**
   * update purchase_order
   *
   * Updates a purchase order
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderService.PurchaseOrderUpdateParams` containing the following parameters:
   *
   * - `purchaseOrderID`: Purchase Order ID
   *
   * - `payload`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  purchaseOrderUpdateResponse(params: PurchaseOrderService.PurchaseOrderUpdateParams): __Observable<__StrictHttpResponse<LasgroPurchase_order>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroPurchase_order>;
      })
    );
  }
  /**
   * update purchase_order
   *
   * Updates a purchase order
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderService.PurchaseOrderUpdateParams` containing the following parameters:
   *
   * - `purchaseOrderID`: Purchase Order ID
   *
   * - `payload`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  purchaseOrderUpdate(params: PurchaseOrderService.PurchaseOrderUpdateParams): __Observable<LasgroPurchase_order> {
    return this.purchaseOrderUpdateResponse(params).pipe(
      __map(_r => _r.body as LasgroPurchase_order)
    );
  }

  /**
   * delete purchase_order
   *
   * Deletes a purchase order draft
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderService.PurchaseOrderDeleteParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   */
  purchaseOrderDeleteResponse(params: PurchaseOrderService.PurchaseOrderDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete purchase_order
   *
   * Deletes a purchase order draft
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderService.PurchaseOrderDeleteParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   */
  purchaseOrderDelete(params: PurchaseOrderService.PurchaseOrderDeleteParams): __Observable<null> {
    return this.purchaseOrderDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * rcdss_upload_electronic_voucher purchase_order
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderService.PurchaseOrderRcdssUploadElectronicVoucherParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  purchaseOrderRcdssUploadElectronicVoucherResponse(params: PurchaseOrderService.PurchaseOrderRcdssUploadElectronicVoucherParams): __Observable<__StrictHttpResponse<LasgroPanda_doc_document>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/electronic_voucher`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroPanda_doc_document>;
      })
    );
  }
  /**
   * rcdss_upload_electronic_voucher purchase_order
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderService.PurchaseOrderRcdssUploadElectronicVoucherParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  purchaseOrderRcdssUploadElectronicVoucher(params: PurchaseOrderService.PurchaseOrderRcdssUploadElectronicVoucherParams): __Observable<LasgroPanda_doc_document> {
    return this.purchaseOrderRcdssUploadElectronicVoucherResponse(params).pipe(
      __map(_r => _r.body as LasgroPanda_doc_document)
    );
  }

  /**
   * get_rcdss_app_form purchase_order
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderService.PurchaseOrderGetRcdssAppFormParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  purchaseOrderGetRcdssAppFormResponse(params: PurchaseOrderService.PurchaseOrderGetRcdssAppFormParams): __Observable<__StrictHttpResponse<LasgroRcdss_application_form>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/rcdss_application_form`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroRcdss_application_form>;
      })
    );
  }
  /**
   * get_rcdss_app_form purchase_order
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderService.PurchaseOrderGetRcdssAppFormParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  purchaseOrderGetRcdssAppForm(params: PurchaseOrderService.PurchaseOrderGetRcdssAppFormParams): __Observable<LasgroRcdss_application_form> {
    return this.purchaseOrderGetRcdssAppFormResponse(params).pipe(
      __map(_r => _r.body as LasgroRcdss_application_form)
    );
  }

  /**
   * save_rcdss_app_form purchase_order
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderService.PurchaseOrderSaveRcdssAppFormParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  purchaseOrderSaveRcdssAppFormResponse(params: PurchaseOrderService.PurchaseOrderSaveRcdssAppFormParams): __Observable<__StrictHttpResponse<LasgroRcdss_application_form>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/rcdss_application_form`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroRcdss_application_form>;
      })
    );
  }
  /**
   * save_rcdss_app_form purchase_order
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderService.PurchaseOrderSaveRcdssAppFormParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  purchaseOrderSaveRcdssAppForm(params: PurchaseOrderService.PurchaseOrderSaveRcdssAppFormParams): __Observable<LasgroRcdss_application_form> {
    return this.purchaseOrderSaveRcdssAppFormResponse(params).pipe(
      __map(_r => _r.body as LasgroRcdss_application_form)
    );
  }

  /**
   * change_status_rcdss_app_form purchase_order
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderService.PurchaseOrderChangeStatusRcdssAppFormParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  purchaseOrderChangeStatusRcdssAppFormResponse(params: PurchaseOrderService.PurchaseOrderChangeStatusRcdssAppFormParams): __Observable<__StrictHttpResponse<LasgroRcdss_application_form>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/rcdss_application_form/change_status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroRcdss_application_form>;
      })
    );
  }
  /**
   * change_status_rcdss_app_form purchase_order
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderService.PurchaseOrderChangeStatusRcdssAppFormParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  purchaseOrderChangeStatusRcdssAppForm(params: PurchaseOrderService.PurchaseOrderChangeStatusRcdssAppFormParams): __Observable<LasgroRcdss_application_form> {
    return this.purchaseOrderChangeStatusRcdssAppFormResponse(params).pipe(
      __map(_r => _r.body as LasgroRcdss_application_form)
    );
  }

  /**
   * first_app_form_opening purchase_order
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderService.PurchaseOrderFirstAppFormOpeningParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   */
  purchaseOrderFirstAppFormOpeningResponse(params: PurchaseOrderService.PurchaseOrderFirstAppFormOpeningParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/rcdss_application_form/first_app_form_opening`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * first_app_form_opening purchase_order
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderService.PurchaseOrderFirstAppFormOpeningParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   */
  purchaseOrderFirstAppFormOpening(params: PurchaseOrderService.PurchaseOrderFirstAppFormOpeningParams): __Observable<null> {
    return this.purchaseOrderFirstAppFormOpeningResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * show_rcdss_sord_form purchase_order
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderService.PurchaseOrderShowRcdssSordFormParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  purchaseOrderShowRcdssSordFormResponse(params: PurchaseOrderService.PurchaseOrderShowRcdssSordFormParams): __Observable<__StrictHttpResponse<LasgroRcdss_sord_form>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/rcdss_sord_form`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroRcdss_sord_form>;
      })
    );
  }
  /**
   * show_rcdss_sord_form purchase_order
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderService.PurchaseOrderShowRcdssSordFormParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  purchaseOrderShowRcdssSordForm(params: PurchaseOrderService.PurchaseOrderShowRcdssSordFormParams): __Observable<LasgroRcdss_sord_form> {
    return this.purchaseOrderShowRcdssSordFormResponse(params).pipe(
      __map(_r => _r.body as LasgroRcdss_sord_form)
    );
  }

  /**
   * save_rcdss_sord_form purchase_order
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderService.PurchaseOrderSaveRcdssSordFormParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  purchaseOrderSaveRcdssSordFormResponse(params: PurchaseOrderService.PurchaseOrderSaveRcdssSordFormParams): __Observable<__StrictHttpResponse<LasgroRcdss_sord_form>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}/rcdss_sord_form`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroRcdss_sord_form>;
      })
    );
  }
  /**
   * save_rcdss_sord_form purchase_order
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderService.PurchaseOrderSaveRcdssSordFormParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `payload`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  purchaseOrderSaveRcdssSordForm(params: PurchaseOrderService.PurchaseOrderSaveRcdssSordFormParams): __Observable<LasgroRcdss_sord_form> {
    return this.purchaseOrderSaveRcdssSordFormResponse(params).pipe(
      __map(_r => _r.body as LasgroRcdss_sord_form)
    );
  }

  /**
   * save_draft purchase_order
   *
   * Saves a purchase order draft
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderService.PurchaseOrderSaveDraftParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `organizationID`:
   *
   * - `workflow_id`:
   *
   * - `noApprover`:
   *
   * - `finalize`:
   *
   * @return Created
   */
  purchaseOrderSaveDraftResponse(params: PurchaseOrderService.PurchaseOrderSaveDraftParams): __Observable<__StrictHttpResponse<LasgroPurchase_order>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    if (params.workflowId != null) __params = __params.set('workflow_id', params.workflowId.toString());
    if (params.noApprover != null) __params = __params.set('noApprover', params.noApprover.toString());
    if (params.finalize != null) __params = __params.set('finalize', params.finalize.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders_draft`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroPurchase_order>;
      })
    );
  }
  /**
   * save_draft purchase_order
   *
   * Saves a purchase order draft
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderService.PurchaseOrderSaveDraftParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `organizationID`:
   *
   * - `workflow_id`:
   *
   * - `noApprover`:
   *
   * - `finalize`:
   *
   * @return Created
   */
  purchaseOrderSaveDraft(params: PurchaseOrderService.PurchaseOrderSaveDraftParams): __Observable<LasgroPurchase_order> {
    return this.purchaseOrderSaveDraftResponse(params).pipe(
      __map(_r => _r.body as LasgroPurchase_order)
    );
  }

  /**
   * delete_draft purchase_order
   *
   * Soft-deletes a purchase order draft
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderService.PurchaseOrderDeleteDraftParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   */
  purchaseOrderDeleteDraftResponse(params: PurchaseOrderService.PurchaseOrderDeleteDraftParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders_draft/${encodeURIComponent(String(params.purchaseOrderID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete_draft purchase_order
   *
   * Soft-deletes a purchase order draft
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderService.PurchaseOrderDeleteDraftParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   */
  purchaseOrderDeleteDraft(params: PurchaseOrderService.PurchaseOrderDeleteDraftParams): __Observable<null> {
    return this.purchaseOrderDeleteDraftResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * perma_delete_draft purchase_order
   *
   * Permanently deletes a purchase order draft
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderService.PurchaseOrderPermaDeleteDraftParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   */
  purchaseOrderPermaDeleteDraftResponse(params: PurchaseOrderService.PurchaseOrderPermaDeleteDraftParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders_draft/${encodeURIComponent(String(params.purchaseOrderID))}/delete_permanently`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * perma_delete_draft purchase_order
   *
   * Permanently deletes a purchase order draft
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderService.PurchaseOrderPermaDeleteDraftParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   */
  purchaseOrderPermaDeleteDraft(params: PurchaseOrderService.PurchaseOrderPermaDeleteDraftParams): __Observable<null> {
    return this.purchaseOrderPermaDeleteDraftResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * recover_draft purchase_order
   *
   * Recovers a deleted purchase order draft
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderService.PurchaseOrderRecoverDraftParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   */
  purchaseOrderRecoverDraftResponse(params: PurchaseOrderService.PurchaseOrderRecoverDraftParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/organizations/${encodeURIComponent(String(params.organizationID))}/purchase_orders_draft/${encodeURIComponent(String(params.purchaseOrderID))}/recover`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * recover_draft purchase_order
   *
   * Recovers a deleted purchase order draft
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderService.PurchaseOrderRecoverDraftParams` containing the following parameters:
   *
   * - `purchaseOrderID`:
   *
   * - `organizationID`:
   */
  purchaseOrderRecoverDraft(params: PurchaseOrderService.PurchaseOrderRecoverDraftParams): __Observable<null> {
    return this.purchaseOrderRecoverDraftResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * list purchase_order
   *
   * Lists purchase orders
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderService.PurchaseOrderList1Params` containing the following parameters:
   *
   * - `viewType`: What view type to show for the checklist
   *
   * - `organizationID`:
   *
   * @return OK
   */
  purchaseOrderList1Response(params: PurchaseOrderService.PurchaseOrderList1Params): __Observable<__StrictHttpResponse<LasgroPurchase_orderCollection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.viewType != null) __params = __params.set('viewType', params.viewType.toString());
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/purchase_orders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroPurchase_orderCollection>;
      })
    );
  }
  /**
   * list purchase_order
   *
   * Lists purchase orders
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderService.PurchaseOrderList1Params` containing the following parameters:
   *
   * - `viewType`: What view type to show for the checklist
   *
   * - `organizationID`:
   *
   * @return OK
   */
  purchaseOrderList1(params: PurchaseOrderService.PurchaseOrderList1Params): __Observable<LasgroPurchase_orderCollection> {
    return this.purchaseOrderList1Response(params).pipe(
      __map(_r => _r.body as LasgroPurchase_orderCollection)
    );
  }

  /**
   * change_status purchase_order
   *
   * When someone wants to accept/reject the PO
   * @param params The `PurchaseOrderService.PurchaseOrderChangeStatusParams` containing the following parameters:
   *
   * - `purchaseOrderID`: Purchase Order ID
   *
   * - `payload`:
   *
   * @return OK
   */
  purchaseOrderChangeStatusResponse(params: PurchaseOrderService.PurchaseOrderChangeStatusParams): __Observable<__StrictHttpResponse<LasgroPurchase_order>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/purchase_orders/change_state/${encodeURIComponent(String(params.purchaseOrderID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroPurchase_order>;
      })
    );
  }
  /**
   * change_status purchase_order
   *
   * When someone wants to accept/reject the PO
   * @param params The `PurchaseOrderService.PurchaseOrderChangeStatusParams` containing the following parameters:
   *
   * - `purchaseOrderID`: Purchase Order ID
   *
   * - `payload`:
   *
   * @return OK
   */
  purchaseOrderChangeStatus(params: PurchaseOrderService.PurchaseOrderChangeStatusParams): __Observable<LasgroPurchase_order> {
    return this.purchaseOrderChangeStatusResponse(params).pipe(
      __map(_r => _r.body as LasgroPurchase_order)
    );
  }

  /**
   * show purchase_order
   *
   * Retrieve purchase order with given id
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderService.PurchaseOrderShow1Params` containing the following parameters:
   *
   * - `purchaseOrderID`: Purchase Order ID
   *
   * - `organizationID`:
   *
   * @return OK
   */
  purchaseOrderShow1Response(params: PurchaseOrderService.PurchaseOrderShow1Params): __Observable<__StrictHttpResponse<LasgroPurchase_order>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroPurchase_order>;
      })
    );
  }
  /**
   * show purchase_order
   *
   * Retrieve purchase order with given id
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderService.PurchaseOrderShow1Params` containing the following parameters:
   *
   * - `purchaseOrderID`: Purchase Order ID
   *
   * - `organizationID`:
   *
   * @return OK
   */
  purchaseOrderShow1(params: PurchaseOrderService.PurchaseOrderShow1Params): __Observable<LasgroPurchase_order> {
    return this.purchaseOrderShow1Response(params).pipe(
      __map(_r => _r.body as LasgroPurchase_order)
    );
  }

  /**
   * update purchase_order
   *
   * Updates a purchase order
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderService.PurchaseOrderUpdate1Params` containing the following parameters:
   *
   * - `purchaseOrderID`: Purchase Order ID
   *
   * - `payload`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  purchaseOrderUpdate1Response(params: PurchaseOrderService.PurchaseOrderUpdate1Params): __Observable<__StrictHttpResponse<LasgroPurchase_order>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;
    if (params.organizationID != null) __params = __params.set('organizationID', params.organizationID.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/purchase_orders/${encodeURIComponent(String(params.purchaseOrderID))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LasgroPurchase_order>;
      })
    );
  }
  /**
   * update purchase_order
   *
   * Updates a purchase order
   *
   * Required security scopes:
   *   * `api:access`
   * @param params The `PurchaseOrderService.PurchaseOrderUpdate1Params` containing the following parameters:
   *
   * - `purchaseOrderID`: Purchase Order ID
   *
   * - `payload`:
   *
   * - `organizationID`:
   *
   * @return OK
   */
  purchaseOrderUpdate1(params: PurchaseOrderService.PurchaseOrderUpdate1Params): __Observable<LasgroPurchase_order> {
    return this.purchaseOrderUpdate1Response(params).pipe(
      __map(_r => _r.body as LasgroPurchase_order)
    );
  }

  /**
   * restore purchase_order
   *
   * Restores an archived PO to it's previous status
   *
   * Required security scopes:
   *   * `api:access`
   * @param purchaseOrderID Purchase Order ID
   */
  purchaseOrderRestoreResponse(purchaseOrderID: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/purchase_orders/${encodeURIComponent(String(purchaseOrderID))}/restore`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * restore purchase_order
   *
   * Restores an archived PO to it's previous status
   *
   * Required security scopes:
   *   * `api:access`
   * @param purchaseOrderID Purchase Order ID
   */
  purchaseOrderRestore(purchaseOrderID: string): __Observable<null> {
    return this.purchaseOrderRestoreResponse(purchaseOrderID).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * send_to_supplier purchase_order
   *
   * Sends this QB imported PO to its supplier.
   *
   * Required security scopes:
   *   * `api:access`
   * @param purchaseOrderID Purchase Order ID
   */
  purchaseOrderSendToSupplierResponse(purchaseOrderID: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/purchase_orders/${encodeURIComponent(String(purchaseOrderID))}/send_to_supplier`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * send_to_supplier purchase_order
   *
   * Sends this QB imported PO to its supplier.
   *
   * Required security scopes:
   *   * `api:access`
   * @param purchaseOrderID Purchase Order ID
   */
  purchaseOrderSendToSupplier(purchaseOrderID: string): __Observable<null> {
    return this.purchaseOrderSendToSupplierResponse(purchaseOrderID).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module PurchaseOrderService {

  /**
   * Parameters for purchaseOrderList
   */
  export interface PurchaseOrderListParams {
    organizationID: string;

    /**
     * What view type to show for the checklist
     */
    viewType?: 'tiny' | 'report';
  }

  /**
   * Parameters for purchaseOrderCheckValues
   */
  export interface PurchaseOrderCheckValuesParams {
    organizationID: string;
    salesOrderNumber?: string;
    poNumber?: string;
  }

  /**
   * Parameters for purchaseOrderShow
   */
  export interface PurchaseOrderShowParams {

    /**
     * Purchase Order ID
     */
    purchaseOrderID: string;
    organizationID: string;
  }

  /**
   * Parameters for purchaseOrderUpdate
   */
  export interface PurchaseOrderUpdateParams {

    /**
     * Purchase Order ID
     */
    purchaseOrderID: string;
    payload: PurchaseOrderInputPayload;
    organizationID: string;
  }

  /**
   * Parameters for purchaseOrderDelete
   */
  export interface PurchaseOrderDeleteParams {
    purchaseOrderID: string;
    organizationID: string;
  }

  /**
   * Parameters for purchaseOrderRcdssUploadElectronicVoucher
   */
  export interface PurchaseOrderRcdssUploadElectronicVoucherParams {
    purchaseOrderID: string;
    payload: PandaDocDocumentInputPayload;
    organizationID: string;
  }

  /**
   * Parameters for purchaseOrderGetRcdssAppForm
   */
  export interface PurchaseOrderGetRcdssAppFormParams {
    purchaseOrderID: string;
    organizationID: string;
  }

  /**
   * Parameters for purchaseOrderSaveRcdssAppForm
   */
  export interface PurchaseOrderSaveRcdssAppFormParams {
    purchaseOrderID: string;
    payload: SaveRcdssAppFormPurchaseOrderPayload;
    organizationID: string;
  }

  /**
   * Parameters for purchaseOrderChangeStatusRcdssAppForm
   */
  export interface PurchaseOrderChangeStatusRcdssAppFormParams {
    purchaseOrderID: string;
    payload: ChangeStatusRcdssAppFormPurchaseOrderPayload;
    organizationID: string;
  }

  /**
   * Parameters for purchaseOrderFirstAppFormOpening
   */
  export interface PurchaseOrderFirstAppFormOpeningParams {
    purchaseOrderID: string;
    organizationID: string;
  }

  /**
   * Parameters for purchaseOrderShowRcdssSordForm
   */
  export interface PurchaseOrderShowRcdssSordFormParams {
    purchaseOrderID: string;
    organizationID: string;
  }

  /**
   * Parameters for purchaseOrderSaveRcdssSordForm
   */
  export interface PurchaseOrderSaveRcdssSordFormParams {
    purchaseOrderID: string;
    payload: RcdssSordFormInputPayload;
    organizationID: string;
  }

  /**
   * Parameters for purchaseOrderSaveDraft
   */
  export interface PurchaseOrderSaveDraftParams {
    payload: SaveDraftPurchaseOrderPayload;
    organizationID: string;
    workflowId?: string;
    noApprover?: boolean;
    finalize?: boolean;
  }

  /**
   * Parameters for purchaseOrderDeleteDraft
   */
  export interface PurchaseOrderDeleteDraftParams {
    purchaseOrderID: string;
    organizationID: string;
  }

  /**
   * Parameters for purchaseOrderPermaDeleteDraft
   */
  export interface PurchaseOrderPermaDeleteDraftParams {
    purchaseOrderID: string;
    organizationID: string;
  }

  /**
   * Parameters for purchaseOrderRecoverDraft
   */
  export interface PurchaseOrderRecoverDraftParams {
    purchaseOrderID: string;
    organizationID: string;
  }

  /**
   * Parameters for purchaseOrderList1
   */
  export interface PurchaseOrderList1Params {

    /**
     * What view type to show for the checklist
     */
    viewType?: 'tiny' | 'report';
    organizationID?: string;
  }

  /**
   * Parameters for purchaseOrderChangeStatus
   */
  export interface PurchaseOrderChangeStatusParams {

    /**
     * Purchase Order ID
     */
    purchaseOrderID: string;
    payload: ChangeStatusPurchaseOrderPayload;
  }

  /**
   * Parameters for purchaseOrderShow1
   */
  export interface PurchaseOrderShow1Params {

    /**
     * Purchase Order ID
     */
    purchaseOrderID: string;
    organizationID?: string;
  }

  /**
   * Parameters for purchaseOrderUpdate1
   */
  export interface PurchaseOrderUpdate1Params {

    /**
     * Purchase Order ID
     */
    purchaseOrderID: string;
    payload: PurchaseOrderInputPayload;
    organizationID?: string;
  }
}

export { PurchaseOrderService }
