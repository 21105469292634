import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from 'file-saver';
import * as printJS from 'print-js';

@Component({
  selector: 'ngx-image-viewer',
  styleUrls: ['./image-viewer.component.scss'],
  templateUrl: './image-viewer.component.html',
})
export class ImageViewerComponent implements OnInit {
  @Input() loading: false;
  @Input() title: string;
  @Input() file: Blob;
  @Input() src: any;
  @Output() emitData: EventEmitter<any> = new EventEmitter<any>();

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
  }

  close(status: string) {
    this.emitData.emit(status);
    this.activeModal.close(status);
  }

  download() {
    saveAs(this.file, this.title)
  }

  print() {
    printJS({
      printable: 'image-viewer', // Specify the ID of the element you want to print
      type: 'html', // Type of content: 'html', 'image', 'json', or 'raw'
    })
  }
}
