import { CommonModule, TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgSelectModule } from '@ng-select/ng-select';
import { CompanyService } from '@services/company.service';
import { Toaster } from '@services/toaster.service';
import { MentionModule } from 'angular-mentions';
import {
  DatePickerFieldComponent,
  DownloadFileComponent,
  FilterMenuBtnComponent,
  PillBtnComponent,
  PoSearchComponent,
  ProductFilterBarComponent,
  SupplierFileItemComponent,
  TitleDividerComponent,
  AddTaskFormComponent,
  BroadcastMessageComponent,
  PoNotesFormComponent,
  FloatingActionButtonComponent,
} from '@shared/components';
import { FieldsContainerComponent } from '@shared/components/fields-container/fields-container.component';
import { NgEditableSelectDirective, SecureUrlInputDirective } from '@shared/directives';
import {
  AddFilesModalComponent,
  AddShipmentFilesModalComponent,
  CompanyInfoModalComponent,
  CreateCompanyModalComponent,
  CreateProductModalComponent,
  ImageViewerComponent,
  ModalPOFilesComponent,
  ModalPONotesComponent,
  HelpVideosModalComponent,
  PdfVisualizerComponent,
  ReIssueReasonModalComponent,
  RejectionReasonModalComponent,
  AddLinkFileModalComponent,
  StepToProduceFormModalComponent,
  ProductChangesModalComponent,
  SupplierFileViewComponent,
  CustomerRequiredFileModalComponent,
} from '@shared/modals';
import { FileSizePipe, PendingStatusToWaitingForApprovalPipe } from '@shared/pipes';
import { TimeAgoPipe } from '@shared/pipes/time-ago.pipe';
import { ThemeModule } from '@theme/theme.module';
import { ToasterService } from 'angular2-toaster';
import { DxDataGridModule, DxHtmlEditorModule, DxSpeedDialActionModule } from 'devextreme-angular';
import { NgxLoadingModule } from 'ngx-loading';
import { PoImportedDetailComponent } from './components/po-imported-detail/po-imported-detail.component';
import { DateSelectorComponent } from './components/po-search/date-selector/date-selector.component';
import { EnteryKeyDirective } from './components/po-search/enterkey.directive';
import { FilterContainerComponent } from './components/po-search/filter-container/filter-container.component';
import { FilterMenuComponent } from './components/po-search/filter-menu/filter-menu.component';
import { FilterPillComponent } from './components/po-search/filter-pill/filter-pill.component';
import { POSearchService } from './components/po-search/po-search.service';
import { PurchaseOrdersImportedComponent } from './components/purchase-orders-imported/purchase-orders-imported.component';
import { ResourceActionsComponent } from './components/resource-actions/resource-actions.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { HelpIconComponent } from './components/help-icon/help-icon.component';
import { HelpIconDirective } from './directives/help-icon.directive';
import { FilesPreviewDirective } from './directives/files-preview.directive';
import { SafeUrlPipe } from '@shared/pipes/safe-url.pipe';
import { ItHelpModalComponent } from './modals/it-help-modal/it-help-modal.component';
import { TrainingGuidesModalComponent } from './modals/training-guides-modal/training-guides-modal.component';
import { UnicargoQuotesModalComponent } from './modals/unicargo-quotes-modal/unicargo-quotes-modal.component';
import { ShipmentService } from '@services/shipment.service';
import { LinkyModule } from 'ngx-linky';
import { MailComponent } from './components/mail/mail.component';

const COMPONENTS = [
  TitleDividerComponent,
  PoSearchComponent,
  FilterPillComponent,
  FilterMenuComponent,
  FilterContainerComponent,
  DateSelectorComponent,
  DatePickerFieldComponent,
  PillBtnComponent,
  FilterMenuBtnComponent,
  ProductFilterBarComponent,
  SupplierFileItemComponent,
  PillBtnComponent,
  FilterMenuBtnComponent,
  ProductFilterBarComponent,
  FieldsContainerComponent,
  AddTaskFormComponent,
  BroadcastMessageComponent,
  PoNotesFormComponent,
  FloatingActionButtonComponent,
  MailComponent
];
const MODALS = [
  CreateProductModalComponent,
  StepToProduceFormModalComponent,
  ProductChangesModalComponent,
  CompanyInfoModalComponent,
  ModalPOFilesComponent,
  ModalPONotesComponent,
  HelpVideosModalComponent,
  CreateCompanyModalComponent,
  AddFilesModalComponent,
  AddShipmentFilesModalComponent,
  PdfVisualizerComponent,
  ImageViewerComponent,
  RejectionReasonModalComponent,
  AddLinkFileModalComponent,
  ReIssueReasonModalComponent,
  SupplierFileViewComponent,
  PoImportedDetailComponent,
  CustomerRequiredFileModalComponent,
];

const DIRECTIVES = [
  EnteryKeyDirective,
  NgEditableSelectDirective,
  SecureUrlInputDirective,
  FilesPreviewDirective,
];

const PIPES = [
  FileSizePipe,
  PendingStatusToWaitingForApprovalPipe,
  TimeAgoPipe,
  SafeHtmlPipe,
  SafeUrlPipe,
];

@NgModule({
  imports: [
    CommonModule,
    MatChipsModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatIconModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    ThemeModule,
    MatDialogModule,
    MatDividerModule,
    MatSlideToggleModule,
    NgxLoadingModule,
    MatTooltipModule,
    MatMenuModule,
    DxDataGridModule,
    DxSpeedDialActionModule,
    MentionModule,
    NgOptionHighlightModule,
    LinkyModule,
    DxHtmlEditorModule
  ],
  declarations: [
    ...COMPONENTS,
    ...MODALS,
    ...DIRECTIVES,
    ...PIPES,
    AddFilesModalComponent,
    AddShipmentFilesModalComponent,
    TitleDividerComponent,
    AddTaskFormComponent,
    BroadcastMessageComponent,
    PoNotesFormComponent,
    DownloadFileComponent,
    PillBtnComponent,
    FilterContainerComponent,
    FilterMenuBtnComponent,
    SupplierFileViewComponent,
    SupplierFileItemComponent,
    PillBtnComponent,
    FilterContainerComponent,
    FilterMenuBtnComponent,
    ProductFilterBarComponent,
    ResourceActionsComponent,
    PurchaseOrdersImportedComponent,
    HelpIconComponent,
    HelpIconDirective,
    ItHelpModalComponent,
    TrainingGuidesModalComponent,
    UnicargoQuotesModalComponent,
  ],
  entryComponents: [
    ...MODALS,
    HelpIconComponent,
    BroadcastMessageComponent,
  ],
  exports: [
    ...COMPONENTS,
    ...MODALS,
    ...PIPES,
    ...DIRECTIVES,
    NgSelectModule,
    NgOptionHighlightModule,
    ThemeModule,
    ResourceActionsComponent,
    PurchaseOrdersImportedComponent,
    HelpIconDirective,
    HelpIconComponent,
  ],
  providers: [
    CompanyService,
    ShipmentService,
    Toaster,
    POSearchService,
    ToasterService,
    TitleCasePipe,
  ],
})
export class SharedModule {
}
