import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Company } from '@app/models';
import { CompanyService } from '@app/services';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date';
import { LocalStorage } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { last, map, mergeMap, startWith } from 'rxjs/operators';
import { POSearchService } from './po-search.service';
import { environment } from '@env/environment';

@Component({
  selector: 'ngx-po-search',
  templateUrl: './po-search.component.html',
  styleUrls: [
    // '/app/@theme/styles/search.scss',
    './po-search.component.scss',
  ],
})
export class PoSearchComponent implements OnInit, OnDestroy {
  isRCDSS = environment.name === 'rcdss';
  @LocalStorage() organizationId: string;
  @LocalStorage() companyType: string;
  isCustomer: boolean;
  filteredCompanies: Observable<Company[]>;
  selectedPO: string;
  selectedProduct: string;
  companyCtrl: FormControl = new FormControl();
  companies: Company[];
  selectedStatus: string;
  completedPOs = false;
  selectedShipDates: [NgbDate, NgbDate];
  selectedOrderDates: [NgbDate, NgbDate];
  selectedCancelDates: [NgbDate, NgbDate];

  @Input() type: string = 'customer';

  constructor(private $company: CompanyService, private $searchMenu: POSearchService) {
  }

  async ngOnInit() {
    this.isCustomer = this.type === 'customer';
    const requestCompaniesObs = (this.isCustomer
      ? this.$company.fetchSuppliers(this.organizationId)
      : this.$company.fetchCustomers(this.organizationId));
    requestCompaniesObs.subscribe((companies) => {
      this.companies = companies;
    });

    this.filteredCompanies = this.companyCtrl.valueChanges
      .pipe(
        startWith(''),
        last(),
        mergeMap(() => requestCompaniesObs),
        map((data) => {
          console.log(data);
          return [];
          // return (company ? this.filterCompanies(company) : this.companies.slice());
        }),
      );
  }

  ngOnDestroy() {
    this.$searchMenu.cleanSearch();
  }

  filterCompanies(name: string) {
    return this.companies.filter(company => company.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  getTitle(key: string) {
    let value = this.$searchMenu.getValue(key);

    switch (key) {
      case 'number':
        value = value || this.isRCDSS ? 'Contract #' : 'Purchase Order #';
        break;
      case 'product':
        value = value || 'Item';
        break;
      case 'company':
        value = value || this.isRCDSS ? (this.isCustomer ? 'Vendor' : 'Department') : (this.isCustomer ? 'Supplier' : 'Customer');
        break;
      case 'order':
        if (!value) return this.isRCDSS ? 'Date' : 'Order Date';
        if (!value[1]) return this.buildDateTitle(value[0]);
        return `${this.buildDateTitle(value[0])} - ${this.buildDateTitle(value[1])}`;
      case 'ship':
        if (!value) return 'Ship Date';
        if (!value[1]) return this.buildDateTitle(value[0]);
        return `${this.buildDateTitle(value[0])} - ${this.buildDateTitle(value[1])}`;
      case 'cancel':
        if (!value) return this.isRCDSS ? 'Due Date' : 'Cancel Date';
        if (!value[1]) return this.buildDateTitle(value[0]);
        return `${this.buildDateTitle(value[0])} - ${this.buildDateTitle(value[1])}`;
      case 'status':
        value = value === 'qb-imported' ? 'recently imported' : value;
        value = value || 'Status';
        break;
      default:
        break;
    }
    return value;
  }

  onFilter(key: string, isSave: boolean) {
    if (key === '') {
      return;
    }
    let value = null;
    switch (key) {
      case 'number':
        isSave ? (value = this.selectedPO) : (this.selectedPO = '');
        break;
      case 'product':
        isSave ? (value = this.selectedProduct) : (this.selectedProduct = '');
        break;
      case 'company':
        isSave ? (value = this.companyCtrl.value) : this.companyCtrl.setValue('');
        break;
      case 'order':
        isSave ? (value = this.selectedOrderDates) : (this.selectedOrderDates = null);
        break;
      case 'ship':
        isSave ? (value = this.selectedShipDates) : (this.selectedShipDates = null);
        break;
      case 'cancel':
        isSave ? (value = this.selectedCancelDates) : (this.selectedCancelDates = null);
        break;
      case 'status':
        isSave ? (value = this.selectedStatus) : (this.selectedStatus = '');
        break;
      case 'completed':
        isSave ? (value = this.completedPOs) : (this.completedPOs = false);
        break;
      default:
        break;
    }

    isSave ? this.$searchMenu.addSearchParameter(key, value) : this.$searchMenu.clearSearchParameter(key);
  }

  buildDateTitle(ngbDate: NgbDate) {
    return `${ngbDate.year}/${ngbDate.month}/${ngbDate.day}`;
  }
}
